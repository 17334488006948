import { useCallback, useState } from 'react'
import { CustomButton, CustomTable } from 'components'
import { Checkbox } from '@mui/material'

const tableHeaders = [
  { label: '', field: 'check' },
  { label: 'sr. no.', field: '' },
  { label: 'Employee Code', field: 'employeeCode' },
  { label: 'First Name', field: 'firstName' },
  { label: 'Last Name', field: 'lastName' },
  { label: 'Pincode', field: 'pincode' },
  { label: 'Phone Number', field: 'phoneNo' },
  { label: 'View', field: 'view' },
]

export default function EmployeeTable({
  tableData,
  setStep,
  setUserObj,
  setAgentData,
}) {
  const [selected, setSelected] = useState([])
  const newTableData = !Array.isArray(tableData) ? [tableData] : tableData

  const isSelected = useCallback((id) => selected.includes(id), [selected])

  const onClickViewMapping = useCallback(() => {
    const poAgents = selected.flatMap((user) => {
      const assignToData = {
        assignTo: user.completeName,
        designation: user.designation,
        employeeCode: user.employeeCode,
      }
      return [...user.POs, ...user.Agents].map((agentPOs) => ({
        ...agentPOs,
        assignToData: {
          ...assignToData,
          previouslyAssignedTo: agentPOs?.Employees?.completeName,
        },
      }))
    })
    setAgentData(poAgents)
    setStep(3)
  }, [selected, setAgentData, setStep])

  const handleCheckBoxClick = (event, rowData) => {
    const selectedIndex = selected.findIndex(
      (selectedRow) => selectedRow.id === rowData.id
    )
    const newSelected = [...selected]

    if (selectedIndex === -1) {
      newSelected.push(rowData)
    } else {
      newSelected.splice(selectedIndex, 1)
    }

    setSelected(newSelected)
  }

  const handleClick = (user) => {
    setUserObj(user)
    setStep(2)
  }

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'employeeCode':
        return row.employeeCode
      case 'firstName':
        return row.firstName
      case 'lastName':
        return row.lastName
      case 'country':
        return row.country
      case 'pincode':
        return row.pincode
      case 'phoneNo':
        return `+${row.phoneNo || row.officeMobile}`
      case 'check':
        return (
          <Checkbox
            checked={isSelected(row)}
            onClick={(event) => handleCheckBoxClick(event, row)}
          />
        )
      case 'view':
        return (
          <CustomButton variant={'outlined'} onClick={() => handleClick(row)}>
            View
          </CustomButton>
        )
      default:
        return ''
    }
  }

  return (
    <>
      <CustomTable
        tableLabel="Employee Selection"
        headers={tableHeaders}
        rows={newTableData}
        render={renderCell}
      />
      <div className="flex justify-center gap-4 my-4">
        <CustomButton
          type="submit"
          variant={'contained'}
          onClick={onClickViewMapping}
          disabled={selected.length === 0}
        >
          View Mapping
        </CustomButton>
      </div>
    </>
  )
}
