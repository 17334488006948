import { ViewDetails } from 'components'

const EmployeeDetails = ({ user, handleClick, step }) => {
  let fieldsToDisplay
  if (step === 2) {
    fieldsToDisplay = [
      { value: 'employeeCode', label: 'Employee Code' },
      { value: 'firstName', label: 'First Name' },
      { value: 'middleName', label: 'Middle Name' },
      { value: 'lastName', label: 'Last Name' },
      { value: 'gender', label: 'Gender' },
      { value: 'dateOfBirth', label: 'Date Of Birth' },
      { value: 'email', label: 'Email ID' },
      { value: 'phoneNo', label: 'Mobile Number' },
      { value: 'address', label: 'Address' },
      { value: 'country', label: 'Country' },
      { value: 'state', label: 'State' },
      { value: 'district', label: 'District' },
      { value: 'city', label: 'City/Area' },
      { value: 'pincode', label: 'Pin Code' },
    ]
  } else if (step === 4) {
    fieldsToDisplay = [
      { value: 'completeName', label: 'Agent Name' },
      { value: 'assignToData', label: 'Previously Assigned To' },
      { value: 'assignToData', label: 'Assigned To' },
      { value: 'assignToData', label: 'Employee Code' },
      { value: 'assignToData', label: 'Designation' },
      { value: 'firstName', label: 'Name' },
      { value: 'dateOfBirth', label: 'Date Of Birth' },
      { value: 'officeEmail', label: 'Email ID (Official)' },
      { value: 'email', label: 'Email ID (Personal)' },
      { value: 'officeMobile', label: 'Mobile Number (Official)' },
      { value: 'mobile', label: 'Mobile Number (Personal)' },
      { value: 'address', label: 'Address' },
      { value: 'country', label: 'Country' },
      { value: 'region', label: 'Region' },
      { value: 'state', label: 'State' },
      { value: 'district', label: 'District' },
      { value: 'city', label: 'City/Area' },
      { value: 'branch', label: 'Branch' },
      { value: 'pincode', label: 'Pin Code' },
    ]
  }

  return (
    <ViewDetails
      user={user}
      handleClick={handleClick}
      fieldsToDisplay={fieldsToDisplay}
    />
  )
}

export default EmployeeDetails
