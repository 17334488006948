import { useEffect, useState } from 'react'
import { Checkbox } from '@mui/material'
import { useMappedUserToUserMutation } from 'api/usersAPI'
import { CustomButton, CustomModal, CustomTable, Toast } from 'components'

const EmployeeMappingTable = ({ tableData, assignTo, setStep }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const [modalMessage, setModalMessage] = useState('')
  const [mappedUserToUser, { isSuccess, isError, isLoading }] =
    useMappedUserToUserMutation()

  useEffect(() => {
    if (isSuccess) {
      setModalOpen(true)
      setModalMessage('Employee Assigned Successfully!')
    } else if (isLoading) {
      setModalOpen(true)
      setModalMessage('Employee is being Assigned...')
    } else {
      setModalOpen(false)
      setModalMessage('')
    }
  }, [isLoading, isSuccess])

  const toggleSelectAll = () => {
    if (selected.length === tableData.length) {
      setSelected([])
    } else {
      setSelected(tableData.map((item) => item.employeeCode))
    }
  }

  const isSelected = (employeeCode) => selected.includes(employeeCode)

  const onClickAssign = () => {
    const postData = {
      userType: 'individual',
      userSubType: 'employee',
      assignTo: assignTo,
      employeeCodes: selected,
    }
    mappedUserToUser(postData)
  }

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'name':
        return row.completeName
      case 'designation':
        return row.designation
      case 'district':
        return row.district
      case 'state':
        return row.state
      case 'region':
        return row.region
      case 'pincode':
        return row.pincode
      case 'city':
        return row.city
      case 'select-all':
        return (
          <Checkbox
            checked={isSelected(row.employeeCode)}
            onClick={(event) => handleCheckboxClick(event, row.employeeCode)}
            key={row.employeeCode}
          />
        )
      default:
        return ''
    }
  }
  const handleCheckboxClick = (event, rowData) => {
    const selectedIndex = selected.findIndex(
      (selectedRow) => selectedRow === rowData
    )
    const newSelected =
      selectedIndex === -1
        ? [...selected, rowData]
        : [
            ...selected.slice(0, selectedIndex),
            ...selected.slice(selectedIndex + 1),
          ]

    setSelected(newSelected)
  }

  const handleModalClose = () => {
    setModalOpen(false)
    setModalMessage('')
  }

  const disabledButton = selected.length === 0

  const tableHeaders = [
    {
      label: (
        <Checkbox
          checked={selected.length === tableData.length}
          onClick={() => toggleSelectAll()}
        />
      ),
      field: 'select-all',
    },
    { label: 'sr. no.', field: '' },
    { label: 'Name', field: 'name' },
    { label: 'Designation', field: 'designation' },
    { label: 'Pincode', field: 'pincode' },
    { label: 'Region', field: 'region' },
    { label: 'State', field: 'state' },
    { label: 'District', field: 'district' },
    { label: 'City / Area', field: 'city' },
  ]

  return (
    <>
      {isError && (
        <Toast
          message={'Employee Assigned Unsuccessful Please Try Again'}
          severity={'error'}
        />
      )}
      <CustomTable
        tableLabel="Employee Selection"
        headers={tableHeaders}
        rows={tableData}
        render={renderCell}
      />
      <div className="flex justify-center gap-4 my-4">
        <CustomButton
          type="submit"
          variant="contained"
          disabled={disabledButton}
          onClick={onClickAssign}
        >
          Assign
        </CustomButton>
        <CustomButton onClick={() => setStep(1)} variant="outlined">
          Back
        </CustomButton>
      </div>
      <CustomModal
        message={modalMessage}
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
      />
    </>
  )
}

export default EmployeeMappingTable
