import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Controller } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import CustomLabel from './CustomLabel'

const errorStyle = {
  color: '#d32f2f',
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.66,
  letterSpacing: '0.03333em',
  textAlign: 'left',
  marginTop: '3px',
  marginRight: '14px',
  marginBottom: 0,
  marginLeft: '14px',
}

function CustomMobileInput({
  label,
  name,
  control,
  className,
  variant,
  errors,
  defaultValue,
  isRequired,
  smartCovrFormat,
}) {
  const handlePhoneInputChange = (value, countryData, onChange) => {
    const phoneNumber = {
      code: countryData.dialCode,
      number: value.replace(/[^0-9]+/g, ''),
    }
    if (phoneNumber.number.startsWith(phoneNumber.code)) {
      phoneNumber.number = phoneNumber.number.substring(phoneNumber.code.length)
    }
    const formattedPhoneNumber = JSON.stringify(phoneNumber)
    onChange(formattedPhoneNumber)
  }

  return (
    <div className="flex flex-col">
      <CustomLabel htmlFor={name} className="mt-2 mb-1 capitalize">
        {label}
        {isRequired && <span className="text-red-700"> *</span>}
      </CustomLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        render={({
          field: { ref, onChange, value },
          fieldState: { error },
        }) => (
          <PhoneInput
            name={name}
            specialLabel={false}
            className={className}
            country="in"
            countryCodeEditable={false}
            defaultErrorMessage="error message"
            onChange={
              smartCovrFormat
                ? (value, countryData) =>
                    handlePhoneInputChange(value, countryData, onChange)
                : onChange
            }
            value={value || '+91'}
            buttonStyle={{
              padding: '10px',
              backgroundColor: 'white',
              border: error ? '1px solid #d32f2f' : '',
            }}
            inputStyle={{
              padding: '27px',
              paddingLeft: '65px',
              width: '100%',
              borderRadius: '2px',
              border: error ? '1px solid #d32f2f' : '',
              '.selectedFlag': { padding: '8px' },
            }}
            inputProps={{ ref: ref }}
            variant={variant}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p style={errorStyle}>{message}</p>}
      />
    </div>
  )
}

export default CustomMobileInput
