import * as yup from 'yup'

const today = new Date()

export const userTypeSchema = yup.object({
  registerUserType: yup
    .string()
    .required('Please select User Type from the list above'),
  registerUserSubType: yup
    .string()
    .required('Please select User Sub-Type from the list above'),
})

export const posSchema = yup.object({
  userType: yup.string().default('individual'),
  userSubType: yup.string().default('pos'),
  posCode: yup
    .string()
    .required('Please Provide POS Code')
    .max(20, 'Must be less Than 20 characters')
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric are allowed for this field '),
  title: yup.string().required('Please select Title from the list above'),
  firstName: yup
    .string()
    .required('Please Provide First Name')
    .max(20, 'Must be less Than 20 characters')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  middleName: yup
    .string()
    .required('Please Provide Middle Name')
    .max(20, 'Must be less Than 20 characters')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  lastName: yup
    .string()
    .required('Please Provide Last Name')
    .max(20, 'Must be less Than 20 characters')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  dateOfBirth: yup
    .date()
    .required()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .required('Please Provide Date of Birth')
    .min(
      new Date(today.getFullYear() - 80, today.getMonth(), today.getDate()),
      'Enter Valid Date'
    )
    .max(
      new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()),
      'Should be above 18 years'
    ),
  email: yup
    .string()
    .required('Please Provide Email Id')
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      'Please provide valid email id'
    )
    .max(60, 'Must be less than 60 characters'),
  officeEmail: yup
    .string()
    .required('Please Provide Email Id')
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      'Please provide valid email id'
    )
    .max(60, 'Must be less than 60 characters'),
  phoneNo: yup.string(),
  officeMobile: yup
    .string()
    .required('Please Provide Mobile Number')
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{6})$/,
      'Phone number is not valid'
    ),
  address: yup
    .string()
    .required('Please Provide Address')
    .min(10, 'Must be at least 10 characters')
    .max(300, 'Must be less Than 300 characters')
    .matches(
      /^[0-9a-zA-Z \\/,-:;<>.&@(){}#~]*$/,
      'Only alphanumeric with special characters are allowed for this field '
    ),
  country: yup.string().required('Please select a Country from the list above'),
  region: yup.string().required('Please select Region from the list above'),
  state: yup.string().required('Please select State from the list above'),
  district: yup.string().required('Please select District from the list above'),
  pincode: yup
    .string()
    .matches(/^$|^\d{6}$/, 'Pincode must be exactly 6 digits')
    .required(),
  city: yup.string().required('Please select City from the list above'),
  newCity: yup
    .string()
    .when('city', { is: 'others', then: (schema) => schema.min(3) })
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  nismRegistrationNumber: yup
    .string()
    .required('Please Provide NISM Certificate Number')
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric are allowed for this field ')
    .max(20, 'Must be less than 20 characters'),
  nismRegistrationNumberGenerationDate: yup
    .date()
    .required()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .required('Please Provide NISM Certificate Generation Date')
    .min(
      new Date(today.getFullYear() - 80, today.getMonth(), today.getDate()),
      'Enter Valid Date'
    )
    .max(new Date(), 'Enter Valid Date'),
  posRegistrationNumber: yup
    .string()
    .required('Please Provide POS Registration Number')
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric are allowed for this field '),
  posRegistrationNumberGenerationDate: yup
    .date()
    .required()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .required('Please Provide POS Registration Number Generation Date')
    .min(
      new Date(today.getFullYear() - 80, today.getMonth(), today.getDate()),
      'Enter Valid Date'
    )
    .max(new Date(), 'Enter Valid Date'),
  qualification: yup
    .string()
    .required('Please select Qualification from the list above'),
  photograph: yup.string().required('Please Upload Photograph'),
  nismCertificate: yup.string().required('Please Upload NISM Certificate'),
  posCertificate: yup.string().required('Please Upload POS Certificate'),
  educationCertificate: yup
    .string()
    .required('Please Upload Higher Education Certificate'),
  generalInsurance: yup
    .array()
    .of(
      yup.object().shape({
        insurancePolicy: yup
          .string()
          .required('Please provide General Insurance Name'),
        companyCode: yup
          .string()
          .matches(
            /^[a-zA-Z0-9]*$/,
            'Only alphanumeric are allowed for this field '
          )
          .required('Please provide General Insurance Code'),
      })
    )
    .required(),
  healthInsurance: yup
    .array()
    .of(
      yup.object().shape({
        insurancePolicy: yup
          .string()
          .required('Please provide Health Insurance Name'),
        companyCode: yup
          .string()
          .matches(
            /^[a-zA-Z0-9]*$/,
            'Only alphanumeric are allowed for this field '
          )
          .required('Please provide Health Insurance Code'),
      })
    )
    .required(),
  lifeInsurance: yup
    .array()
    .of(
      yup.object().shape({
        insurancePolicy: yup
          .string()
          .required('Please provide life Insurance Name'),
        companyCode: yup
          .string()
          .matches(
            /^[a-zA-Z0-9]*$/,
            'Only alphanumeric are allowed for this field '
          )
          .required('Please provide life Insurance Code'),
      })
    )
    .required(),
})

export const agentSchema = yup.object({
  userType: yup.string().default('individual'),
  userSubType: yup.string().default('agent'),
  agencyCode: yup
    .string()
    .required('Please Provide Agency Code')
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric are allowed for this field '),
  title: yup.string().required('Please select Title from the list above'),
  firstName: yup
    .string()
    .required('Please Provide First Name')
    .max(20, 'Must be less Than 20 characters')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  middleName: yup
    .string()
    .required('Please Provide Middle Name')
    .max(20, 'Must be less Than 20 characters')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  lastName: yup
    .string()
    .required('Please Provide Last Name')
    .max(20, 'Must be less Than 20 characters')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  dateOfBirth: yup
    .date()
    .required()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .required('Please Provide Date of Birth')
    .min(
      new Date(today.getFullYear() - 80, today.getMonth(), today.getDate()),
      'Enter Valid Date'
    )
    .max(
      new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()),
      'Should be above 18 years'
    ),
  email: yup
    .string()
    .required('Please Provide Email Id')
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      'Please provide valid email id'
    )
    .max(60, 'Must be less than 60 characters'),
  officeEmail: yup
    .string()
    .required('Please Provide Email Id')
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      'Please provide valid email id'
    )
    .max(60, 'Must be less than 60 characters'),
  phoneNo: yup.string(),
  officeMobile: yup
    .string()
    .required('Please Provide Mobile Number')
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{6})$/,
      'Phone number is not valid'
    ),
  address: yup
    .string()
    .required('Please Provide Address')
    .min(10, 'Must be at least 10 characters')
    .max(300, 'Must be less Than 300 characters')
    .matches(
      /^[0-9a-zA-Z \\/,-:;<>.&@(){}#~]*$/,
      'Only alphanumeric with special characters are allowed for this field '
    ),
  country: yup.string().required('Please select a Country from the list above'),
  region: yup.string().required('Please select Region from the list above'),
  state: yup.string().required('Please select State from the list above'),
  district: yup.string().required('Please select District from the list above'),
  pincode: yup
    .string()
    .matches(/^$|^\d{6}$/, 'Pincode must be exactly 6 digits')
    .required(),
  city: yup.string().required('Please select City from the list above'),
  newCity: yup
    .string()
    .when('city', { is: 'others', then: (schema) => schema.min(3) })
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  nismRegistrationNumber: yup
    .string()
    .required('Please Provide NISM Certificate Number')
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric are allowed for this field ')
    .max(20, 'Must be less than 20 characters'),
  nismRegistrationNumberGenerationDate: yup
    .date()
    .required()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .required('Please Provide NISM Certificate Generation Date')
    .min(
      new Date(today.getFullYear() - 80, today.getMonth(), today.getDate()),
      'Enter Valid Date'
    )
    .max(new Date(), 'Enter Valid Date'),
  qualification: yup
    .string()
    .required('Please select Qualification from the list above'),
  generalInsurance: yup
    .array()
    .of(
      yup.object().shape({
        insurancePolicy: yup
          .string()
          .required('Please provide General Insurance Name'),
        companyCode: yup
          .string()
          .matches(
            /^[a-zA-Z0-9]*$/,
            'Only alphanumeric are allowed for this field '
          )
          .required('Please provide General Insurance Code'),
      })
    )
    .required(),
  healthInsurance: yup
    .array()
    .of(
      yup.object().shape({
        insurancePolicy: yup
          .string()
          .required('Please provide Health Insurance Name'),
        companyCode: yup
          .string()
          .matches(
            /^[a-zA-Z0-9]*$/,
            'Only alphanumeric are allowed for this field '
          )
          .required('Please provide Health Insurance Code'),
      })
    )
    .required(),
  lifeInsurance: yup
    .array()
    .of(
      yup.object().shape({
        insurancePolicy: yup
          .string()
          .required('Please provide life Insurance Name'),
        companyCode: yup
          .string()
          .matches(
            /^[a-zA-Z0-9]*$/,
            'Only alphanumeric are allowed for this field '
          )
          .required('Please provide life Insurance Code'),
      })
    )
    .required(),
})

export const employeeSchema = yup.object({
  userType: yup.string().default('individual'),
  userSubType: yup.string().default('employee'),
  employeeCode: yup
    .string()
    .required('Please Provide Employee Code')
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric are allowed for this field '),
  title: yup.string().required('Please select Title from the list above'),
  firstName: yup
    .string()
    .required('Please Provide First Name')
    .max(20, 'Must be less Than 20 characters')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  middleName: yup
    .string()
    .required('Please Provide Middle Name')
    .max(20, 'Must be less Than 20 characters')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  lastName: yup
    .string()
    .required('Please Provide Last Name')
    .max(20, 'Must be less Than 20 characters')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  dateOfBirth: yup
    .date()
    .required()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .required('Please Provide Date of Birth')
    .min(
      new Date(today.getFullYear() - 80, today.getMonth(), today.getDate()),
      'Enter Valid Date'
    )
    .max(
      new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()),
      'Should be above 18 years'
    ),
  designation: yup
    .string()
    .required('Please select Designation from the list above')
    .min(3, 'Must be minimum 3 characters')
    .max(40, 'Must be less Than 40 characters')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  email: yup
    .string()
    .required('Please Provide Email Id')
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      'Please provide valid email id'
    )
    .max(60, 'Must be less than 60 characters'),
  officeEmail: yup
    .string()
    .required('Please Provide Email Id')
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      'Please provide valid email id'
    )
    .max(60, 'Must be less than 60 characters'),
  phoneNo: yup.string(),
  officeMobile: yup
    .string()
    .required('Please Provide Mobile Number')
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{6})$/,
      'Phone number is not valid'
    ),
  address: yup
    .string()
    .required('Please Provide Address')
    .min(10, 'Must be at least 10 characters')
    .max(300, 'Must be less Than 300 characters')
    .matches(
      /^[0-9a-zA-Z \\/,-:;<>.&@(){}#~]*$/,
      'Only alphanumeric with special characters are allowed for this field '
    ),
  country: yup.string().required('Please select a Country from the list above'),
  region: yup.string().required('Please select Region from the list above'),
  state: yup.string().required('Please select State from the list above'),
  district: yup.string().required('Please select District from the list above'),
  pincode: yup
    .string()
    .matches(/^$|^\d{6}$/, 'Pincode must be exactly 6 digits')
    .required(),
  city: yup.string().required('Please select City from the list above'),
  newCity: yup
    .string()
    .when('city', { is: 'others', then: (schema) => schema.min(3) })
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  branchCode: yup
    .string()
    .required('Please Provide Branch')
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric are allowed for this field '),
  nismRegistrationNumber: yup
    .string()
    .matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric are allowed for this field ')
    .max(20, 'Must be less than 20 characters'),
  nismRegistrationNumberGenerationDate: yup
    .date()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .min(
      new Date(today.getFullYear() - 80, today.getMonth(), today.getDate()),
      'Enter Valid Date'
    )
    .max(new Date(), 'Enter Valid Date')
    .nullable(),
  qualification: yup
    .string()
    .required('Please select Qualification from the list above'),
  photograph: yup.string().required('Please Upload Photograph'),
  nismCertificate: yup.string().required('Please Upload NISM Certificate'),
  educationCertificate: yup
    .string()
    .required('Please Upload Higher Education Certificate'),
})
