import * as yup from 'yup'

const today = new Date()
export const employeeSchema = yup.object({
  dateFrom: yup
    .date()
    .required()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .required('Please Provide From Date')
    .min(
      new Date(today.getFullYear() - 80, today.getMonth(), today.getDate()),
      'Enter Valid Date'
    )
    .max(new Date(), 'Enter Valid Date'),
  dateTo: yup
    .date()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .min(yup.ref('dateFrom'), "End date can't be before Start date")
    .required('Please provide To Date')
    .max(new Date(), 'Enter Valid Date'),
  userType: yup
    .string()
    .required('Please select User Type from the list above'),
  userSubType: yup
    .string()
    .required('Please select User Sub-Type from the list above'),
  pincode: yup
    .string()
    .matches(/^$|^\d{6}$/, 'Pincode must be exactly 6 digits'),
  newCity: yup
    .string()
    .when('city', { is: 'others', then: (schema) => schema.min(3) })
    .matches(/^[aA-zZ\s]+$/, 'Please Provide New City Name'),
})

export const employeeCodeSchema = yup.object({
  employeeCode: yup
    .string()
    .required('Please Provide Employee Code')
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric are allowed for this field ')
    .max(50, 'Must be less than 50 characters'),
})

export const agentPosSchema = yup.object({
  userType: yup
    .string()
    .required('Please select User Type from the list above'),
  userSubType: yup
    .string()
    .required('Please select User Sub-Type from the list above'),
  pincode: yup
    .string()
    .matches(/^$|^\d{6}$/, 'Pincode must be exactly 6 digits'),
  newCity: yup
    .string()
    .when('city', { is: 'others', then: (schema) => schema.min(3) })
    .matches(/^[aA-zZ\s]+$/, 'Please Provide New City Name'),
})
export const profileCodeSchema = yup.object({
  userSubType: yup
    .string()
    .required('Please select User Sub-Type from the list above'),
  profileCode: yup
    .string()
    .required('Please Provide Profile Code')
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric are allowed for this field ')
    .max(20, 'Must be less than 20 characters'),
})
