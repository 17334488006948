import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from 'config'
import { getCookie } from 'utils/CustomUtils'

const MetadataQueryWrapper = (baseQuery) => {
  return async (args, api, extraOptions) => {
    const { metadataUrl, apiHeaders } = config
    const url = `${metadataUrl}${args.url}`
    // const accessToken = api.getState().auth.data
    // access token taken by below get cookie is for testing on uat, by defualt it should get access token from authState like on above line
    const accessToken = getCookie('accessToken')
    const headers = accessToken
      ? { ...apiHeaders, authorization: `Bearer ${accessToken}` }
      : { ...apiHeaders }
    const modifiedArgs = {
      ...args,
      params: {
        ...args.params,
        apiKey: config.apiKey,
      },
      headers: headers,
    }
    return baseQuery({ ...modifiedArgs, url }, api, extraOptions)
  }
}

export const wrappedMetadataBaseQuery = MetadataQueryWrapper(
  fetchBaseQuery({ keepUnusedDataFor: 60 })
)
