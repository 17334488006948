import { useEffect, useState } from 'react'
import { CustomButton, CustomModal, CustomTable, Toast } from 'components'
import { Checkbox } from '@mui/material'
import { useMappedUserToUserMutation } from 'api/usersAPI'

const tableHeaders = [
  { label: '', field: 'check' },
  { label: 'sr. no.', field: '' },
  { label: 'Name', field: 'name' },
  { label: 'Designation', field: 'designation' },
  { label: 'Country', field: 'country' },
  { label: 'State', field: 'state' },
  { label: 'Region', field: 'region' },
  { label: 'District', field: 'district' },
  { label: 'Pincode', field: 'pincode' },
  { label: 'City / Area', field: 'city' },
]

export default function EmployeeReMappingTable(props) {
  const { tableData, assignTo, setStep } = props
  const [modalOpen, setModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [mappedUserToUser, { isSuccess, isError, isLoading }] =
    useMappedUserToUserMutation()
  const [selected, setSelected] = useState([])

  useEffect(() => {
    if (isSuccess) {
      setModalOpen(true)
      setModalMessage('Employee Re-Assigned Successfully!')
    } else if (isLoading) {
      setModalOpen(true)
      setModalMessage('Employee is being Re-Assigned...')
    } else {
      setModalOpen(false)
      setModalMessage('')
    }
  }, [isLoading, isSuccess])

  const isSelected = (employeeCode) => selected.indexOf(employeeCode) !== -1

  const onClickAssign = () => {
    const postData = {
      userType: 'individual',
      userSubType: 'employee',
      assignTo: assignTo,
      employeeCodes: selected,
    }
    mappedUserToUser(postData)
  }

  const handleCheckboxClick = (event, rowData) => {
    const selectedIndex = selected.findIndex(
      (selectedRow) => selectedRow === rowData
    )
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowData)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    } else {
      // add this else block to remove the row data if the checkbox is unchecked
      newSelected = newSelected.concat(
        selected.filter((selectedRow) => selectedRow !== rowData)
      )
    }

    setSelected(newSelected)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }
  const disabledButton = selected.length === 0 ? true : false

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'name':
        return row.completeName
      case 'designation':
        return row.designation
      case 'district':
        return row.district
      case 'country':
        return row.country
      case 'state':
        return row.state
      case 'region':
        return row.region
      case 'pincode':
        return row.pincode
      case 'city':
        return row.city
      case 'check':
        return (
          <Checkbox
            checked={isSelected(row.employeeCode)}
            onClick={(event) => handleCheckboxClick(event, row.employeeCode)}
          />
        )
      default:
        return ''
    }
  }

  return (
    <>
      {isError && (
        <Toast
          message={'Error fetching employee Please Try Again'}
          severity={'error'}
        />
      )}
      <CustomTable
        tableLabel="Employee Selection"
        headers={tableHeaders}
        rows={tableData}
        render={renderCell}
      />
      <div className="flex justify-center gap-4 my-4">
        <CustomButton
          type="submit"
          variant={'contained'}
          disabled={disabledButton}
          onClick={onClickAssign}
        >
          Re-Assign
        </CustomButton>
        <CustomButton onClick={() => setStep(1)} variant={'outlined'}>
          Back
        </CustomButton>
      </div>
      <CustomModal
        message={modalMessage}
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
      />
    </>
  )
}
