import { ViewDetails } from 'components'

export const fieldsToDisplay = [
  { value: 'leadType', label: 'Lead Type' },
  { value: 'leadSubType', label: 'Lead Sub Type' },
  { value: 'policyOption', label: 'Policy Type' },
  { value: 'leadName', label: 'Full Name' },
  { value: 'gender', label: 'Gender' },
  { value: 'dob', label: 'Date Of Birth' },
  { value: 'officeEmail', label: 'Email ID (Official)' },
  { value: 'email', label: 'Email ID (Personal)' },
  { value: 'mobile', label: 'Mobile Number' },
  { value: 'address', label: 'Address' },
  { value: 'country', label: 'Country' },
  { value: 'state', label: 'State' },
  { value: 'district', label: 'District' },
  { value: 'pincode', label: 'Pin Code' },
  { value: 'city', label: 'City/Area' },
  { value: 'createdAt', label: 'Lead Created On' },
]

export default function LeadDetails({ user, handleClick }) {
  return (
    <ViewDetails
      user={user}
      handleClick={handleClick}
      fieldsToDisplay={fieldsToDisplay}
    />
  )
}
