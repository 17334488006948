import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#003b90',
      light: '#283593',
      dark: '#0a3156',
    },
    secondary: {
      main: '#0270f6',
      light: '#2196f3',
    },
  },
})

export default theme
