import { useRef, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import { useSelector } from 'react-redux'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImageResize,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageTransform
)

const FileUploadComponent = (props) => {
  const authApiKey = '81053a30-2725-483c-9a78-a3f67bc55371'
  const iamId = useSelector((state) => state.auth.iamId)
  const serverUrl = 'https://apigateway.smartcovr.io/apiman-gateway/smartcovr'
  const { label, setValue, name } = props
  const [files, setFiles] = useState()
  const pond = useRef(null)

  // Function to convert file to base64
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result.split(',')[1])
      reader.onerror = (error) => reject(error)
    })
  }

  // Function to send base64 data to Postman URL via XHR
  const processFile = async (base64Data) => {
    const strImage = base64Data.replace(
      /^data:[image|application]+\/[a-z]+;base64,/,
      ''
    )
    const filestoreData = {
      data: strImage,
      extension: 'image',
      forceUpload: true,
    }
    const fdata = JSON.stringify(filestoreData)
    try {
      const response = await fetch(
        `${serverUrl}/secureupload/1.0/postfiles/IN-9839/${iamId}/identity/${name}/IN-9820?apikey=${authApiKey}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: fdata,
        }
      )

      if (!response.ok) {
        throw new Error('Failed to upload file')
      }

      // Extracting ID from the response
      const holdMyUUID = await response.text()
      setValue(name, holdMyUUID)

      const getResponse = await fetch(
        `https://apigateway.smartcovr.io/apiman-gateway/smartcovr/secureupload/1.0/getfiles/${holdMyUUID}?apikey=${authApiKey}`
      )

      if (!getResponse.ok) {
        throw new Error('Failed to fetch file')
      }
    } catch (error) {
      // console.error('Error processing file:', error)
    }
  }

  const handleFileUpload = async (files) => {
    setFiles(files)
    try {
      for (const file of files) {
        const base64Data = await fileToBase64(file.file)
        await processFile(base64Data)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error processing files:', error)
    }
  }

  return (
    <div>
      <label>{label}</label>
      <FilePond
        ref={pond}
        files={files}
        allowMultiple={false}
        maxFiles={1}
        server={null}
        onupdatefiles={handleFileUpload}
        allowFilePreview={true}
        acceptedFileTypes={['image/*', 'application/pdf']}
        labelIdle="Upload"
      />
    </div>
  )
}

export default FileUploadComponent
