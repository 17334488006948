import { CustomLoading, Toast } from 'components'
import { useState } from 'react'
import {
  EmployeeDetails,
  EmployeeMappingTable,
  EmployeeTable,
} from 'features/employeeToEmployeeMapping'
import { useGetUsersQuery } from 'api/usersAPI'
import { filterFormData } from 'utils/CustomUtils'
import { EmployeeForm } from 'forms'

function EmployeeEmployeeMapping() {
  const [step, setStep] = useState(1)
  const [user, setUser] = useState({})
  const [employeeSkip, setEmployeeSkip] = useState(true)
  const [unassignedEmployeeSkip, setUnassignedEmployeeSkip] = useState(true)
  const [employeeFormData, setEmployeeFormData] = useState({})
  const [unAssignedEmployeeFormData, setAssignedEmployeeFormData] = useState({})
  const [assignTo, setAssignTo] = useState('')

  const employeeFormDataFiltered = filterFormData(employeeFormData)
  const employeeFormData2Filtered = filterFormData(unAssignedEmployeeFormData)

  const {
    data: employees = [],
    isFetching: isEmployeeFetching,
    isLoading: isEmployeeLoading,
    isError: isEmployeeError,
  } = useGetUsersQuery(
    {
      ...employeeFormDataFiltered,
      userType: 'individual',
      userSubType: 'employee',
    },
    { skip: employeeSkip }
  )

  const {
    data: unassignedEmployee = [],
    isFetching: isUnassignedEmployeeFetching,
    isLoading: isUnassignedEmployeeLoading,
    isError: isUnassignedEmployeeError,
  } = useGetUsersQuery(
    {
      ...employeeFormData2Filtered,
      userType: 'individual',
      userSubType: 'employee',
      assigned: false,
    },
    { skip: unassignedEmployeeSkip }
  )

  const onEmployeeSubmit = (formData) => {
    setEmployeeFormData({ ...formData })
    setEmployeeSkip(false)
  }

  const onUnassignedEmployeeSubmit = (formData) => {
    setAssignedEmployeeFormData({ ...formData })
    setUnassignedEmployeeSkip(false)
  }
  return (
    <>
      {(isEmployeeError || isUnassignedEmployeeError) && (
        <Toast
          message={'Error Fetching Users Try Again in Sometime'}
          severity={'error'}
        />
      )}
      {isEmployeeLoading &&
        isUnassignedEmployeeLoading &&
        isUnassignedEmployeeFetching &&
        isEmployeeFetching && <CustomLoading />}
      {step === 1 && (
        <EmployeeForm
          formData={employeeFormData}
          onSubmit={onEmployeeSubmit}
          formHeader={'Employee To Employee Mapping'}
        />
      )}
      {step === 1 && !!employees.length && (
        <EmployeeTable
          tableData={employees}
          setUserObj={setUser}
          setStep={setStep}
          setAssignTo={setAssignTo}
        />
      )}
      {step === 2 && (
        <EmployeeDetails
          user={user}
          handleClick={() => setStep((prev) => prev - 1)}
        />
      )}
      {step === 3 && (
        <EmployeeForm
          formData={unAssignedEmployeeFormData}
          onSubmit={onUnassignedEmployeeSubmit}
          formHeader={'Search Employee for Mapping'}
        />
      )}
      {step === 3 && !!unassignedEmployee.length && (
        <EmployeeMappingTable
          setStep={setStep}
          tableData={unassignedEmployee}
          assignTo={assignTo}
        />
      )}
    </>
  )
}

export default EmployeeEmployeeMapping
