import { Typography } from '@mui/material'
import {
  CustomButton,
  CustomDatePicker,
  CustomDropdown,
  CustomTextField,
  CustomPaper,
  PincodeInput,
  LocationDropdown,
} from 'components'
import {
  Branches,
  selectUserOptions,
  userOptions,
  userSubTypeOptions,
} from 'constant/formOptions'
import { useCustomForm } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { usersSchema } from 'schema'

function UsersForm({ onSubmit, formData, formHeader }) {
  const { handleSubmit, control, watch } = useCustomForm(formData, usersSchema)
  const navigate = useNavigate()

  return (
    <CustomPaper>
      <Typography
        component={'h1'}
        sx={{
          fontWeight: 700,
          fontSize: { xs: 15, sm: 15, md: 20, lg: 24 },
        }}
        className="p-4 pl-8 text-center capitalize"
      >
        {formHeader}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-6 lg:grid-cols-2 md:grid-cols-1 ">
          <CustomDatePicker
            label="From Date"
            name={'dateFrom'}
            control={control}
          />
          <CustomDatePicker label="To Date" name={'dateTo'} control={control} />
          <CustomDropdown
            name={'selectUser'}
            control={control}
            label="Select User"
            options={selectUserOptions}
            isRequired
          />
          <CustomDropdown
            name={'userType'}
            control={control}
            label="User Type"
            options={userOptions}
            isRequired
          />
          {watch('selectUser') === 'byName' && (
            <CustomTextField
              name={'name'}
              control={control}
              label={'Name'}
              isRequired
            />
          )}
          <CustomDropdown
            name={'userSubType'}
            control={control}
            label="User Sub Type"
            options={userSubTypeOptions}
            isRequired
          />
          <LocationDropdown control={control} watch={watch} />
          <PincodeInput control={control} name={'pincode'} label={'Pin Code'} />
          {watch('city') === 'others' && (
            <CustomTextField
              control={control}
              name={'newCity'}
              label="Add New City/Area"
              isRequired
            />
          )}
          <CustomDropdown
            name={'branch'}
            control={control}
            label="Branch"
            options={Branches}
          />
        </div>
        <div className="flex justify-center gap-4 my-4">
          <CustomButton type="submit" variant={'contained'}>
            Search
          </CustomButton>
          <CustomButton variant={'outlined'} onClick={() => navigate('/')}>
            Back
          </CustomButton>
        </div>
      </form>
    </CustomPaper>
  )
}

export default UsersForm
