import Papa from 'papaparse'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHelperText } from '@mui/material'
import { CustomButton, CustomLabel, Toast } from 'components'
import { mobileFormatChange } from 'utils/CustomUtils'
import { useState } from 'react'

const CsvUploadForm = ({ postData, smartCovrFormat, label }) => {
  const [invalidHeaders, setInvalidHeaders] = useState(false)
  const schema = yup.object().shape({
    csvFile: yup.mixed().test('fileType', 'CSV file required', (value) => {
      if (!value[0]) return false // for handling errors with empty file inputs
      return ['text/csv'].includes(value[0].type)
    }),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // csvFile: ""
    },
  })
  const acceptedHeaders = [
    'leadType',
    'leadSubType',
    'title',
    'leadName',
    'dob',
    'mobile',
    'email',
    'country',
    'region',
    'state',
    'district',
    'pincode',
    'city',
    'leadSource',
    'occupation',
    'organization',
    'policyOption',
  ]

  const onSubmit = (data) => {
    const file = data.csvFile[0]
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (result) {
        const headers = result.meta.fields // Retrieve the headers from the parsed CSV

        // Check if all headers in the CSV are included in the acceptedHeaders array
        const isValidHeaders = headers.every((header) =>
          acceptedHeaders.includes(header)
        )
        // Check if the acceptedHeaders array contains exactly the same headers as the CSV
        const isValidLength = acceptedHeaders.length === headers.length

        if (!isValidHeaders || !isValidLength) {
          // If any additional headers or missing headers are found, display error message modal
          // Proceed with further processing if headers are valid
          setInvalidHeaders(true)
        } else {
          const formattedData = smartCovrFormat
            ? mobileFormatChange(result)
            : result.data
          postData(formattedData)
        }
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="md:flex mb-5">
      <div className="mb-4 flex flex-col md:items-start mt-4 space-y-2">
        <CustomLabel>Upload Bulk {label}</CustomLabel>
        <CustomLabel>Format: CSV</CustomLabel>
        <input
          type="file"
          id="csvFile"
          name="csvFile"
          aria-invalid={errors.csvFile ? 'true' : 'false'}
          {...register('csvFile')}
        />
        {errors.csvFile && (
          <FormHelperText error={!!errors}>
            {errors.csvFile?.message}
          </FormHelperText>
        )}
      </div>
      <CustomButton variant="contained" type="submit" className="self-center">
        Upload
      </CustomButton>
      {invalidHeaders && (
        <Toast
          message={'Please upload CSV with valid table headers'}
          severity={'error'}
        />
      )}
    </form>
  )
}

export default CsvUploadForm
