function Landing() {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="max-w-lg px-8 py-12 bg-white rounded-lg shadow-lg">
        <h1 className="mb-4 text-3xl font-bold">
          Welcome to the Agent Dashboard
        </h1>
        <p className="mb-6 text-sm text-gray-600">
          From here, you can access various features and tools to manage your
          clients and tasks. Explore the menu to get started!
        </p>
        <button className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-700">
          Get Started
        </button>
      </div>
    </div>
  )
}

export default Landing
