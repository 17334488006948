import * as yup from 'yup'
const today = new Date()

export const userSchema = yup.object().shape({
  userType: yup
    .string()
    .required('Please select User Type from the list above'),
  userSubType: yup
    .string()
    .required('Please select User Sub Type from the list above'),
  pincode: yup
    .string()
    .matches(/^$|^\d{6}$/, 'Pincode must be exactly 6 digits'),
})

export const leadSchema = yup.object({
  dateFrom: yup
    .date()
    .required()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .required('Please Provide From Date')
    .min(
      new Date(today.getFullYear() - 80, today.getMonth(), today.getDate()),
      'Enter Valid Date'
    )
    .max(new Date(), 'Enter Valid Date'),
  dateTo: yup
    .date()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .min(yup.ref('dateFrom'), "End date can't be before Start date")
    .required('Please provide To Date')
    .max(new Date(), 'Enter Valid Date'),
  userWiseLead: yup
    .string()
    .required('please select Userwise Lead from the list above'),
  name: yup.string().when('userWiseLead', {
    is: (value) => value && value === 'byName',
    then: () =>
      yup
        .string()
        .required()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .min(3, 'Must be minimum 3 characters')
        .max(20, 'Must be less Than 20 characters'),
    otherwise: () => yup.string().notRequired(),
  }),
  leadType: yup
    .string()
    .required('please select Lead Type from the list above'),
  leadSubType: yup
    .string()
    .required('please select Lead Sub Type from the list above'),
  pincode: yup
    .string()
    .matches(/^$|^\d{6}$/, 'Pincode must be exactly 6 digits'),
  newCity: yup
    .string()
    .when('city', { is: 'others', then: (schema) => schema.min(3) })
    .matches(/^[aA-zZ\s]+$/, 'Please Provide New City Name'),
})
