import { Checkbox } from '@mui/material'
import { CustomButton, CustomTable } from 'components'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const tableHeaders = [
  { label: '', field: 'check' },
  { label: 'sr. no.', field: '' },
  { label: 'Employee Code', field: 'employeeCode' },
  { label: 'First Name', field: 'firstName' },
  { label: 'Last Name', field: 'lastName' },
  { label: 'Pincode', field: 'pincode' },
  { label: 'Phone Number', field: 'phoneNo' },
  { label: 'View', field: 'view' },
]

function EmployeeRemappingTable(props) {
  const { tableData, setStep, setUserObj, setAssignTo, step } = props
  const [selected, setSelected] = useState('')
  const navigate = useNavigate()

  const onClickSave = () => {
    setAssignTo(selected)
    setStep(step + 2)
  }
  const handleClick = (user) => {
    setUserObj(user)
    setStep(step + 1)
  }

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'employeeCode':
        return row.employeeCode
      case 'firstName':
        return row.firstName
      case 'lastName':
        return row.lastName
      case 'country':
        return row.country
      case 'pincode':
        return row.pincode
      case 'phoneNo':
        return `+${row.officeMobile}`
      case 'check':
        return (
          <Checkbox
            checked={selected === row.employeeCode}
            onChange={() => setSelected(row.employeeCode)}
          />
        )
      case 'view':
        return (
          <CustomButton variant={'outlined'} onClick={() => handleClick(row)}>
            View
          </CustomButton>
        )
      default:
        return ''
    }
  }
  return (
    <>
      <CustomTable
        tableLabel="Employee Selection"
        headers={tableHeaders}
        rows={tableData}
        render={renderCell}
      />
      <div className="flex justify-center gap-4 my-4">
        <CustomButton
          type="submit"
          variant={'contained'}
          disabled={selected ? false : true}
          onClick={onClickSave}
        >
          Save & proceed
        </CustomButton>
        <CustomButton variant={'outlined'} onClick={() => navigate('/')}>
          Back
        </CustomButton>
      </div>
    </>
  )
}

export default EmployeeRemappingTable
