import { useState } from 'react'
import {
  LeadDetails,
  LeadTable,
  LeadsReallocationForm,
  LeadsReallocationTable,
  ReassignLeadsForm,
} from 'features/reassignLeads'
import { CustomLoading } from 'components'
export default function ReassignLeads() {
  const [step, setStep] = useState(1)
  const [userObj, setUserObj] = useState({})
  const [tableData, setTableData] = useState([])
  const [leadData, setLeadData] = useState([])
  const [showLeadTable, setShowLeadTable] = useState(false)
  const [showLeadReallocationTable, setShowLeadReallocationTable] =
    useState(false)
  const [leadNumbers, setLeadNumbers] = useState()
  const [userTypeValue, setUserTypeValue] = useState()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      {isLoading && <CustomLoading />}
      {step === 1 && (
        <ReassignLeadsForm
          setTableData={setTableData}
          setShowLeadTable={setShowLeadTable}
          setIsLoading={setIsLoading}
        />
      )}
      {step === 1 && showLeadTable && (
        <LeadTable
          setLeadNumbers={setLeadNumbers}
          tableData={tableData}
          setStep={setStep}
          setUserObj={setUserObj}
        />
      )}
      {(step === 2 || step === 4) && (
        <LeadDetails
          user={userObj}
          handleClick={() => setStep((prev) => prev - 1)}
          step={step}
        />
      )}
      {step === 3 && (
        <LeadsReallocationForm
          setUserTypeValue={setUserTypeValue}
          setTableData={setLeadData}
          setStep={setStep}
          setShowLeadReallocationTable={setShowLeadReallocationTable}
          setIsLoading={setIsLoading}
        />
      )}
      {step === 3 && showLeadReallocationTable && (
        <LeadsReallocationTable
          userTypeValue={userTypeValue}
          leadNumbers={leadNumbers}
          tableData={leadData}
          step={step}
          setStep={setStep}
          setUserObj={setUserObj}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  )
}
