import { CustomLoading, Toast } from 'components'
import { useState } from 'react'
import {
  EmployeeDetails,
  EmployeeTable,
  MappedEmployeeTable,
} from 'features/viewMappedEmployee'
import { useGetEmployeeQuery } from 'api/usersAPI'
import { filterFormData } from 'utils/CustomUtils'
import { EmployeeForm } from 'forms'

export default function ViewMappedEmployee() {
  const [step, setStep] = useState(1)
  const [userObj, setUserObj] = useState({})
  const [employeeData, setEmployeeData] = useState([])
  const [employeeSkip, setEmployeeSkip] = useState(true)
  const [employeeFormData, setEmployeeFormData] = useState({})

  const formDataFiltered = filterFormData(employeeFormData)
  const {
    data: employees = [],
    isLoading,
    isError,
    isFetching,
  } = useGetEmployeeQuery(
    {
      ...formDataFiltered,
      userType: 'individual',
      userSubType: 'employee',
    },
    { skip: employeeSkip }
  )

  const onSubmit = (formData) => {
    setEmployeeFormData({ ...formData })
    setEmployeeSkip(false)
  }

  return (
    <>
      {isLoading && isFetching && <CustomLoading />}
      {isError && (
        <Toast
          message={'Error Fetching Users Try Again in Sometime'}
          severity={'error'}
        />
      )}
      {step === 1 && (
        <EmployeeForm
          formData={employeeFormData}
          onSubmit={onSubmit}
          formHeader={'View Mapped Employee'}
        />
      )}
      {step === 1 && !!employees.length && (
        <EmployeeTable
          setUserObj={setUserObj}
          tableData={employees}
          setStep={setStep}
          setEmployeeData={setEmployeeData}
        />
      )}
      {(step === 2 || step === 4) && (
        <EmployeeDetails
          user={userObj}
          handleClick={() => setStep((prev) => prev - 1)}
          step={step}
        />
      )}
      {step === 3 && (
        <MappedEmployeeTable
          setUserObj={setUserObj}
          setStep={setStep}
          tableData={employeeData}
        />
      )}
    </>
  )
}
