import { Typography } from '@mui/material'
import {
  CustomDatePicker,
  CustomDropdown,
  CustomButton,
  CustomPaper,
  PincodeInput,
  LocationDropdown,
  CustomTextField,
} from 'components'
import {
  userOptions,
  leadOptions,
  Branches,
  userViewLeadsSubTypeOptions,
  LifeSubTypeOptions,
  HealthSubTypeOptions,
  GeneralSubTypeOption,
} from 'constant/formOptions'
import { useNavigate } from 'react-router-dom'
import { useCustomForm } from 'hooks/useCustomForm'
import { viewLeadSchema } from './schema'

export default function ViewLeadsForm({ formData, onSubmit }) {
  const { control, watch, handleSubmit } = useCustomForm(
    formData,
    viewLeadSchema
  )

  const navigate = useNavigate()

  let leadSubType = []
  if (watch('leadType') === 'Life insurance') {
    leadSubType = LifeSubTypeOptions
  } else if (watch('leadType') === 'Health insurance') {
    leadSubType = HealthSubTypeOptions
  } else if (watch('leadType') === 'General insurance') {
    leadSubType = GeneralSubTypeOption
  }

  return (
    <CustomPaper>
      <Typography
        component={'h1'}
        sx={{
          fontWeight: 700,
          fontSize: { xs: 15, sm: 15, md: 20, lg: 24 },
        }}
        className="p-4 pl-8 text-center capitalize"
      >
        View Assigned Leads
      </Typography>
      <div className="grid gap-6 lg:grid-cols-2 md:grid-cols-1 ">
        <CustomDatePicker
          label="From Date"
          name={'dateFrom'}
          control={control}
        />
        <CustomDatePicker label="To Date" name={'dateTo'} control={control} />
        <CustomDropdown
          name={'leadType'}
          control={control}
          label="Lead Type"
          options={leadOptions}
          isRequired={true}
        />
        <CustomDropdown
          name={'leadSubType'}
          control={control}
          label="Lead Sub Type"
          options={leadSubType}
          isRequired={true}
        />
        <CustomDropdown
          name={'userType'}
          control={control}
          label="User Type"
          options={userOptions}
        />
        <CustomDropdown
          name={'userSubType'}
          control={control}
          label="User Sub Type"
          options={userViewLeadsSubTypeOptions}
        />
        <LocationDropdown control={control} watch={watch} />
        <PincodeInput control={control} name={'pincode'} label={'Pin Code'} />
        {watch('city') === 'others' && (
          <CustomTextField
            control={control}
            name={'newCity'}
            label="Add New City/Area"
            isRequired
          />
        )}
        <CustomDropdown
          name={'branch'}
          control={control}
          label="Branch"
          options={Branches}
        />
      </div>
      <div className="flex justify-center gap-4 my-4">
        <CustomButton
          type="submit"
          variant={'contained'}
          onClick={handleSubmit(onSubmit)}
        >
          Search
        </CustomButton>
        <CustomButton variant={'outlined'} onClick={() => navigate('/')}>
          Back
        </CustomButton>
      </div>
    </CustomPaper>
  )
}
