import * as yup from 'yup'

const profileCodeSchema = yup.object({
  userSubType: yup
    .string()
    .required('Please select User Sub-Type from the list above'),
  profileCode: yup
    .string()
    .required('Please Provide Profile Code')
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric are allowed for this field ')
    .max(20, 'Must be less than 20 characters'),
  name: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, 'Only alphanumeric are allowed for this field ')
    .max(50, 'Must be less than 50 characters'),
})

export default profileCodeSchema
