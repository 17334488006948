import * as yup from 'yup'

export const userSchema = yup.object({
  userType: yup
    .string()
    .required('Please select User Type from the list above'),
  userSubType: yup
    .string()
    .required('Please select User Sub-Type from the list above'),
  pincode: yup
    .string()
    .matches(/^$|^\d{6}$/, 'Pincode must be exactly 6 digits'),
  newCity: yup
    .string()
    .when('city', { is: 'others', then: (schema) => schema.min(3) })
    .matches(/^[aA-zZ\s]+$/, 'Please Provide New City Name'),
})

export const userProfileCodeSchema = yup.object({
  userType: yup
    .string()
    .required('Please select User Type from the list above'),
  userSubType: yup
    .string()
    .required('Please select User Sub-Type from the list above'),
  profileCode: yup
    .string()
    .required('Please Provide Profile Code')
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric are allowed for this field ')
    .max(50, 'Must be less than 50 characters'),
})
