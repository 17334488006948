import { CustomLoading, Toast } from 'components'
import {
  EmployeeDetails,
  EmployeeRemappingTable,
  AgentPosRemappingTable,
} from 'features/employeeToAgentRemapping'
import { useState } from 'react'
import { EmployeeForm, AgentPosForm } from 'forms'
import { useGetUsersQuery } from 'api/usersAPI'
import { filterFormData } from 'utils/CustomUtils'

function EmployeeAgentRemapping() {
  const [step, setStep] = useState(1)
  const [agentFormData, setAgentFormData] = useState({})
  const [user, setUser] = useState({})
  const [employeeSkip, setEmployeeSkip] = useState(true)
  const [agentSkip, setAgentSkip] = useState(true)
  const [employeeFormData, setEmployeeFormData] = useState({})
  const [reAssignTo, setReAssignTo] = useState('')

  const employeeFormDataFiltered = filterFormData(employeeFormData)
  const agentFormDataFiltered = filterFormData(agentFormData)

  const {
    data: employees = [],
    isFetching: isEmployeeFetching,
    isLoading: isEmployeeLoading,
    isError: isEmployeeError,
  } = useGetUsersQuery(
    {
      ...employeeFormDataFiltered,
      userType: 'individual',
      userSubType: 'employee',
    },
    { skip: employeeSkip }
  )

  const {
    data: agents = [],
    isFetching: isAgentFetching,
    isLoading: isAgentLoading,
    isError: isAgentError,
  } = useGetUsersQuery(
    {
      ...agentFormDataFiltered,
      assigned: true,
    },
    { skip: agentSkip }
  )

  const onEmployeeSubmit = (formData) => {
    setEmployeeFormData({ ...formData })
    setEmployeeSkip(false)
  }

  const onAgentSubmit = (formData) => {
    setAgentFormData({ ...formData })
    setAgentSkip(false)
  }

  return (
    <>
      {(isEmployeeError || isAgentError) && (
        <Toast
          message={'Error Fetching Users Try Again in Sometime'}
          severity={'error'}
        />
      )}
      {(isEmployeeLoading ||
        isEmployeeFetching ||
        isAgentLoading ||
        isAgentFetching) && <CustomLoading />}
      {step === 1 && (
        <EmployeeForm
          formData={employeeFormData}
          onSubmit={onEmployeeSubmit}
          formHeader={'Employee To Agent Re-Mapping'}
        />
      )}
      {step === 1 && !!employees.length && (
        <EmployeeRemappingTable
          tableData={employees}
          setUserObj={setUser}
          step={step}
          setStep={setStep}
          setAssignTo={setReAssignTo}
        />
      )}
      {step === 2 && (
        <EmployeeDetails
          user={user}
          handleClick={() => setStep((prev) => prev - 1)}
        />
      )}
      {step === 3 && (
        <AgentPosForm
          formData={agentFormData}
          onSubmit={onAgentSubmit}
          setStep={setStep}
          formHeader={'Search Agents/User '}
        />
      )}
      {step === 3 && !!agents.length && (
        <AgentPosRemappingTable
          setStep={setStep}
          tableData={agents}
          agentFormData={agentFormData}
          empCode={reAssignTo}
        />
      )}
    </>
  )
}

export default EmployeeAgentRemapping
