import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from 'config'

export const logoutAPI = createApi({
  reducerPath: 'logoutAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: config.logoutUrl,
  }),
  endpoints: (builder) => ({
    logout: builder.mutation({
      query: ({ accessToken, refreshToken }) => ({
        method: 'POST',
        headers: {
          ...config.apiHeaders,
        },
        body: JSON.stringify({
          accessToken,
          refreshToken,
        }),
      }),
    }),
  }),
})

export const { useLogoutMutation } = logoutAPI
