import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

const CustomButton = styled(Button)(({ theme }) => ({
  '&.Mui-disabled': {
    color: theme.palette.action.disabled,
  },
  borderRadius: theme.shape.borderRadius,
  textTransform: 'uppercase',
  boxShadow: 'none',
  letterSpacing: '.1rem',
  transition: 'transform .2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}))

export default CustomButton
