import { createApi } from '@reduxjs/toolkit/query/react'
import { wrappedMetadataBaseQuery } from './metadataBaseQuery'

export const metadataAPI = createApi({
  reducerPath: 'metadata',
  baseQuery: wrappedMetadataBaseQuery,
  endpoints: (builder) => ({
    getPincode: builder.query({
      query: (filters) => ({
        url: '/pincode',
        params: filters,
      }),
    }),
  }),
})

export const { useGetPincodeQuery } = metadataAPI
