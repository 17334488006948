import {
  AddBoxOutlined as AddBoxOutlinedIcon,
  ListAltOutlined as ListAltOutlinedIcon,
  AssignmentIndOutlined as AssignmentIndOutlinedIcon,
  PeopleOutlined as PeopleOutlinedIcon,
} from '@mui/icons-material'

export const navItems = [
  {
    label: 'Create/Add New User',
    path: '/create-users',
    icon: <PeopleOutlinedIcon />,
    exact: false,
  },
  {
    label: 'View User List',
    path: '/view-users',
    icon: <ListAltOutlinedIcon />,
    exact: true,
  },
  {
    label: 'Employee To Agent Mapping (DO)',
    path: '/employee-agent-mapping',
    icon: <ListAltOutlinedIcon />,
    exact: false,
  },
  {
    label: 'Employee To Employee Mapping',
    path: '/employee-employee-mapping',
    icon: <ListAltOutlinedIcon />,
    exact: false,
  },
  {
    label: 'View Mapped Agents',
    path: '/view-mapped-agents',
    icon: <ListAltOutlinedIcon />,
    exact: false,
  },
  {
    label: 'Employee To Agent Re-Mapping',
    path: '/employee-agent-remapping',
    icon: <ListAltOutlinedIcon />,
    exact: false,
  },
  {
    label: 'View Mapped Employee',
    path: '/view-mapped-employee',
    icon: <ListAltOutlinedIcon />,
    exact: false,
  },
  {
    label: 'Employee To Employee Re-Mapping',
    path: '/employee-employee-remapping',
    icon: <ListAltOutlinedIcon />,
    exact: false,
  },
  {
    label: 'Create Lead',
    path: '/create-leads',
    icon: <AddBoxOutlinedIcon />,
    exact: false,
  },
  {
    label: 'Assign Leads To Self',
    path: '/assign-leads-to-self',
    icon: <AssignmentIndOutlinedIcon />,
    exact: false,
  },
  {
    label: 'Assign Leads & Allocation',
    path: '/assign-leads-allocation',
    icon: <AssignmentIndOutlinedIcon />,
    exact: false,
  },
  {
    label: 'View Assigned Leads',
    path: '/view-assigned-leads',
    icon: <ListAltOutlinedIcon />,
    exact: false,
  },
  {
    label: 'View All Leads',
    path: '/all-leads',
    icon: <ListAltOutlinedIcon />,
    exact: false,
  },
  {
    label: 'View Mapped Leads',
    path: '/mapped-leads',
    icon: <ListAltOutlinedIcon />,
    exact: false,
  },
  {
    label: 'Reassign Lead & Allocation',
    path: '/reassign-leads',
    icon: <AssignmentIndOutlinedIcon />,
    exact: false,
  },
  {
    label: 'View My Leads',
    path: '/my-leads',
    icon: <ListAltOutlinedIcon />,
    exact: false,
  },
  {
    label: 'View Mapped Clients',
    path: '/view-mapped-clients',
    icon: <ListAltOutlinedIcon />,
    exact: false,
  },
  {
    label: 'View All Clients',
    path: '/all-clients',
    icon: <ListAltOutlinedIcon />,
    exact: false,
  },
  {
    label: ' View My Clients',
    path: '/my-clients',
    icon: <ListAltOutlinedIcon />,
    exact: false,
  },
  {
    label: 'Reassign Clients',
    path: '/reassign-clients',
    icon: <AssignmentIndOutlinedIcon />,
    exact: false,
  },
]
