import { ListItemButton, ListItemText } from '@mui/material'

function NavItem({ navItem, location, handleListItemButtonClick }) {
  const isSelected = navItem.exact
    ? location.pathname === navItem.path
    : location.pathname.startsWith(navItem.path)

  return (
    <ListItemButton
      key={navItem.label}
      onClick={() => handleListItemButtonClick(navItem)}
      selected={isSelected}
      className={`flex items-center gap-2 px-4 py-2 rounded-lg hover:bg-gray-200 ${
        isSelected ? 'bg-gray-500' : ''
      }`}
    >
      {navItem.icon}
      <ListItemText primary={navItem.label} />
    </ListItemButton>
  )
}

export default NavItem
