import { useCallback, useState } from 'react'
import { Box, TextField, Button } from '@mui/material'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import * as XLSX from 'xlsx'
import { checkSmartcovrMobileFormat } from 'utils/CustomUtils'
import CustomLabel from './CustomLabel'
import { parseISO, format } from 'date-fns'

const CustomDataRangeDownloader = ({
  className,
  tableHeaders,
  tableData,
  fileName,
}) => {
  const [range, setRange] = useState(`1-${tableData.length}`)
  const [error, setError] = useState('')

  const handleRangeChange = useCallback(
    (e) => {
      const input = e.target.value
      const totalRows = tableData.length

      if (!input) {
        setError('Please Enter Range')
        setRange('')
      } else if (/^[1-9]\d*-[1-9]\d*$/.test(input)) {
        const selectedRange = input.split('-')
        const start = parseInt(selectedRange[0])
        const end = selectedRange[1] ? parseInt(selectedRange[1]) : totalRows
        if (start <= totalRows && end <= totalRows && start <= end) {
          setError('')
          setRange(input)
        } else if (start > end) {
          setError('Start number must be less than or equal to end number')
          setRange(input)
        } else {
          setError(
            `Invalid Range 1-${tableData.length}. Enter within this range.`
          )
          setRange(input)
        }
      } else {
        setError('Invalid Format or Range')
        setRange(input)
      }
    },
    [tableData.length]
  )

  const generateOutput = (data, format) => {
    if (format === 'PDF') {
      const doc = new jsPDF({ format: [420, 594] })
      const tableData = data.map((row) =>
        tableHeaders.map((header) => row[header.value])
      )
      doc.autoTable({
        head: [tableHeaders.map((header) => header.label)],
        body: tableData,
      })
      doc.save(`${fileName}_${range}.pdf`)
    } else if (format === 'CSV') {
      const csvData = [tableHeaders.map((tableHeader) => tableHeader.label)]
      data.forEach((row) => {
        const csvRow = []
        tableHeaders.forEach((header) => {
          const value = row[header.value]
          if (typeof value !== 'undefined') {
            csvRow.push(value)
          } else {
            csvRow.push('NA')
          }
        })
        csvData.push(csvRow)
      })
      const csvFileName = `${fileName}_${range}.csv`
      const csvLink = document.createElement('a')
      csvLink.style.display = 'none'
      document.body.appendChild(csvLink)
      csvLink.setAttribute(
        'href',
        encodeURI(
          `data:text/csv;charset=utf-8,${csvData
            .map((row) => row.join(','))
            .join('\n')}`
        )
      )
      csvLink.setAttribute('download', csvFileName)
      csvLink.click()
      document.body.removeChild(csvLink)
    } else if (format === 'XLSX') {
      const worksheet = XLSX.utils.json_to_sheet(data)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      XLSX.writeFile(workbook, `${fileName}_${range}.xlsx`)
    }
  }

  const handleDownload = (fileType) => {
    const data = []
    const selectedRange = range.split('-')
    const startIndex = parseInt(selectedRange[0]) - 1
    const endIndex = selectedRange[1]
      ? parseInt(selectedRange[1])
      : tableData.length

    for (let i = startIndex; i < endIndex; i++) {
      const row = {}
      for (let j = 0; j < tableHeaders.length; j++) {
        let value = tableData[i][tableHeaders[j].value]

        if (tableHeaders[j].value === 'gender') {
          const title = tableData[i].title
          if (title === 'mr' || title === 'mr.') {
            value = 'Male'
          } else if (
            title === 'ms' ||
            title === 'ms.' ||
            title === 'mrs' ||
            title === 'mrs.'
          ) {
            value = 'Female'
          } else {
            value = 'NA'
          }
        } else if (tableHeaders[j].value === 'address') {
          const address = tableData[i]?.address?.permanentAddress
          value = address ? address.replace(/,/g, ' ') : 'NA'
        } else if (tableHeaders[j].value === 'createdAt') {
          value = format(parseISO(value), 'dd-MM-yyyy')
        }

        const stringValue = checkSmartcovrMobileFormat(value)
        row[tableHeaders[j].value] = stringValue
      }
      data.push(row)
    }
    generateOutput(data, fileType)
  }

  return (
    <Box className={`space-y-4 ${className}`}>
      <div className="flex flex-col space-y-1">
        <CustomLabel>Choose Data Range:</CustomLabel>
        <TextField
          placeholder={`1-${tableData.length}`}
          variant="outlined"
          onChange={handleRangeChange}
          value={range}
          type="text"
          className="px-3 py-2 bg-white border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        />
        {error && (
          <span className="text-sm text-center text-red-600">{error}</span>
        )}
      </div>
      <div className="flex justify-center gap-2">
        <Button
          onClick={() => handleDownload('PDF')}
          variant="contained"
          className="px-6 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
          disabled={!!error}
        >
          PDF
        </Button>
        <Button
          variant="contained"
          onClick={() => handleDownload('XLSX')}
          className="px-6 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
          disabled={!!error}
        >
          XLS
        </Button>
        <Button
          variant="contained"
          onClick={() => handleDownload('CSV')}
          className="px-6 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
          disabled={!!error}
        >
          CSV
        </Button>
      </div>
    </Box>
  )
}

export default CustomDataRangeDownloader
