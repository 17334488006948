export const selectUserOptions = [
  { label: 'All', value: 'all' },
  { label: 'By Name', value: 'byName' },
]

export const leadOptions = [
  { label: 'Life Insurance', value: 'Life insurance' },
  { label: 'General Insurance', value: 'General insurance' },
  { label: 'Health Insurance', value: 'Health insurance' },
]
export const LifeSubTypeOptions = [
  { label: 'Term', value: 'term' },
  { label: 'Annuity', value: 'annuity' },
]

export const HealthSubTypeOptions = [{ label: 'Health', value: 'health' }]

export const GeneralSubTypeOption = [
  { label: 'Car', value: 'car' },
  { label: 'Bike', value: 'bike' },
]

export const userOptions = [{ label: 'Individual', value: 'individual' }]

export const userSubTypeOptions = [
  { label: 'POS', value: 'pos' },
  { label: 'Agent', value: 'agent' },
  { label: 'Employee', value: 'employee' },
]

export const userViewLeadsSubTypeOptions = [
  { label: 'POS', value: 'Pos' },
  { label: 'Agent', value: 'Agent' },
  { label: 'Employee', value: 'Employee' },
]

export const agentSubTypeOptions = [
  { label: 'POS', value: 'pos' },
  { label: 'Agent', value: 'agent' },
]

export const Countries = [{ label: 'India', value: 'india' }]

export const Branches = [{ label: 'Dummy Branch', value: 'branch000' }]

export const Regions = [
  { label: 'East', value: 'east' },
  { label: 'West', value: 'west' },
  { label: 'North', value: 'north' },
  { label: 'South', value: 'south' },
]

export const orderArray = [
  { label: 'Ascending', value: 'ascending' },
  { label: 'Descending', value: 'descending' },
]

export const leadSource = [
  { label: 'Friend', value: 'friend' },
  { label: 'Relative', value: 'relative' },
  { label: 'Stranger', value: 'stranger' },
  { label: 'Marketing', value: 'marketing' },
  { label: 'Event', value: 'event' },
  { label: 'Campaign', value: 'campaign' },
  { label: 'Web', value: 'web' },
  { label: 'Social Media', value: 'Social Media' },
]

export const TitleOption = [
  { label: 'Mr.', value: 'mr.' },
  { label: 'Mrs.', value: 'mrs.' },
  { label: 'Ms.', value: 'ms.' },
]

export const lookingFor = [
  { label: 'Buy New', value: 'buyNew' },
  { label: 'Renewal', value: 'renewal' },
  { label: 'Top Up', value: 'topUp' },
]

export const qualificationOption = [
  { label: 'S.S.C', value: 's.s.c' },
  { label: 'H.S.C.', value: 'h.s.c' },
  { label: 'Diploma', value: 'diploma' },
  { label: 'Graduate', value: 'graduate' },
  { label: 'Post-Graduate', value: 'post-graduate' },
  { label: 'Professional degree', value: 'professional degree' },
]

export const generalInsurance = [
  { label: 'General-1', value: 'General-1' },
  { label: 'General-2', value: 'General-2' },
  { label: 'General-3', value: 'General-3' },
]

export const healthInsurance = [
  { label: 'Health-1', value: 'Health-1' },
  { label: 'Health-2', value: 'Health-2' },
  { label: 'Health-3', value: 'Health-3' },
]

export const lifeInsurance = [
  { label: 'Life-1', value: 'Life-1' },
  { label: 'Life-2', value: 'Life-2' },
  { label: 'Life-3', value: 'Life-3' },
]

export const leadStatusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Qualify', value: 'qualify' },
  { label: 'Dis-qualify', value: 'dis-qualify' },
]

export const employeeDesignationOptions = [
  { label: 'Chairman', value: 'Chairman' },
  { label: 'Managing Director', value: 'Managing Director' },
  { label: 'Zonal Director', value: 'Zonal Director' },
  { label: 'Director', value: 'Director' },
  { label: 'Chief Financial Officer', value: 'Chief Financial Officer' },
  { label: 'Senior Vice President', value: 'Senior Vice President' },
  { label: 'Vice President', value: 'Vice President' },
  { label: 'Assistant Vice President', value: 'Assistant Vice President' },
  { label: 'Associate Vice President', value: 'Associate Vice President' },
  { label: 'Sales Head', value: 'Sales Head' },
  { label: 'Zonal Manager', value: 'Zonal Manager' },
  { label: 'Zonal Sales Head', value: 'Zonal Sales Head' },
  { label: 'Sourcing Manager', value: 'Sourcing Manager' },
  { label: 'Relationship Manager', value: 'Relationship Manager' },
  { label: 'Manager', value: 'Manager' },
  { label: 'Accounts Manager', value: 'Accounts Manager' },
  { label: 'Officer', value: 'Officer' },
  { label: 'Senior Officer', value: 'Senior Officer' },
  { label: 'Back Office Manager', value: 'Back Office Manager' },
  { label: 'Executive Back Office', value: 'Executive Back Office' },
  { label: 'Customer Relation Officer', value: 'Customer Relation Officer' },
  { label: 'Area Sales Manager', value: 'Area Sales Manager' },
  { label: 'Sales Officer', value: 'Sales Officer' },
  { label: 'Feet on Street', value: 'Feet on Street' },
]
