import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from 'config'

export const authenticationAPI = createApi({
  reducerPath: 'authenticationAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: config.tptTokenUrl,
  }),
  endpoints: (builder) => ({
    tptToken: builder.mutation({
      query: ({ code, redirectTo, redirectUri }) => ({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          distributorId: 'IN-9820',
        },
        body: JSON.stringify({
          code,
          redirectTo,
          redirectUri,
        }),
      }),
    }),
  }),
})

export const { useTptTokenMutation } = authenticationAPI
