import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTptTokenMutation } from 'api/authenticationAPI'
import { getQueryParams } from 'utils/CustomUtils'
import { setLogin } from './authSlice'
import { useDispatch } from 'react-redux'
import { CustomLoading } from 'components'

function TptHandler() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [tptTokenMutation] = useTptTokenMutation()

  const [isLoading, setIsLoading] = useState(false)
  const isFetchedRef = useRef(false)

  useEffect(() => {
    const { code, session_state } = getQueryParams()
    const redirectTo = encodeURIComponent(`${window.location.origin}/#/`)
    const redirectUri = `${window.location.origin}/#/tpt-handler`

    if (!isFetchedRef.current && code && session_state) {
      isFetchedRef.current = true
      setIsLoading(true)

      tptTokenMutation({
        code,
        redirectTo,
        redirectUri,
      })
        .then((data) => {
          const { accessToken, refreshToken } = data.data
          document.cookie = `accessToken=${accessToken}; path=/`
          document.cookie = `refreshToken=${refreshToken}; path=/`
          dispatch(setLogin(true))

          const { code, session_state } = getQueryParams()
          const newUrl = window.location.href
            .replace(`?session_state=${session_state}`, '')
            .replace(`&code=${code}`, '')
          window.history.replaceState({}, document.title, newUrl)

          navigate('/')
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('TptHandler Error:', error)
          navigate('/')
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else if (!code || !session_state) {
      navigate('/')
    }
  }, [navigate, tptTokenMutation])

  return isLoading && <CustomLoading />
}

export default TptHandler
