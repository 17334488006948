import { useState } from 'react'
import { FormControlLabel, Switch, Typography } from '@mui/material'
import {
  CustomDatePicker,
  CustomButton,
  CustomDropdown,
  CustomTextField,
  CustomPaper,
  PincodeInput,
  LocationDropdown,
} from 'components'
import {
  userOptions,
  Branches,
  agentSubTypeOptions,
} from 'constant/formOptions'
import { agentPosSchema, profileCodeSchema } from 'schema'
import { useCustomForm } from 'hooks'

export default function AgentPosForm({
  setStep,
  onSubmit,
  formData,
  formHeader,
}) {
  const [showProfileForm, setShowProfileForm] = useState(false)

  const handleToggle = () => {
    setShowProfileForm((prev) => !prev)
    resetForm()
    resetForm2()
  }

  const { control, handleSubmit, resetForm, watch } = useCustomForm(
    formData,
    agentPosSchema
  )

  const {
    control: control2,
    handleSubmit: handleSubmit2,
    resetForm: resetForm2,
  } = useCustomForm(formData, profileCodeSchema)

  return (
    <CustomPaper>
      <Typography
        component={'h1'}
        sx={{
          fontWeight: 700,
          fontSize: { xs: 15, sm: 15, md: 20, lg: 24 },
        }}
        className="p-4 pl-8 text-center capitalize"
      >
        {formHeader}
      </Typography>
      <div className="flex justify-start my-4">
        <FormControlLabel
          control={<Switch checked={showProfileForm} onChange={handleToggle} />}
          label="Search By Profile Code"
        />
      </div>
      {!showProfileForm && (
        <form className="grid gap-6 lg:grid-cols-2 md:grid-cols-1 ">
          <CustomDatePicker
            label="From Date"
            name={'dateFrom'}
            control={control}
          />
          <CustomDatePicker label="To Date" name={'dateTo'} control={control} />
          <CustomDropdown
            name={'userType'}
            control={control}
            label="User Type"
            options={userOptions}
            isRequired
          />
          <CustomDropdown
            name={'userSubType'}
            control={control}
            label="User Sub Type"
            options={agentSubTypeOptions}
            isRequired
          />
          <LocationDropdown control={control} watch={watch} />
          <PincodeInput control={control} name={'pincode'} label={'Pin Code'} />
          {watch('city') === 'others' && (
            <CustomTextField
              control={control}
              name={'newCity'}
              label="Add New City/Area"
              isRequired
            />
          )}
          <CustomDropdown
            name={'branch'}
            control={control}
            select
            label="Branch"
            options={Branches}
          />
        </form>
      )}
      {showProfileForm && (
        <form className="grid gap-6 lg:grid-cols-2 md:grid-cols-1">
          <CustomDropdown
            name={'userType'}
            control={control2}
            label="User Type"
            options={userOptions}
            defaultValue={'individual'}
            disabled
            isRequired
          />
          <CustomDropdown
            name={'userSubType'}
            control={control2}
            label="User Sub Type"
            options={agentSubTypeOptions}
            isRequired
          />
          <CustomTextField
            name={'profileCode'}
            control={control2}
            label={'Profile Code'}
            isRequired
          />
          <CustomTextField name={'name'} control={control2} label={'Name'} />
        </form>
      )}
      <div className="flex justify-center gap-4 my-4">
        <CustomButton
          type="submit"
          variant={'contained'}
          onClick={
            !showProfileForm ? handleSubmit(onSubmit) : handleSubmit2(onSubmit)
          }
        >
          Search
        </CustomButton>
        <CustomButton variant={'outlined'} onClick={() => setStep(1)}>
          Back
        </CustomButton>
      </div>
    </CustomPaper>
  )
}
