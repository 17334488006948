import { Checkbox } from '@mui/material'
import { CustomTable, CustomButton, CustomModal } from 'components'
import { useEffect, useState } from 'react'
import { useMappedUserToUserMutation } from 'api/usersAPI'

function AgentMappingTable({ tableData, assignTo, agentFormData }) {
  const [checkbox, setCheckbox] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  const toggleSelectAll = () => {
    if (checkbox.length === tableData.length) {
      setCheckbox([])
    } else {
      setCheckbox(tableData.map((item) => item.agencyCode || item.posCode))
    }
  }

  const tableHeaders = [
    {
      label: <Checkbox onClick={() => toggleSelectAll()} />,
      field: 'select-all',
    },
    { label: 'sr. no.', field: '' },
    { label: 'Name', field: 'name' },
    { label: 'User SubType', field: 'userSubType' },
    { label: 'Agency/Pos Code', field: 'profileCode' },
    { label: 'Region', field: 'region' },
    { label: 'State', field: 'state' },
    { label: 'District', field: 'district' },
    { label: 'Pincode', field: 'pincode' },
    { label: 'City', field: 'city' },
  ]

  const handleCheckbox = (event, id) => {
    event.stopPropagation()
    const checkIndex = checkbox.indexOf(id)
    let newCheck = []

    if (checkIndex === -1) {
      newCheck = newCheck.concat(checkbox, id)
    } else if (checkIndex === 0) {
      newCheck = newCheck.concat(checkbox.slice(1))
    } else if (checkIndex === checkbox.length - 1) {
      newCheck = newCheck.concat(checkbox.slice(0, -1))
    } else if (checkIndex > 0) {
      newCheck = newCheck.concat(
        checkbox.slice(0, checkIndex),
        checkbox.slice(checkIndex + 1)
      )
    }
    setCheckbox([...newCheck])
  }

  const isChecked = (id) => checkbox.indexOf(id) !== -1

  const handleModalOpen = () => setModalOpen(true)

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const [mappedUserToUser, { isSuccess }] = useMappedUserToUserMutation()

  const handleAssignClick = () => {
    const mapping = {
      userType: agentFormData.userType,
      userSubType: agentFormData.userSubType,
      employeeCode: assignTo,
      agencyCodes: checkbox,
    }
    const mappingForPos = {
      userType: agentFormData.userType,
      userSubType: agentFormData.userSubType,
      employeeCode: assignTo,
      posCodes: checkbox,
    }
    if (agentFormData.userSubType === 'agent') {
      mappedUserToUser(mapping)
    } else if (agentFormData.userSubType === 'pos') {
      mappedUserToUser(mappingForPos)
    }
    setCheckbox([])
  }

  useEffect(() => {
    if (isSuccess) {
      handleModalOpen()
    }
  }, [isSuccess])

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'select-all':
        return (
          <>
            {row.agencyCode && (
              <Checkbox
                checked={isChecked(row.agencyCode || row.posCode)}
                onClick={(event) =>
                  handleCheckbox(event, row.agencyCode, row.posCode)
                }
              />
            )}
            {row.posCode && (
              <Checkbox
                checked={isChecked(row.posCode)}
                onClick={(event) => handleCheckbox(event, row.posCode)}
              />
            )}
          </>
        )
      case 'name':
        return `${row.firstName} ${row.lastName}`
      case 'userSubType':
        return row.userSubType
      case 'profileCode':
        return row.agencyCode || row.posCode
      case 'region':
        return row.region
      case 'state':
        return row.state
      case 'district':
        return row.district
      case 'pincode':
        return row.pincode
      case 'city':
        return row.city
      default:
        return ''
    }
  }

  return (
    <>
      <div>
        <CustomTable
          tableLabel="Agent/POS Selection"
          headers={tableHeaders}
          rows={tableData}
          render={renderCell}
        />
        <div className="flex justify-center gap-4 my-4">
          <CustomButton
            type="submit"
            disabled={checkbox.length !== 0 ? false : true}
            variant={'contained'}
            onClick={handleAssignClick}
          >
            Assign
          </CustomButton>
        </div>
      </div>
      {isSuccess && (
        <CustomModal
          message={'User Assigned Successfully'}
          modalOpen={modalOpen}
          handleModalOpen={handleModalOpen}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  )
}

export default AgentMappingTable
