import * as yup from 'yup'

const employeeSchema = yup.object({
  userType: yup
    .string()
    .required('Please select User Type from the list above'),
  userSubType: yup
    .string()
    .required('Please select User Sub-Type from the list above'),
  pincode: yup
    .string()
    .matches(/^$|^\d{6}$/, 'Pincode must be exactly 6 digits'),
  newCity: yup
    .string()
    .when('city', { is: 'others', then: (schema) => schema.min(3) })
    .matches(/^[aA-zZ\s]+$/, 'Please Provide New City Name'),
})

export default employeeSchema
