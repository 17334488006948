import { useLayoutEffect, useRef, useState } from 'react'
import {
  CustomButton,
  CustomDataRangeDownloader,
  CustomLoading,
  CustomTable,
  Toast,
} from 'components'
import { useMyLeadsQuery } from 'api/leadAPI'
import { useLocation, useNavigate } from 'react-router-dom'
import { RedirectToSmartcovr, filterFormData } from 'utils/CustomUtils'
import { LeadDetails, GetMyLeadsForm } from 'features/viewMyLeads'
import { fieldsToDisplay } from 'features/viewMyLeads/LeadDetails'
import { useSelector } from 'react-redux'

const tableHeaders = [
  { label: 'sr. no.', field: '' },
  { label: 'Name', field: 'leadName' },
  { label: 'Lead Type', field: 'leadType' },
  { label: 'Lead Subtype', field: 'leadSubType' },
  { label: 'Country', field: 'country' },
  { label: 'View', field: 'view' },
  { label: 'Status', field: 'status' },
]

function MyLeads() {
  const [formData, setFormData] = useState({})
  const [lead, setLead] = useState(true)
  const [showDetails, setShowDetails] = useState(false)
  const [leadObj, setLeadObj] = useState({})
  const tableRef = useRef(null)
  const location = useLocation()
  const iamId = location.state?.iamId
  const iamIdUser = useSelector((state) => state.auth.iamId)
  const navigate = useNavigate()

  const formDataFiltered = filterFormData(formData)
  const filteredTableHeaders = location.state?.mappedLeads
    ? tableHeaders.filter((header) => header.field !== 'status')
    : tableHeaders

  const {
    data: leads = [],
    isLoading,
    isFetching,
    isError,
  } = useMyLeadsQuery(
    {
      ...formDataFiltered,
      iamId: iamId || iamIdUser,
      assigned: true,
    },
    { skip: lead }
  )

  useLayoutEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [leads])

  const handleClick = (lead) => {
    setLeadObj(lead)
    setShowDetails(!showDetails)
  }

  const handleBackClick = () => {
    setShowDetails(!showDetails)
    setLead(true)
  }

  const handleReset = () => {
    setFormData({})
    setLead(true)
  }
  const OnSubmit = (formData) => {
    setFormData({ ...formData })
    setLead(false)
  }

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'leadName':
        return `${row.leadName}`
      case 'leadType':
        return row.leadType
      case 'leadSubType':
        return row.leadSubType
      case 'country':
        return row.country
      case 'view':
        return (
          <CustomButton variant={'outlined'} onClick={() => handleClick(row)}>
            View
          </CustomButton>
        )
      case 'status':
        return (
          <CustomButton
            variant={'contained'}
            onClick={() => RedirectToSmartcovr(row)}
          >
            {row.dropOff === 'onboard' || '' ? 'Start' : 'Continue'}
          </CustomButton>
        )
      default:
        return ''
    }
  }

  return (
    <>
      {isError && (
        <Toast
          message={'Error fetching Leads Please Try Again'}
          severity={'error'}
        />
      )}
      {!showDetails && (
        <div>
          <GetMyLeadsForm
            onSubmit={OnSubmit}
            formData={formData}
            onReset={handleReset}
          />
          {(isLoading || isFetching) && <CustomLoading />}
          {!!leads.length && (
            <>
              <div ref={tableRef}>
                <CustomDataRangeDownloader
                  className="w-56 ml-44"
                  tableHeaders={fieldsToDisplay}
                  tableData={leads}
                  fileName={'MY_LEADS'}
                />
                <CustomTable
                  tableLabel="Lead search result"
                  headers={filteredTableHeaders}
                  rows={leads}
                  render={renderCell}
                />
              </div>
              <div className="flex justify-center mb-4">
                <CustomButton variant="outlined" onClick={() => navigate('/')}>
                  Back
                </CustomButton>
              </div>
            </>
          )}
        </div>
      )}
      {showDetails && (
        <LeadDetails user={leadObj} handleClick={handleBackClick} />
      )}
    </>
  )
}

export default MyLeads
