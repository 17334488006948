import { Drawer, Divider, IconButton, Box } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useNavigate, useLocation } from 'react-router-dom'
import { navItems } from './navItems'
import NavItem from './NavItem'

function DrawerMenu({ open, toggleDrawer, privateRoutes }) {
  const navigate = useNavigate()
  const location = useLocation()

  function mapNavItems(privateRoutes, navItems) {
    const navItemstoShow = navItems.filter((item) =>
      privateRoutes.find((routeObj) => routeObj.path.includes(item.path))
    )

    return navItemstoShow
  }
  let privateNavItems = []
  privateNavItems = mapNavItems(privateRoutes, navItems)
  const handleListItemButtonClick = (navItem) => {
    navigate(navItem.path)
    toggleDrawer()
  }

  const handleClose = () => {
    toggleDrawer()
  }

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={handleClose}
      className="w-64 md:w-80"
      classes={{
        paper: 'bg-white',
      }}
    >
      <Box className="min-h-screen p-3 px-5 sm:w-64 md:w-80 lg:w-96">
        <div className="flex items-center justify-between mb-4">
          <IconButton onClick={handleClose}>
            <CloseIcon className="w-6 h-6" />
          </IconButton>
        </div>

        <Divider className="mb-2" />

        <Box className="mb-2">
          {privateNavItems.map((navItem) => (
            <NavItem
              key={navItem.label}
              navItem={navItem}
              location={location}
              handleListItemButtonClick={handleListItemButtonClick}
            />
          ))}
        </Box>
      </Box>
    </Drawer>
  )
}

export default DrawerMenu
