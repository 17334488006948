import { useLayoutEffect, useRef, useState } from 'react'
import {
  CustomButton,
  CustomLoading,
  CustomTable,
  Toast,
  CustomDataRangeDownloader,
} from 'components'
import { useGetLeadsQuery } from 'api/leadAPI'
import { filterFormData } from 'utils/CustomUtils'
import { LeadDetails, AllLeadsForm } from 'features/viewAllLeads'
import { useNavigate } from 'react-router-dom'
import { fieldsToDisplay } from 'features/viewAllLeads/LeadDetails'

const tableHeaders = [
  { label: 'sr. no.', field: '' },
  { label: 'Name', field: 'name' },
  { label: 'Lead Type', field: 'leadType' },
  { label: 'Lead Subtype', field: 'leadSubType' },
  { label: 'Country', field: 'country' },
  { label: 'View', field: 'view' },
]

function AllLeads() {
  const [showLeadsDetails, setShowLeadsDetails] = useState(false)
  const [leadsFormData, setLeadsFormData] = useState({})
  const [lead, setLead] = useState(true)
  const [leadObj, setLeadObj] = useState({})
  const tableRef = useRef(null)
  const navigate = useNavigate()

  const formDataFiltered = filterFormData(leadsFormData)

  const {
    data: leads = [],
    isLoading,
    isFetching,
    isError,
  } = useGetLeadsQuery(
    {
      ...formDataFiltered,
    },
    { skip: lead }
  )

  useLayoutEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [leads])

  const handleClick = (lead) => {
    setLeadObj(lead)
    setShowLeadsDetails(!showLeadsDetails)
  }

  const handleBackClick = () => {
    setShowLeadsDetails(!showLeadsDetails)
  }

  const handleReset = () => {
    setLeadsFormData({})
    setLead(true)
  }
  const OnSubmit = (formData) => {
    setLeadsFormData({ ...formData })
    setLead(false)
  }

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'name':
        return `${row.leadName}`
      case 'leadType':
        return row.leadType
      case 'leadSubType':
        return row.leadSubType
      case 'country':
        return row.country
      case 'view':
        return (
          <CustomButton variant={'outlined'} onClick={() => handleClick(row)}>
            View
          </CustomButton>
        )
      default:
        return ''
    }
  }

  return (
    <>
      {!showLeadsDetails && (
        <div>
          <AllLeadsForm
            onSubmit={OnSubmit}
            formData={leadsFormData}
            onReset={handleReset}
          />
          {isError && (
            <Toast
              message={'Error Fetching Leads Try Again in Sometime'}
              severity={'error'}
            />
          )}
          {(isLoading || isFetching) && <CustomLoading />}
          {!!leads.length && (
            <>
              <div className="mt-8" ref={tableRef}>
                <CustomDataRangeDownloader
                  className="w-56 ml-44"
                  tableHeaders={fieldsToDisplay}
                  tableData={leads}
                  fileName={'ALL_LEADS'}
                />
                <CustomTable
                  tableLabel="Lead search result"
                  headers={tableHeaders}
                  rows={leads}
                  render={renderCell}
                />
              </div>
              <div className="flex justify-center mb-4">
                <CustomButton variant="outlined" onClick={() => navigate('/')}>
                  Back
                </CustomButton>
              </div>
            </>
          )}
        </div>
      )}
      {showLeadsDetails && (
        <LeadDetails user={leadObj} handleClick={handleBackClick} />
      )}
    </>
  )
}
export default AllLeads
