import { useLayoutEffect, useRef, useState } from 'react'
import {
  CustomButton,
  CustomDataRangeDownloader,
  CustomLoading,
  CustomTable,
  Toast,
} from 'components'
import { useMyClientsQuery } from 'api/clientAPI'
import { filterFormData } from 'utils/CustomUtils'
import { useLocation } from 'react-router-dom'
import { ClientDetails, MyClientsForm } from 'features/myClients'
import { fieldsToDisplay } from 'features/myClients/ClientDetails'
import { useSelector } from 'react-redux'

const tableHeaders = [
  { label: 'sr. no.', field: '' },
  { label: 'Name', field: 'clientName' },
  { label: 'Country', field: 'country' },
  { label: 'State', field: 'state' },
  { label: 'District', field: 'district' },
  { label: 'City/Area', field: 'city' },
  { label: 'Pincode', field: 'pincode' },
  { label: 'View', field: 'view' },
]

function MyClients() {
  const [formData, setFormData] = useState({})
  const [client, setClient] = useState(true)
  const [showDetails, setShowDetails] = useState(false)
  const [clientObj, setClientObj] = useState({})
  const tableRef = useRef(null)

  const location = useLocation()
  const iamId = location.state
  const iamIdUser = useSelector((state) => state.auth.iamId)

  const formDataFiltered = filterFormData(formData)

  const {
    data: clients = [],
    isLoading,
    isFetching,
    isError,
  } = useMyClientsQuery(
    {
      ...formDataFiltered,
      iamId: iamId || iamIdUser,
    },
    { skip: client }
  )

  useLayoutEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [clients])

  const handleClick = (client) => {
    setClientObj(client)
    setShowDetails(!showDetails)
  }

  const handleBackClick = () => {
    setShowDetails(!showDetails)
    setClient(true)
  }

  const OnSubmit = (formData) => {
    setFormData({ ...formData })
    setClient(false)
  }

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'clientName':
        return row.leadName
      case 'country':
        return row.country
      case 'state':
        return row.state
      case 'district':
        return row.district
      case 'city':
        return row.city
      case 'pincode':
        return row.pincode
      case 'view':
        return (
          <CustomButton variant={'outlined'} onClick={() => handleClick(row)}>
            View
          </CustomButton>
        )
      default:
        return ''
    }
  }

  return (
    <>
      {isError && (
        <Toast
          message={'Error fetching Clients Please Try Again'}
          severity={'error'}
        />
      )}
      {!showDetails && (
        <div>
          <MyClientsForm onSubmit={OnSubmit} formData={formData} />
          {(isLoading || isFetching) && <CustomLoading />}
          {!!clients.length && (
            <>
              <div ref={tableRef}>
                <CustomDataRangeDownloader
                  className="w-56 ml-44"
                  tableHeaders={fieldsToDisplay}
                  tableData={clients}
                  fileName={'MY_CLIENTS'}
                />
                <CustomTable
                  tableLabel="Client search result"
                  headers={tableHeaders}
                  rows={clients}
                  render={renderCell}
                />
              </div>
            </>
          )}
        </div>
      )}
      {showDetails && (
        <ClientDetails user={clientObj} handleClick={handleBackClick} />
      )}
    </>
  )
}

export default MyClients
