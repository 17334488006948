import { useGetClientQuery } from 'api/clientAPI'
import {
  CustomButton,
  CustomDataRangeDownloader,
  CustomLoading,
  CustomTable,
  Toast,
} from 'components'
import { useState } from 'react'
import { filterFormData } from 'utils/CustomUtils'
import { ClientDetails, ViewAllClientsForm } from 'features/viewAllClients'
import { fieldsToDisplay } from 'features/viewAllClients/ClientDetails'

export default function ViewAllClients() {
  const [formData, setFormData] = useState({})
  const [user, setUser] = useState(true)
  const [showDetails, setShowDetails] = useState(false)
  const [userObj, setUserObj] = useState({})

  const onSubmit = (formData) => {
    setFormData({ ...formData })
    setUser(false)
  }

  const handleClick = (user) => {
    setUserObj(user)
    setShowDetails(!showDetails)
  }

  const handleBackClick = () => {
    setShowDetails(!showDetails)
    setUser(true)
  }

  const formDataFiltered = filterFormData(formData)

  const {
    data: clients = [],
    isLoading,
    isFetching,
    isError,
  } = useGetClientQuery(
    {
      ...formDataFiltered,
    },
    {
      skip: user,
    }
  )

  const tableHeaders = [
    { label: 'sr. no.', field: '' },
    { label: 'Name', field: 'leadName' },
    { label: 'Insurance Type', field: 'leadType' },
    { label: 'Insurance Sub Type', field: 'leadSubType' },
    { label: 'Country', field: 'country' },
    { label: 'Region', field: 'region' },
    { label: 'View', field: 'view' },
  ]

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'leadName':
        return row.leadName
      case 'leadType':
        return row.leadType
      case 'leadSubType':
        return row.leadSubType
      case 'country':
        return row.country
      case 'region':
        return row.region
      case 'view':
        return (
          <CustomButton variant={'outlined'} onClick={() => handleClick(row)}>
            View
          </CustomButton>
        )
      default:
        return ''
    }
  }

  if (isError) {
    return <div>Error fetching agents.</div>
  }
  return (
    <>
      {isError && (
        <Toast
          message={'Error fetching Clients Please Try Again'}
          severity={'error'}
        />
      )}
      {!showDetails && (
        <div>
          <ViewAllClientsForm onSubmit={onSubmit} formData={formData} />
          {(isLoading || isFetching) && <CustomLoading />}
          {!!clients.length && (
            <div className="mt-8">
              <CustomDataRangeDownloader
                className="w-56 ml-44"
                tableHeaders={fieldsToDisplay}
                tableData={clients}
                fileName={'ALL_CLIENTS'}
              />
              <CustomTable
                tableLabel="Client search result"
                rows={clients}
                headers={tableHeaders}
                render={renderCell}
              />
            </div>
          )}
        </div>
      )}
      {showDetails && (
        <ClientDetails user={userObj} handleClick={handleBackClick} />
      )}
    </>
  )
}
