import React, { useEffect } from 'react'
import { CustomModal, Toast } from 'components'
import RegistrationFormAgent from './RegistrationFormAgent'
import RegistrationFormEmployee from './RegistrationFormEmployee'
import RegistrationFormPos from './RegistrationFormPos'
import { useRegisterUsersMutation } from 'api/usersAPI'

function RegistrationForm({
  userSubType,
  formStep,
  handleBackClick,
  setFormStep,
  setIsLoading,
}) {
  const [postUsers, { isSuccess, isLoading, isError }] =
    useRegisterUsersMutation()
  const [modalOpen, setModalOpen] = React.useState(false)

  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => {
    setModalOpen(false)
    setFormStep((prev) => !prev)
  }

  const onSubmit = (formData) => {
    const istDateofBirth = formData.dateOfBirth.toString()
    const dateComponents = istDateofBirth.split(' ')

    const day = dateComponents[2]
    const month = (
      'JanFebMarAprMayJunJulAugSepOctNovDec'.indexOf(dateComponents[1]) / 3 +
      1
    )
      .toString()
      .padStart(2, '0')
    const year = dateComponents[3]
    const time = dateComponents[4]
    const isoDateOfBirth = `${year}-${month}-${day}T${time}.000Z`

    const user = {
      userType: formData.userType,
      designation: formData.designation,
      userSubType: formData.userSubType,
      agencyCode: formData.agencyCode,
      posCode: formData.posCode,
      employeeCode: formData.employeeCode,
      title: formData.title,
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,
      dateOfBirth: isoDateOfBirth,
      email: formData.email,
      officeEmail: formData.officeEmail,
      phoneNo: formData.phoneNo,
      officeMobile: formData.officeMobile,
      address: {
        permanentAddress: formData.address,
        city: formData.city,
        country: formData.country,
        state: formData.state,
        pincode: formData.pincode,
        district: formData.district,
      },
      country: formData.country,
      region: formData.region,
      state: formData.state,
      district: formData.district,
      pincode: formData.pincode,
      city: formData.city,
      branchCode: formData.branchCode,
      nismRegistrationNumber: formData.nismRegistrationNumber,
      nismRegistrationNumberGenerationDate:
        formData.nismRegistrationNumberGenerationDate,
      posRegistrationNumber: formData.posRegistrationNumber,
      posRegistrationNumberGenerationDate:
        formData.posRegistrationNumberGenerationDate,
      photograph: formData.photograph,
      nismCertificate: formData.nismCertificate,
      posCertificate: formData.posCertificate,
      educationCertificate: formData.educationCertificate,
      qualification: formData.qualification,
      generalInsurance: formData.generalInsurance,
      healthInsurance: formData.healthInsurance,
      lifeInsurance: formData.lifeInsurance,
    }
    postUsers(user)
  }

  const onBulkSubmit = () => {
    // postCreateBulkLeads(formData)
  }

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading, setIsLoading])

  useEffect(() => {
    if (isSuccess) {
      handleModalOpen()
    }
  }, [isSuccess])

  return (
    <>
      {isError && (
        <Toast
          message={'Error in Registration Please Try Again'}
          severity={'error'}
        />
      )}
      {!formStep && userSubType === 'employee' && (
        <RegistrationFormEmployee
          onSubmit={onSubmit}
          handleBackClick={handleBackClick}
          onBulkSubmit={onBulkSubmit}
        />
      )}
      {!formStep && userSubType === 'pos' && (
        <RegistrationFormPos
          onSubmit={onSubmit}
          handleBackClick={handleBackClick}
          onBulkSubmit={onBulkSubmit}
        />
      )}
      {!formStep && userSubType === 'agent' && (
        <RegistrationFormAgent
          onSubmit={onSubmit}
          handleBackClick={handleBackClick}
          onBulkSubmit={onBulkSubmit}
        />
      )}
      {isSuccess && (
        <CustomModal
          message={'Saved Successfully and Sent for Approval'}
          modalOpen={modalOpen}
          handleModalOpen={handleModalOpen}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  )
}

export default RegistrationForm
