const env = process.env.NODE_ENV
const appEnv = process.env.REACT_APP_BUILD_TYPE
const envMode = env === 'development' ? env : appEnv

const apiKey = 'e6958d49-46f7-4983-8ba2-d3778bc8e771'
const iamId = '924c8f7f-a47f-4949-b54b-60f6939cb19c'
const keycloakApiKey = '81053a30-2725-483c-9a78-a3f67bc55371'

const metadataUrl =
  envMode === 'development'
    ? 'http://localhost:3032/metadata'
    : 'https://apigateway.smartcovr.io/apiman-gateway/smartcovr/metadata/1.0'

const baseUrl =
  envMode === 'development'
    ? 'http://localhost:3032/agent'
    : 'https://apigateway.smartcovr.io/apiman-gateway/smartcovr/smartcovr-agent/1.0'

const tptLoginUrl =
  envMode === 'development'
    ? 'http://localhost:3032/tptlogin?apikey=dummy'
    : `https://apigateway.smartcovr.io/apiman-gateway/smartcovr/tptlogin/1.0?apikey=${keycloakApiKey}`

const tptLoginParams = {
  client: 'agent',
  redirectTo: `${window.location.origin}/#/tpt-handler`,
  distributorId: 'IN-9820',
  configType: 'agentAuth',
}

const refreshTokenUrl =
  envMode === 'development'
    ? `http://localhost:3032/regenerateAccessToken?apikey=dummy&distributorId=IN-9820&client=agent&configType=agentAuth`
    : `https://apigateway.smartcovr.io/apiman-gateway/smartcovr/regenerateAccessToken/1.0?apikey=81053a30-2725-483c-9a78-a3f67bc55371&distributorId=IN-9820&client=agent&configType=agentAuth`

const tptTokenUrl =
  envMode === 'development'
    ? `http://localhost:3032/tpttoken?client=${tptLoginParams.client}&distributorId=${tptLoginParams.distributorId}&configType=${tptLoginParams.configType}&apikey=${keycloakApiKey}`
    : `https://apigateway.smartcovr.io/apiman-gateway/smartcovr/tpttoken/1.0?client=${tptLoginParams.client}&distributorId=${tptLoginParams.distributorId}&configType=${tptLoginParams.configType}&apikey=${keycloakApiKey}`

const logoutUrl =
  envMode === 'development'
    ? `http://localhost:3032/keycloak/logout?configType=${tptLoginParams.configType}&apikey=dummy`
    : `https://apigateway.smartcovr.io/apiman-gateway/smartcovr/keycloak-logout/1.0?configType=${tptLoginParams.configType}&apikey=${keycloakApiKey}`

export const config = {
  baseUrl,
  tptLoginUrl,
  tptLoginParams,
  metadataUrl,
  refreshTokenUrl,
  tptTokenUrl,
  logoutUrl,
  apiHeaders: {
    'Content-Type': 'application/json',
    client: process.env.AGENT_APP_API_CLIENT || 'agent',
    distributorId: process.env.AGENT_APP_API_DISTRIBUTOR_ID || 'IN-9820',
  },
  apiKey,
  iamId,
  envMode,
}
