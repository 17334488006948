import { useState } from 'react'
import {
  ReassignClientsForm,
  ClientTable,
  ClientDetails,
  ClientsReallocationForm,
  ClientReallocationTable,
} from 'features/reassignClients'
import { CustomLoading } from 'components'
export default function ReassignClients() {
  const [step, setStep] = useState(1)
  const [userObj, setUserObj] = useState({})
  const [tableData, setTableData] = useState([])
  const [clientData, setClientData] = useState([])
  const [showClientTable, setShowClientTable] = useState(false)
  const [showClientReallocationTable, setShowClientReallocationTable] =
    useState(false)
  const [leadNumbers, setLeadNumbers] = useState()
  const [userTypeValue, setUserTypeValue] = useState()
  const [isLoading, setIsLoading] = useState(false)
  return (
    <>
      {isLoading && <CustomLoading />}
      {step === 1 && (
        <ReassignClientsForm
          setTableData={setTableData}
          setShowClientTable={setShowClientTable}
          setIsLoading={setIsLoading}
        />
      )}
      {step === 1 && showClientTable && (
        <ClientTable
          setLeadNumbers={setLeadNumbers}
          tableData={tableData}
          setStep={setStep}
          setUserObj={setUserObj}
        />
      )}
      {(step === 2 || step === 4) && (
        <ClientDetails
          user={userObj}
          handleClick={() => setStep((prev) => prev - 1)}
          step={step}
        />
      )}
      {step === 3 && (
        <ClientsReallocationForm
          setUserTypeValue={setUserTypeValue}
          setTableData={setClientData}
          setStep={setStep}
          setShowClientReallocationTable={setShowClientReallocationTable}
          setIsLoading={setIsLoading}
        />
      )}
      {step === 3 && showClientReallocationTable && (
        <ClientReallocationTable
          userTypeValue={userTypeValue}
          leadNumbers={leadNumbers}
          tableData={clientData}
          step={step}
          setStep={setStep}
          setUserObj={setUserObj}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  )
}
