import { configureStore } from '@reduxjs/toolkit'
import {
  usersAPI,
  leadAPI,
  clientAPI,
  authenticationAPI,
  metadataAPI,
  logoutAPI,
} from 'api'
import configReducer, { fetchConfig } from 'features/config/configSlice'
import authReducer, { getAuthToken } from 'features/auth/authSlice'
export const store = configureStore({
  reducer: {
    config: configReducer,
    auth: authReducer,
    [usersAPI.reducerPath]: usersAPI.reducer,
    [leadAPI.reducerPath]: leadAPI.reducer,
    [clientAPI.reducerPath]: clientAPI.reducer,
    [authenticationAPI.reducerPath]: authenticationAPI.reducer,
    [metadataAPI.reducerPath]: metadataAPI.reducer,
    [logoutAPI.reducerPath]: logoutAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      usersAPI.middleware,
      leadAPI.middleware,
      clientAPI.middleware,
      authenticationAPI.middleware,
      metadataAPI.middleware,
      logoutAPI.middleware
    ),
})

store.dispatch(fetchConfig()).then(() => {
  if (process.env.NODE_ENV !== 'development') {
    store.dispatch(getAuthToken())
  }
})

export default store
