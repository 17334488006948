import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

export const useCustomForm = (formData, schema) => {
  const { control, handleSubmit, reset, setValue, formState, watch } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })

  useEffect(() => {
    setInitialFormValues()
  }, [formData])

  const setInitialFormValues = () => {
    Object.keys(formData).forEach((key) => {
      setValue(key, formData[key])
    })
  }

  const resetForm = () => {
    reset()
  }

  return {
    control,
    handleSubmit,
    resetForm,
    formState,
    watch,
    setValue,
  }
}
