import { Checkbox } from '@mui/material'
import { useLayoutEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomButton, CustomLoading, CustomTable, Toast } from 'components'
import { useMappedUserQuery } from 'api/usersAPI'
import { filterFormData } from 'utils/CustomUtils'
import {
  ClientDetails,
  ViewMappedClientsForm,
} from 'features/viewMappedClients'
import { useSelector } from 'react-redux'

const tableHeaders = [
  { label: '', field: 'check' },
  { label: 'sr. no.', field: '' },
  { label: 'Employee Code', field: 'employeeCode' },
  { label: 'First Name', field: 'firstName' },
  { label: 'Last Name', field: 'lastName' },
  { label: 'Pincode', field: 'pincode' },
  { label: 'Phone Number', field: 'phoneNo' },
  { label: 'View', field: 'view' },
]

function ViewMappedClients() {
  const [checkbox, setCheckbox] = useState(false)
  const [formData, setFormData] = useState({})
  const [mClient, setMClient] = useState(true)
  const [showMClientDetails, setShowMClientDetails] = useState(false)
  const [mClientObj, setMClientObj] = useState({})
  const navigate = useNavigate()
  const tableRef = useRef(null)
  const iamId = useSelector((state) => state.auth.iamId)

  const OnSubmit = (formData) => {
    setFormData({ ...formData })
    setMClient(false)
  }

  const formDataFiltered = filterFormData(formData)

  const {
    data: users = [],
    isLoading,
    isFetching,
    isError,
  } = useMappedUserQuery(
    {
      ...formDataFiltered,
      iamId,
    },
    { skip: mClient }
  )

  useLayoutEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [users])

  const handleClick = (user) => {
    setMClientObj(user)
    setShowMClientDetails(!showMClientDetails)
  }

  const handleBackClick = () => {
    setShowMClientDetails(!showMClientDetails)
    setMClient(true)
  }

  const handleSaveClick = () => {
    setMClient(true)
    navigate('/my-clients', { state: checkbox })
  }

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'employeeCode':
        return row.employeeCode || row.posCode || row.agencyCode
      case 'firstName':
        return row.firstName
      case 'lastName':
        return row.lastName
      case 'country':
        return row.country
      case 'pincode':
        return row.pincode
      case 'phoneNo':
        return `+${row.phoneNo || row.officeMobile}`
      case 'check':
        return (
          <Checkbox
            checked={checkbox === row.iamId}
            onChange={() => setCheckbox(row.iamId)}
          />
        )
      case 'view':
        return (
          <CustomButton variant={'outlined'} onClick={() => handleClick(row)}>
            View
          </CustomButton>
        )
      default:
        return ''
    }
  }

  return (
    <>
      {isError && (
        <Toast
          message={'Error fetching Mapped Clients Please Try Again'}
          severity={'error'}
        />
      )}
      {!showMClientDetails && (
        <div>
          <ViewMappedClientsForm onSubmit={OnSubmit} formData={formData} />
          {(isLoading || isFetching) && <CustomLoading />}
          {!!users.length && (
            <div ref={tableRef}>
              <CustomTable
                headers={tableHeaders}
                rows={users}
                render={renderCell}
              />
              <div className="flex justify-center gap-4 my-4">
                <CustomButton
                  type="submit"
                  disabled={!checkbox}
                  variant={'contained'}
                  onClick={handleSaveClick}
                >
                  Save
                </CustomButton>
              </div>
            </div>
          )}
        </div>
      )}
      {showMClientDetails && (
        <ClientDetails user={mClientObj} handleClick={handleBackClick} />
      )}
    </>
  )
}

export default ViewMappedClients
