import {
  CustomDatePicker,
  CustomDropdown,
  CustomButton,
  CustomPaper,
  PincodeInput,
  LocationDropdown,
  CustomTextField,
} from 'components'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  Branches,
  GeneralSubTypeOption,
  HealthSubTypeOptions,
  LifeSubTypeOptions,
  leadOptions,
  userOptions,
  userSubTypeOptions,
} from 'constant/formOptions'
import { useCustomForm } from 'hooks/useCustomForm'
import { viewLeadSchema } from './schema'

function AllLeadsForm({ onSubmit, formData }) {
  const navigate = useNavigate()
  let leadSubTypeOption = []
  const { control, handleSubmit, watch } = useCustomForm(
    formData,
    viewLeadSchema
  )

  if (watch('leadType') === 'Life insurance') {
    leadSubTypeOption = LifeSubTypeOptions
  } else if (watch('leadType') === 'Health insurance') {
    leadSubTypeOption = HealthSubTypeOptions
  } else if (watch('leadType') === 'General insurance') {
    leadSubTypeOption = GeneralSubTypeOption
  }

  return (
    <CustomPaper>
      <Typography
        component={'h1'}
        sx={{
          fontWeight: 700,
          fontSize: { xs: 15, sm: 15, md: 20, lg: 24 },
        }}
        className="p-4 pl-8 text-center capitalize"
      >
        View All Leads
      </Typography>
      <div className="grid gap-6 lg:grid-cols-2 md:grid-cols-1 ">
        <CustomDatePicker
          label="From Date"
          name={'dateFrom'}
          control={control}
        />
        <CustomDatePicker label="To Date" name={'dateTo'} control={control} />
        <CustomDropdown
          control={control}
          select
          name={'leadType'}
          label="Lead Type"
          options={leadOptions}
          isRequired={true}
        />
        <CustomDropdown
          control={control}
          select
          name={'leadSubType'}
          label="Lead Sub Type"
          options={leadSubTypeOption}
          isRequired={true}
        />
        <CustomDropdown
          name={'userType'}
          control={control}
          label="User Type"
          options={userOptions}
          defaultValue={'individual'}
          disabled
          isRequired={true}
        />
        <CustomDropdown
          name={'userTypeOptions'}
          control={control}
          label="User Sub Type"
          options={userSubTypeOptions}
          isRequired={true}
        />
        <LocationDropdown control={control} watch={watch} />
        <PincodeInput control={control} name={'pincode'} label={'Pin Code'} />
        {watch('city') === 'others' && (
          <CustomTextField
            control={control}
            name={'newCity'}
            label="Add New City/Area"
            isRequired
          />
        )}
        <CustomDropdown
          name={'branch'}
          control={control}
          select
          label="Branch"
          options={Branches}
        />
      </div>
      <div className="flex justify-center gap-4 my-4">
        <CustomButton
          type="submit"
          variant={'contained'}
          onClick={handleSubmit(onSubmit)}
        >
          Search
        </CustomButton>
        <CustomButton variant={'outlined'} onClick={() => navigate('/')}>
          Back
        </CustomButton>
      </div>
    </CustomPaper>
  )
}

export default AllLeadsForm
