import { useLayoutEffect, useRef, useState } from 'react'
import { CustomButton, CustomLoading, CustomTable, Toast } from 'components'
import { useGetLeadsQuery } from 'api/leadAPI'
import { filterFormData } from 'utils/CustomUtils'
import { LeadDetails, ViewLeadsForm } from 'features/viewAssignedLeads'

const tableHeaders = [
  { label: 'sr. no.', field: '' },
  { label: 'Name', field: 'name' },
  { label: 'Lead Type', field: 'leadType' },
  { label: 'Lead Subtype', field: 'leadSubType' },
  { label: 'Country', field: 'country' },
  { label: 'Assigned To', field: 'assignedTo' },
  { label: 'View', field: 'view' },
]

function ViewAssignedLeads() {
  const [formData, setFormData] = useState({})
  const [lead, setLead] = useState(true)
  const [showDetails, setShowDetails] = useState(false)
  const [leadObj, setLeadObj] = useState({})
  const tableRef = useRef(null)

  const formDataFiltered = filterFormData(formData)

  const {
    data: leads = [],
    isLoading,
    isError,
    isFetching,
  } = useGetLeadsQuery(
    {
      ...formDataFiltered,
      assigned: true,
      isClient: false,
    },
    { skip: lead }
  )

  useLayoutEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [leads])

  const handleClick = (lead) => {
    setLeadObj(lead)
    setShowDetails(!showDetails)
  }

  const handleBackClick = () => {
    setShowDetails(!showDetails)
    setLead(true)
  }

  const onSubmit = (formData) => {
    setFormData({ ...formData })
    setLead(false)
  }

  const handleAssignTo = (row) => {
    if (row?.Employees?.[0]?.completeName) {
      return row.Employees[0].completeName
    }
    if (row?.Agents?.[0]?.completeName) {
      return row.Agents[0].completeName
    }
    if (row?.POs?.[0]?.completeName) {
      return row.POs[0].completeName
    }
    return 'NA'
  }

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'name':
        return `${row.leadName}`
      case 'leadType':
        return row.leadType
      case 'leadSubType':
        return row.leadSubType
      case 'country':
        return row.country
      case 'assignedTo':
        return handleAssignTo(row)
      case 'view':
        return (
          <CustomButton variant={'outlined'} onClick={() => handleClick(row)}>
            View
          </CustomButton>
        )
      default:
        return ''
    }
  }

  return (
    <>
      {isError && (
        <Toast
          message={'Error fetching Assigned Leads Please Try Again'}
          severity={'error'}
        />
      )}
      {!showDetails && (
        <div>
          <ViewLeadsForm onSubmit={onSubmit} formData={formData} />
          {(isLoading || isFetching) && <CustomLoading />}
          {!!leads.length && (
            <div className="mt-8" ref={tableRef}>
              <CustomTable
                headers={tableHeaders}
                rows={leads}
                render={renderCell}
              />
            </div>
          )}
        </div>
      )}
      {showDetails && (
        <LeadDetails user={leadObj} handleClick={handleBackClick} />
      )}
    </>
  )
}

export default ViewAssignedLeads
