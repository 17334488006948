import { useState } from 'react'
import { FormControlLabel, Switch, Typography } from '@mui/material'
import {
  CustomDatePicker,
  CustomButton,
  CustomDropdown,
  CustomTextField,
  CustomPaper,
  PincodeInput,
  LocationDropdown,
} from 'components'
import {
  userOptions,
  userSubTypeOptions,
  Branches,
  employeeDesignationOptions,
} from 'constant/formOptions'
import { employeeCodeSchema, employeeSchema } from 'schema'
import { useCustomForm } from 'hooks'
import { useNavigate } from 'react-router-dom'

export default function EmployeeForm({ onSubmit, formData, formHeader }) {
  const [byEmployeeCode, setByEmployeeCode] = useState(false)
  const navigate = useNavigate()

  const handleSwitchChange = () => {
    setByEmployeeCode(!byEmployeeCode)
    resetForm()
    resetForm2()
  }

  const { control, resetForm, handleSubmit, watch } = useCustomForm(
    formData,
    employeeSchema
  )

  const {
    control: control2,
    resetForm: resetForm2,
    handleSubmit: handleSubmit2,
  } = useCustomForm(formData, employeeCodeSchema)

  return (
    <CustomPaper>
      <Typography
        component={'h1'}
        sx={{
          fontWeight: 700,
          fontSize: { xs: 15, sm: 15, md: 20, lg: 24 },
        }}
        className="p-4 pl-8 text-center capitalize"
      >
        {formHeader}
      </Typography>
      <FormControlLabel
        control={
          <Switch checked={byEmployeeCode} onChange={handleSwitchChange} />
        }
        label="Search By Employee Code or Employee Name"
      />
      {!byEmployeeCode && (
        <div className="grid gap-6 lg:grid-cols-2 md:grid-cols-1 ">
          <CustomDatePicker
            label="From Date"
            name={'dateFrom'}
            control={control}
          />
          <CustomDatePicker label="To Date" name={'dateTo'} control={control} />
          <CustomDropdown
            name={'userType'}
            control={control}
            label="User Type"
            defaultValue={'individual'}
            disabled
            isRequired
            options={userOptions}
          />
          <CustomDropdown
            name={'userSubType'}
            control={control}
            label="User Sub Type"
            options={userSubTypeOptions}
            defaultValue={'employee'}
            disabled
            isRequired
          />
          <CustomDropdown
            name={'designation'}
            control={control}
            label={'Employee Designation'}
            options={employeeDesignationOptions}
          />
          <LocationDropdown control={control} watch={watch} />
          <PincodeInput control={control} name={'pincode'} label={'Pin Code'} />
          {watch('city') === 'others' && (
            <CustomTextField
              control={control}
              name={'newCity'}
              label="Add New City/Area"
              isRequired
            />
          )}
          <CustomDropdown
            name={'branch'}
            control={control}
            label="Branch"
            options={Branches}
          />
        </div>
      )}
      {byEmployeeCode && (
        <div className="grid gap-6 lg:grid-cols-2 md:grid-cols-1">
          <CustomTextField
            name={'employeeCode'}
            control={control2}
            label={'Employee Code'}
          />
          <CustomTextField
            name={'employeeName'}
            control={control2}
            label={'Employee Name'}
          />
        </div>
      )}
      <div className="flex justify-center gap-4 my-4">
        <CustomButton
          type="submit"
          variant={'contained'}
          onClick={
            !byEmployeeCode ? handleSubmit(onSubmit) : handleSubmit2(onSubmit)
          }
        >
          Search
        </CustomButton>
        <CustomButton variant={'outlined'} onClick={() => navigate('/')}>
          Back
        </CustomButton>
      </div>
    </CustomPaper>
  )
}
