import * as yup from 'yup'

const usersSchema = yup.object({
  userType: yup
    .string()
    .required('Please select User Type from the list above'),
  name: yup.string().when('selectUser', {
    is: (value) => value && value === 'byName',
    then: () =>
      yup
        .string()
        .required('Please Provide Name')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .min(3, 'Must be minimum 3 characters')
        .max(50, 'Must be less Than 50 characters'),
    otherwise: () => yup.string().notRequired(),
  }),
  userSubType: yup
    .string()
    .required('Please select User Sub-Type from the list above'),
  selectUser: yup.string().required('Please select User from the list above'),
  country: yup.string(),
  district: yup.string(),
  city: yup.string(),
  newCity: yup
    .string()
    .when('city', { is: 'others', then: (schema) => schema.min(3) })
    .matches(/^[aA-zZ\s]+$/, 'Please Provide New City Name '),
  pincode: yup
    .string()
    .matches(/^$|^\d{6}$/, 'Pincode must be exactly 6 digits'),
})

export default usersSchema
