import axios from 'axios'
import format from 'string-format'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getCookie } from 'utils/CustomUtils'
import { jwtDecode } from 'jwt-decode'

export const getAuthToken = createAsyncThunk(
  'auth/getAuthToken',
  async (_, { getState, dispatch }) => {
    const config = getState().config.data.addedConfig

    try {
      const authData = `${config.apiman.clientId}:${config.apiman.clientSecret}`
      const url = format(config.apiman.iamUrl, { realm: config.apiman.realm })
      const headers = {
        'content-type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${window.btoa(authData)}`,
      }
      const response = await axios({
        url,
        method: 'POST',
        data: `grant_type=${config.apiman.grantType}`,
        headers,
      })
      const accessToken = response.data.access_token
      const expiresIn = response.data.expires_in

      // Set a timer to refresh the token before it expires
      setTimeout(() => {
        dispatch(getAuthToken())
      }, (expiresIn - 60) * 1000) // Refresh the token 1 minute before it expires

      return accessToken
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
      throw err
    }
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    data: null,
    iamId: '',
    loading: false,
    error: null,
    isLoggedIn: false,
  },
  reducers: {
    setLogin: (state, action) => {
      state.isLoggedIn = action.payload
    },
    setIamId: (state) => {
      const cookie = getCookie('accessToken')
      state.iamId = jwtDecode(cookie).iamId
    },
    initializeUser: (state) => {
      const accessToken = getCookie('accessToken')
      if (accessToken) {
        state.isLoggedIn = true
      } else {
        state.isLoggedIn = false
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuthToken.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getAuthToken.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(getAuthToken.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  },
})

export const { initializeUser, setLogin, setIamId } = authSlice.actions
export default authSlice.reducer
