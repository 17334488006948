import { Checkbox } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomButton, CustomLoading, CustomTable, Toast } from 'components'
import { useMappedUserQuery } from 'api/usersAPI'
import { filterFormData } from 'utils/CustomUtils'
import { LeadDetails, MappedLeadForm } from 'features/viewMappedLeads'
import { useSelector } from 'react-redux'

const tableHeaders = [
  { label: '', field: 'check' },
  { label: 'sr. no.', field: '' },
  { label: 'Profile Code', field: 'profileCode' },
  { label: 'First Name', field: 'firstName' },
  { label: 'Last Name', field: 'lastName' },
  { label: 'Pincode', field: 'pincode' },
  { label: 'Phone Number', field: 'phoneNo' },
  { label: 'View', field: 'view' },
]

function GetMappedLeadsToAgent() {
  const [checkbox, setCheckbox] = useState(false)
  const [formData, setFormData] = useState({})
  const [mLead, setMLead] = useState(true)
  const [showMLeadDetails, setShowMLeadDetails] = useState(false)
  const [mLeadObj, setMLeadObj] = useState({})
  const navigate = useNavigate()
  const iamId = useSelector((state) => state.auth.iamId)

  const OnSubmit = (formData) => {
    setFormData({ ...formData })
    setMLead(false)
  }

  const formDataFiltered = filterFormData(formData)

  const {
    data: users = [],
    isLoading,
    isFetching,
    isError,
  } = useMappedUserQuery(
    {
      ...formDataFiltered,
      iamId,
    },
    { skip: mLead }
  )

  const handleClick = (user) => {
    setMLeadObj(user)
    setShowMLeadDetails(!showMLeadDetails)
  }

  const handleBackClick = () => {
    setShowMLeadDetails(!showMLeadDetails)
    setMLead(true)
  }

  const handleSaveClick = () => {
    setMLead(true)
    navigate('/view-mapped-leads', {
      state: { iamId: checkbox, mappedLeads: true },
    })
  }

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'profileCode':
        return row.agencyCode || row.posCode || row.employeeCode
      case 'firstName':
        return row.firstName
      case 'lastName':
        return row.lastName
      case 'country':
        return row.country
      case 'pincode':
        return row.pincode
      case 'phoneNo':
        return `+${row.phoneNo || row.officeMobile}`
      case 'check':
        return (
          <Checkbox
            checked={checkbox === row.iamId}
            onChange={() => setCheckbox(row.iamId)}
          />
        )
      case 'view':
        return (
          <CustomButton variant={'outlined'} onClick={() => handleClick(row)}>
            View
          </CustomButton>
        )
      default:
        return ''
    }
  }

  return (
    <>
      {isError && (
        <Toast
          message={'Error fetching mapped Lead Please Try Again'}
          severity={'error'}
        />
      )}
      {!showMLeadDetails && (
        <div>
          <MappedLeadForm onSubmit={OnSubmit} formData={formData} />
          {(isLoading || isFetching) && <CustomLoading />}
          {!!users.length && (
            <>
              <CustomTable
                tableLabel="Employee Selection"
                headers={tableHeaders}
                rows={users}
                render={renderCell}
              />
              <div className="flex items-center justify-center mb-4">
                <CustomButton
                  type="submit"
                  disabled={!checkbox}
                  variant={'contained'}
                  onClick={handleSaveClick}
                >
                  Save
                </CustomButton>
              </div>
            </>
          )}
        </div>
      )}
      {showMLeadDetails && (
        <LeadDetails user={mLeadObj} handleClick={handleBackClick} />
      )}
    </>
  )
}

export default GetMappedLeadsToAgent
