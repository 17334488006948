import { ViewDetails } from 'components'

const ClientDetails = ({ user, handleClick }) => {
  const fieldsToDisplay = [
    { value: 'employeeCode', label: 'Employee Code' },
    { value: 'agencyCode', label: 'Agency Code' },
    { value: 'posCode', label: 'POS Code' },
    { value: 'firstName', label: 'First Name' },
    { value: 'middleName', label: 'Middle Name' },
    { value: 'lastName', label: 'Last Name' },
    { value: 'gender', label: 'Gender' },
    { value: 'dateOfBirth', label: 'Date Of Birth' },
    { value: 'email', label: 'Email ID' },
    { value: 'phoneNo', label: 'Mobile Number' },
    { value: 'address', label: 'Address' },
    { value: 'country', label: 'Country' },
    { value: 'state', label: 'State' },
    { value: 'district', label: 'District' },
    { value: 'city', label: 'City' },
    { value: 'pincode', label: 'Pin Code' },
  ]
  return (
    <ViewDetails
      fieldsToDisplay={fieldsToDisplay}
      user={user}
      handleClick={handleClick}
    />
  )
}

export default ClientDetails
