import { useEffect, useState } from 'react'
import { CustomButton, CustomModal, CustomTable, Toast } from 'components'
import { Checkbox } from '@mui/material'
import { useAssignLeadMutation } from 'api/leadAPI'

const tableHeaders = [
  { label: '', field: 'check' },
  { label: 'sr. no.', field: '' },
  { label: 'Profile Code', field: 'profileCode' },
  { label: 'First Name', field: 'firstName' },
  { label: 'Last Name', field: 'lastName' },
  { label: 'PinCode', field: 'pincode' },
  { label: 'Phone Number', field: 'phoneNo' },
  { label: 'View', field: 'view' },
]

export default function LeadsReallocationTable({
  tableData,
  setStep,
  step,
  setUserObj,
  leadNumbers,
  userTypeValue,
  setIsLoading,
}) {
  const capitalizeUserType =
    userTypeValue === 'pos'
      ? userTypeValue.toUpperCase()
      : userTypeValue.charAt(0).toUpperCase() + userTypeValue.slice(1)
  const [modal, setModal] = useState(false)
  const [selected, setSelected] = useState(null)
  const [profileCode, setProfileCode] = useState(null)

  const [assignLead, { isSuccess, isLoading, isError }] =
    useAssignLeadMutation()

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading, setIsLoading])

  useEffect(() => {
    isSuccess ? setModal(true) : setModal(false)
  }, [isSuccess])

  const onClickAssign = () => {
    const postData = {
      userType: 'individual',
      userSubType: capitalizeUserType,
      profileCode: profileCode,
      leadNumbers: leadNumbers,
    }
    assignLead(postData)
  }

  const handleClick = (user) => {
    setUserObj(user)
    setStep(step + 1)
  }

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'profileCode':
        return row.employeeCode || row.posCode || row.agencyCode
      case 'firstName':
        return row.firstName
      case 'lastName':
        return row.lastName
      case 'pincode':
        return row.pincode
      case 'phoneNo':
        return `+${row.mobile || row.officeMobile || row.phoneNo}`
      case 'view':
        return (
          <CustomButton variant={'outlined'} onClick={() => handleClick(row)}>
            View
          </CustomButton>
        )
      case 'check':
        return (
          <Checkbox
            checked={selected === row.id}
            onClick={() => {
              setSelected(row.id)
              setProfileCode(row.employeeCode || row.posCode || row.agencyCode)
            }}
          />
        )
      default:
        return ''
    }
  }
  return (
    <>
      {isError && (
        <Toast
          message={'Error assigning Lead Please Try Again'}
          severity={'error'}
        />
      )}
      <CustomTable
        tableLabel="Role Search Result"
        headers={tableHeaders}
        rows={tableData}
        render={renderCell}
      />
      <div className="flex justify-center gap-4 my-4">
        <CustomButton
          type="submit"
          variant={'contained'}
          disabled={selected ? false : true}
          onClick={onClickAssign}
        >
          Save
        </CustomButton>
        <CustomButton variant={'outlined'} onClick={() => setStep(1)}>
          Back
        </CustomButton>
      </div>
      <CustomModal
        message={'Lead Re-Assigned Successfully!'}
        modalOpen={modal}
        handleModalClose={() => setModal(false)}
      />
    </>
  )
}
