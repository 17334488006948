import { CustomTable, CustomButton } from 'components'

const tableHeaders = [
  { label: 'sr. no.', field: '' },
  { label: 'Name', field: 'name' },
  { label: 'User Type', field: 'userType' },
  { label: 'Country', field: 'country' },
  { label: 'State', field: 'state' },
  { label: 'View', field: 'view' },
]

const UsersTable = ({ users, handleClick }) => {
  const renderCell = (row, header) => {
    switch (header.field) {
      case 'name':
        return `${row.completeName}`
      case 'userType':
        return row.userType
      case 'country':
        return row.country
      case 'state':
        return row.state
      case 'view':
        return (
          <CustomButton variant="outlined" onClick={() => handleClick(row)}>
            View
          </CustomButton>
        )
      default:
        return ''
    }
  }

  return (
    <CustomTable
      tableLabel="Users List"
      headers={tableHeaders}
      rows={users}
      render={renderCell}
    />
  )
}

export default UsersTable
