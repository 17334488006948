import { useLayoutEffect, useRef } from 'react'

export const useScrollToTable = (dependencies) => {
  const tableRef = useRef(null)

  useLayoutEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, dependencies)

  return tableRef
}
