import { Typography } from '@mui/material'
import {
  CustomButton,
  CustomDropdown,
  CustomTextField,
  CustomMobileInput,
  CustomPaper,
  PincodeInput,
  LocationDropdown,
  CustomDatePicker,
  CsvUploadForm,
} from 'components'
import {
  GeneralSubTypeOption,
  HealthSubTypeOptions,
  LifeSubTypeOptions,
  TitleOption,
  leadOptions,
  leadSource,
  lookingFor,
} from 'constant/formOptions'
import { useCustomForm } from 'hooks/useCustomForm'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createLeadSchema } from 'schema'
import { filterFormData } from 'utils/CustomUtils'

function CreateLeadForm({ onSubmit, onBulkSubmit, buttonLabel, formHeader }) {
  const navigate = useNavigate()
  let leadSubTypeOption = []
  const {
    handleSubmit,
    control,
    resetForm,
    formState: { errors },
    watch,
  } = useCustomForm({}, createLeadSchema)

  const iamId = useSelector((state) => state.auth.iamId)

  if (watch('leadType') === 'Life insurance') {
    leadSubTypeOption = LifeSubTypeOptions
  } else if (watch('leadType') === 'Health insurance') {
    leadSubTypeOption = HealthSubTypeOptions
  } else if (watch('leadType') === 'General insurance') {
    leadSubTypeOption = GeneralSubTypeOption
  }

  const handleFormSubmit = (data) => {
    onSubmit(filterFormData(data), iamId)
    resetForm()
  }

  return (
    <CustomPaper>
      <div className="flex flex-row justify-between">
        <Typography
          component={'h1'}
          sx={{
            fontWeight: 700,
            fontSize: { xs: 15, sm: 15, md: 20, lg: 24 },
          }}
          className="p-4 pl-8 text-center capitalize"
        >
          {formHeader}
        </Typography>
        <div className="text-center">
          <CsvUploadForm
            postData={onBulkSubmit}
            label={'Leads'}
            smartCovrFormat
          />
        </div>
      </div>
      <div className="grid gap-6 lg:grid-cols-2 md:grid-cols-1">
        <CustomDropdown
          control={control}
          select
          name={'leadType'}
          label="Lead Type"
          options={leadOptions}
          isRequired
        />
        <CustomDropdown
          control={control}
          select
          name={'leadSubType'}
          label="Lead Sub Type"
          options={leadSubTypeOption}
          isRequired
        />
        <CustomDropdown
          control={control}
          select
          name={'title'}
          label="Title"
          options={TitleOption}
          isRequired
        />
        <CustomTextField
          control={control}
          name={'leadName'}
          label="Name of Entity"
          isRequired
        />
        <CustomDatePicker
          name={'dob'}
          control={control}
          label="Date of Birth"
          isRequired
        />
        <CustomTextField
          control={control}
          name={'officeEmail'}
          label="Email ID (Office)"
          type={'email'}
          isRequired
        />
        <CustomTextField
          control={control}
          name={'email'}
          label="Email ID (Personal)"
          type={'email'}
          isRequired
        />
        <CustomMobileInput
          name={'mobile'}
          label={'Mobile Number'}
          control={control}
          variant="outlined"
          errors={errors}
          isRequired
          smartCovrFormat
        />
        <CustomTextField
          name={'address'}
          control={control}
          label={'Address'}
          isRequired
        />
        <LocationDropdown control={control} watch={watch} isRequired />
        <PincodeInput
          control={control}
          name={'pincode'}
          label={'Pin Code'}
          isRequired
        />
        {watch('city') === 'others' && (
          <CustomTextField
            control={control}
            name={'newCity'}
            label="Add New City/Area"
            isRequired
          />
        )}
        <CustomDropdown
          control={control}
          name={'leadSource'}
          label="Source of Lead"
          select
          options={leadSource}
          isRequired
        />
        <CustomTextField
          control={control}
          name={'occupation'}
          label="Occupation"
        />
        <CustomTextField
          control={control}
          name={'organization'}
          label="Organization"
        />
        <CustomDropdown
          control={control}
          name={'policyOption'}
          label="Policy Type"
          select
          options={lookingFor}
          isRequired
        />
      </div>
      <div className="flex justify-center gap-4 my-4">
        <CustomButton
          type="submit"
          variant="contained"
          onClick={handleSubmit(handleFormSubmit)}
        >
          {buttonLabel}
        </CustomButton>
        <CustomButton variant="outlined" onClick={() => navigate('/')}>
          Back
        </CustomButton>
      </div>
    </CustomPaper>
  )
}

export default CreateLeadForm
