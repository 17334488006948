import { useState } from 'react'
import {
  AssignLeadsAllocationForm,
  LeadTable,
  LeadAllocation,
  LeadAllocationTable,
  LeadDetails,
} from 'features/assignLeadsAndAllocation'
import { CustomLoading } from 'components'
export default function AssignLeadsAllocation() {
  const [step, setStep] = useState(1)
  const [userObj, setUserObj] = useState({})
  const [tableData, setTableData] = useState([])
  const [leadData, setLeadData] = useState([])
  const [showLeadTable, setShowLeadTable] = useState(false)
  const [showLeadAllocationTable, setShowLeadAllocationTable] = useState(false)
  const [leadNumbers, setLeadNumbers] = useState()
  const [userTypeValue, setUserTypeValue] = useState()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      {isLoading && <CustomLoading />}
      {step === 1 && (
        <AssignLeadsAllocationForm
          setTableData={setTableData}
          setShowLeadTable={setShowLeadTable}
          setIsLoading={setIsLoading}
        />
      )}
      {step === 1 && showLeadTable && (
        <LeadTable
          setLeadNumbers={setLeadNumbers}
          setShowLeadTable={setShowLeadTable}
          tableData={tableData}
          setStep={setStep}
          setUserObj={setUserObj}
        />
      )}
      {(step === 2 || step === 4) && (
        <LeadDetails
          user={userObj}
          handleClick={() => setStep((prev) => prev - 1)}
          step={step}
        />
      )}
      {step === 3 && (
        <LeadAllocation
          setUserTypeValue={setUserTypeValue}
          setTableData={setLeadData}
          setStep={setStep}
          setShowLeadAllocationTable={setShowLeadAllocationTable}
          setIsLoading={setIsLoading}
        />
      )}
      {step === 3 && showLeadAllocationTable && (
        <LeadAllocationTable
          userTypeValue={userTypeValue}
          leadNumbers={leadNumbers}
          tableData={leadData}
          step={step}
          setStep={setStep}
          setUserObj={setUserObj}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  )
}
