import { CustomLoading, Toast } from 'components'
import { useState } from 'react'
import {
  EmployeeDetails,
  EmployeeTable,
  EmployeeReMappingTable,
} from 'features/employeeToEmployeeRemapping'
import { filterFormData } from 'utils/CustomUtils'
import { useGetUsersQuery } from 'api/usersAPI'
import { EmployeeForm } from 'forms'

function EmployeeToEmployeeRemapping() {
  const [step, setStep] = useState(1)
  const [user, setUser] = useState({})
  const [employeeSkip, setEmployeeSkip] = useState(true)
  const [assignedEmployeeSkip, setAssignedEmployeeSkip] = useState(true)
  const [employeeFormData, setEmployeeFormData] = useState({})
  const [assignedEmployeeFormData, setAssignedEmployeeFormData] = useState({})
  const [assignTo, setAssignTo] = useState('')

  const employeeFormDataFiltered = filterFormData(employeeFormData)
  const employeeFormData2Filtered = filterFormData(assignedEmployeeFormData)

  const {
    data: employees = [],
    isFetching: isEmployeeFetching,
    isLoading: isEmployeeLoading,
    isError: isEmployeeError,
  } = useGetUsersQuery(
    {
      ...employeeFormDataFiltered,
      userType: 'individual',
      userSubType: 'employee',
    },
    { skip: employeeSkip }
  )

  const {
    data: assignedEmployees = [],
    isFetching: isUnassignedEmployeeFetching,
    isLoading: isUnassignedEmployeeLoading,
    isError: isUnassignedEmployeeError,
  } = useGetUsersQuery(
    {
      ...employeeFormData2Filtered,
      userType: 'individual',
      userSubType: 'employee',
      assigned: true,
    },
    { skip: assignedEmployeeSkip }
  )

  const onEmployeeSubmit = (formData) => {
    setEmployeeFormData({ ...formData })
    setEmployeeSkip(false)
  }

  const onUnassignedEmployeeSubmit = (formData) => {
    setAssignedEmployeeFormData({ ...formData })
    setAssignedEmployeeSkip(false)
  }

  return (
    <>
      {(isEmployeeError || isUnassignedEmployeeError) && (
        <Toast
          message={'Error Fetching Users Try Again in Sometime'}
          severity={'error'}
        />
      )}
      {isEmployeeLoading &&
        isUnassignedEmployeeLoading &&
        isUnassignedEmployeeFetching &&
        isEmployeeFetching && <CustomLoading />}
      {step === 1 && (
        <EmployeeForm
          formData={employeeFormData}
          onSubmit={onEmployeeSubmit}
          formHeader={'Employee To Employee Re-Mapping'}
        />
      )}
      {step === 1 && !!employees.length && (
        <EmployeeTable
          tableData={employees}
          setUserObj={setUser}
          setStep={setStep}
          setAssignTo={setAssignTo}
        />
      )}
      {step === 2 && (
        <EmployeeDetails
          user={user}
          handleClick={() => setStep((prev) => prev - 1)}
        />
      )}
      {step === 3 && (
        <EmployeeForm
          formData={assignedEmployeeFormData}
          onSubmit={onUnassignedEmployeeSubmit}
          formHeader={'Search Employee/User '}
        />
      )}
      {step === 3 && !!assignedEmployees.length && (
        <EmployeeReMappingTable
          setStep={setStep}
          tableData={assignedEmployees}
          assignTo={assignTo}
        />
      )}
    </>
  )
}

export default EmployeeToEmployeeRemapping
