import { CustomButton, CustomDropdown } from 'components'
import { userOptions, userSubTypeOptions } from 'constant/formOptions'
import { useCustomForm } from 'hooks/useCustomForm'
import { userTypeSchema } from './schema'

function UserTypeSelectionForm({ onSubmit }) {
  const { handleSubmit, control, resetForm, watch } = useCustomForm(
    {},
    userTypeSchema
  )

  const handleFormSubmit = (data) => {
    onSubmit(data)
    resetForm()
  }

  const registerUserSubType = watch('registerUserSubType')
  const isSubTypeSelected = !!registerUserSubType

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex flex-col justify-center min-h-screen py-6 sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="absolute inset-0 transform -skew-y-6 shadow-lg bg-gradient-to-r from-blue-300 to-blue-600 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
          <div className="relative lg:w-[35rem] md:w-[30rem] sm:w-96 px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
            <div className="max-w-md mx-auto">
              <div>
                <h1 className="mb-2 text-2xl font-semibold text-center">
                  Registration
                </h1>
              </div>
              <CustomDropdown
                name="registerUserType"
                control={control}
                defaultValue="individual"
                label="User Type"
                options={userOptions}
                className="mb-4"
              />
              <CustomDropdown
                name="registerUserSubType"
                control={control}
                label="User Sub Type"
                options={userSubTypeOptions}
              />
              <div className="flex justify-center mt-8">
                <CustomButton
                  type="submit"
                  variant="contained"
                  disabled={!isSubTypeSelected}
                >
                  Next
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default UserTypeSelectionForm
