import { createApi } from '@reduxjs/toolkit/query/react'
import { wrappedFetchBaseQuery } from './baseQuery'
import { config } from 'config'

export const leadAPI = createApi({
  reducerPath: 'leadAPI',
  baseQuery: wrappedFetchBaseQuery,
  endpoints: (builder) => ({
    getLeads: builder.query({
      query: (filters) => ({
        url: '/lead/admin',
        params: filters,
      }),
    }),
    assignLead: builder.mutation({
      query: (lead) => ({
        url: '/assignLeads',
        method: 'POST',
        body: lead,
      }),
    }),
    createLead: builder.mutation({
      query: (lead) => ({
        url: '/lead',
        method: 'POST',
        body: lead,
      }),
    }),
    assignSelfLead: builder.mutation({
      query: ({ lead, iamId }) => ({
        url: '/lead',
        method: 'POST',
        body: lead,
        params: { iamId },
      }),
    }),
    myLeads: builder.query({
      query: (filters) => ({
        url: '/lead/user',
        params: filters,
      }),
    }),
    createBulkLeads: builder.mutation({
      query: (leads) => ({
        url: '/bulkLead',
        method: 'POST',
        body: leads,
      }),
    }),
    assignSelfBulkLeads: builder.mutation({
      query: (leads) => ({
        url: '/bulkLead',
        method: 'POST',
        body: leads,
        params: { iamId: config.iamId },
      }),
    }),
  }),
})

export const {
  useGetLeadsQuery,
  useAssignLeadMutation,
  useCreateLeadMutation,
  useAssignSelfLeadMutation,
  useMyLeadsQuery,
  useCreateBulkLeadsMutation,
  useAssignSelfBulkLeadsMutation,
} = leadAPI
