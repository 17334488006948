import { FormHelperText, MenuItem } from '@mui/material'
import { Controller } from 'react-hook-form'
import { StyledDropdown } from '../CustomDropdown'
import CustomLabel from '../CustomLabel'
import { countries } from '../../constant/countries'
import { populateLocationDropdown } from './helper'

function LocationDropdown({
  className,
  sx,
  disabled,
  isRequired,
  control,
  watch,
  helperText,
}) {
  const countryOptions = populateLocationDropdown('country', countries)
  const countryValue = watch('country')
  const regionValue = watch('region')
  const stateValue = watch('state')
  const regionOptions = populateLocationDropdown(
    'region',
    countries,
    countryValue
  )
  const stateOptions = populateLocationDropdown(
    'state',
    countries,
    countryValue,
    regionValue
  )
  const districtOptions = populateLocationDropdown(
    'district',
    countries,
    countryValue,
    regionValue,
    stateValue
  )
  const generateSingleOptions = (options) => {
    return options.map((option, index) => (
      <MenuItem key={index} value={option.value}>
        {option.label}
      </MenuItem>
    ))
  }
  return (
    <>
      <div className="flex flex-col">
        <CustomLabel htmlFor={'country'} className="mt-2 mb-1 capitalize">
          {'Country'}
          {isRequired && <span className="text-red-700"> *</span>}
        </CustomLabel>
        <Controller
          name="country"
          control={control}
          defaultValue={''}
          render={({
            field: { ref, onChange, value },
            fieldState: { error },
          }) => (
            <>
              <StyledDropdown
                inputRef={ref}
                disabled={disabled}
                defaultValue={''}
                className={className}
                value={value}
                sx={sx}
                onChange={(e) => onChange(e.target.value)}
                error={!!error}
                data-testid={`country-select-field`}
              >
                {generateSingleOptions(countryOptions)}
              </StyledDropdown>
              {error && (
                <FormHelperText error={!!error}>
                  {error ? error.message : helperText}
                </FormHelperText>
              )}
            </>
          )}
        />
      </div>
      <div className="flex flex-col">
        <CustomLabel htmlFor={'region'} className="mt-2 mb-1 capitalize">
          {'Region'}
          {isRequired && <span className="text-red-700"> *</span>}
        </CustomLabel>
        <Controller
          name="region"
          control={control}
          defaultValue={''}
          render={({
            field: { ref, onChange, value },
            fieldState: { error },
          }) => (
            <>
              <StyledDropdown
                inputRef={ref}
                disabled={disabled}
                defaultValue={''}
                className={className}
                value={value}
                sx={sx}
                onChange={(e) => onChange(e.target.value)}
                error={!!error}
                data-testid={`region-select-field`}
              >
                {generateSingleOptions(regionOptions)}
              </StyledDropdown>
              {error && (
                <FormHelperText error={!!error}>
                  {error ? error.message : helperText}
                </FormHelperText>
              )}
            </>
          )}
        />
      </div>
      <div className="flex flex-col">
        <CustomLabel htmlFor={'state'} className="mt-2 mb-1 capitalize">
          {'State'}
          {isRequired && <span className="text-red-700"> *</span>}
        </CustomLabel>
        <Controller
          name="state"
          control={control}
          defaultValue={''}
          render={({
            field: { ref, onChange, value },
            fieldState: { error },
          }) => (
            <>
              <StyledDropdown
                inputRef={ref}
                disabled={disabled}
                defaultValue={''}
                className={className}
                value={value}
                sx={sx}
                onChange={(e) => onChange(e.target.value)}
                error={!!error}
                data-testid={`state-select-field`}
              >
                {generateSingleOptions(stateOptions)}
              </StyledDropdown>
              {error && (
                <FormHelperText error={!!error}>
                  {error ? error.message : helperText}
                </FormHelperText>
              )}
            </>
          )}
        />
      </div>
      <div className="flex flex-col">
        <CustomLabel htmlFor={'district'} className="mt-2 mb-1 capitalize">
          {'District'}
          {isRequired && <span className="text-red-700"> *</span>}
        </CustomLabel>
        <Controller
          name="district"
          control={control}
          defaultValue={''}
          label
          render={({
            field: { ref, onChange, value },
            fieldState: { error },
          }) => (
            <>
              <StyledDropdown
                inputRef={ref}
                disabled={disabled}
                defaultValue={''}
                className={className}
                value={value}
                sx={sx}
                onChange={(e) => onChange(e.target.value)}
                error={!!error}
                data-testid={`district-select-field`}
              >
                {generateSingleOptions(districtOptions)}
              </StyledDropdown>
              {error && (
                <FormHelperText error={!!error}>
                  {error ? error.message : helperText}
                </FormHelperText>
              )}
            </>
          )}
        />
      </div>
    </>
  )
}

export default LocationDropdown
