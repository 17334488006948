import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  CustomButton,
  CustomDatePicker,
  CustomDropdown,
  CustomPaper,
  CustomTextField,
  LocationDropdown,
  PincodeInput,
} from 'components'
import { Branches, selectUserOptions } from 'constant/formOptions'
import { useCustomForm } from 'hooks/useCustomForm'
import { clientSchema } from './schema'

export default function ViewAllClientsForm({ onSubmit, formData }) {
  const navigate = useNavigate()
  const { handleSubmit, control, watch } = useCustomForm(formData, clientSchema)

  return (
    <CustomPaper>
      <Typography
        component={'h1'}
        sx={{
          fontWeight: 700,
          fontSize: { xs: 15, sm: 15, md: 20, lg: 24 },
        }}
        className="p-4 pl-8 text-center capitalize"
      >
        View All Clients
      </Typography>
      <div className="grid gap-6 lg:grid-cols-2 md:grid-cols-1 ">
        <CustomDropdown
          name={'client'}
          control={control}
          label="Client"
          options={selectUserOptions}
          isRequired={true}
        />
        {watch('client') === 'byName' && (
          <CustomTextField
            name={'name'}
            control={control}
            label={'Enter Client Name'}
            isRequired={true}
          />
        )}
        <CustomDatePicker
          label="From Date"
          name={'dateFrom'}
          control={control}
        />
        <CustomDatePicker label="To Date" name={'dateTo'} control={control} />
        <LocationDropdown control={control} watch={watch} />
        <PincodeInput control={control} name={'pincode'} label={'Pin Code'} />
        {watch('city') === 'others' && (
          <CustomTextField
            control={control}
            name={'newCity'}
            label="Add New City/Area"
            isRequired
          />
        )}
        <CustomDropdown
          name={'branch'}
          control={control}
          label="Branch"
          options={Branches}
        />
      </div>
      <div className="flex justify-center gap-4 my-4">
        <CustomButton
          type="submit"
          variant={'contained'}
          onClick={handleSubmit(onSubmit)}
        >
          Search
        </CustomButton>
        <CustomButton variant={'outlined'} onClick={() => navigate('/')}>
          Back
        </CustomButton>
      </div>
    </CustomPaper>
  )
}
