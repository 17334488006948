import { ViewDetails } from 'components'

export default function EmployeeDetails({ user, handleClick }) {
  const fieldsToDisplay = [
    { value: 'employeeCode', label: 'Employee Code' },
    { value: 'firstName', label: 'First Name' },
    { value: 'middleName', label: 'Middle Name' },
    { value: 'lastName', label: 'Last Name' },
    { value: 'gender', label: 'Gender' },
    { value: 'dateOfBirth', label: 'Date Of Birth' },
    { value: 'designation', label: 'Designation' },
    { value: 'officeEmail', label: 'Email ID (Official)' },
    { value: 'email', label: 'Email ID (Personal)' },
    { value: 'officeMobile', label: 'Mobile Number (Official)' },
    { value: 'phoneNo', label: 'Mobile Number (Personal)' },
    { value: 'address', label: 'Address' },
    { value: 'country', label: 'Country' },
    { value: 'state', label: 'State' },
    { value: 'district', label: 'District' },
    { value: 'city', label: 'City/Area' },
    { value: 'pincode', label: 'Pin Code' },
    { value: 'branch', label: 'Branch' },
  ]
  return (
    <ViewDetails
      user={user}
      handleClick={handleClick}
      fieldsToDisplay={fieldsToDisplay}
    />
  )
}
