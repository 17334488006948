import styled from '@emotion/styled'
import { Controller, useWatch } from 'react-hook-form'
import CustomLabel from './CustomLabel'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'

const StyledDatePicker = styled(DatePicker)(({ theme, error }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 2,
      borderColor: error ? theme.palette.error.main : '',
    },
    '&:hover fieldset': {
      borderColor: error
        ? theme.palette.error.light
        : theme.palette.primary.light,
    },
    '&.Mui-focused fieldset': {
      borderColor: error
        ? theme.palette.error.main
        : theme.palette.primary.main,
    },
  },
  '& .MuiFormHelperText-root': {
    color: theme.palette.error.main,
  },
}))

function CustomDatePicker({
  defaultValue,
  control,
  placeholder,
  label,
  name,
  isRequired,
  ...restProps
}) {
  const testId = `${name}-datepicker`
  const format = 'dd/MM/yyyy'
  const today = new Date()

  const dateFromValue = useWatch({
    control,
    name: 'dateFrom',
    defaultValue: '',
  })
  const minDate =
    name === 'dateTo' && dateFromValue
      ? new Date(dateFromValue)
      : new Date(today.getFullYear() - 80, today.getMonth(), today.getDate())

  return (
    <div className="flex flex-col">
      <CustomLabel htmlFor={name} className="mt-2 mb-1 capitalize">
        {label}
        {isRequired && <span className="text-red-700"> *</span>}
      </CustomLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        render={({
          field: { ref, onChange, value },
          fieldState: { error },
        }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledDatePicker
              inputRef={ref}
              placeholder={placeholder}
              format={format}
              value={value || null}
              onChange={onChange}
              minDate={minDate}
              slotProps={{
                textField: {
                  helperText: error?.message,
                  'data-testid': testId,
                },
              }}
              disableFuture
              error={Boolean(error)}
              {...restProps}
            />
          </LocalizationProvider>
        )}
      />
    </div>
  )
}

export default CustomDatePicker
