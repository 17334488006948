import { FormControlLabel, Switch, Typography } from '@mui/material'
import { useState } from 'react'
import {
  CustomButton,
  CustomDatePicker,
  CustomDropdown,
  CustomPaper,
  CustomTextField,
  LocationDropdown,
  PincodeInput,
} from 'components'
import {
  userOptions,
  Branches,
  userSubTypeOptions,
  employeeDesignationOptions,
} from 'constant/formOptions'
import { useCustomForm } from 'hooks/useCustomForm'
import { userProfileCodeSchema, userSchema } from './schema'

function ViewMappedClientsForm({ onSubmit }) {
  const { control, handleSubmit, watch } = useCustomForm({}, userSchema)
  const { control: control2, handleSubmit: handleSubmit2 } = useCustomForm(
    {},
    userProfileCodeSchema
  )

  const [showEmployeeForm, setShowEmployeeForm] = useState(false)

  const handleToggle = () => {
    setShowEmployeeForm((prev) => !prev)
  }

  return (
    <CustomPaper>
      <Typography
        component={'h1'}
        sx={{
          fontWeight: 700,
          fontSize: { xs: 15, sm: 15, md: 20, lg: 24 },
        }}
        className="p-4 pl-8 text-center capitalize"
      >
        View Mapped Clients
      </Typography>
      <div className="flex justify-start my-4">
        <FormControlLabel
          control={
            <Switch checked={showEmployeeForm} onChange={handleToggle} />
          }
          label="Search By Profile Code"
        />
      </div>
      {!showEmployeeForm && (
        <form className="grid gap-6 lg:grid-cols-2 md:grid-cols-1 ">
          <CustomDropdown
            name={'userType'}
            control={control}
            label="User Type"
            options={userOptions}
            isRequired={true}
          />
          <CustomDropdown
            name={'userSubType'}
            control={control}
            label="User Sub Type"
            options={userSubTypeOptions}
            isRequired={true}
          />
          {watch('userSubType') === 'employee' && (
            <CustomDropdown
              name={'designation'}
              control={control}
              label={'Employee Designation'}
              options={employeeDesignationOptions}
            />
          )}
          <CustomDatePicker
            label="From Date"
            name={'dateFrom'}
            control={control}
          />
          <CustomDatePicker label="To Date" name={'dateTo'} control={control} />
          <LocationDropdown control={control} watch={watch} />
          <PincodeInput control={control} name={'pincode'} label={'Pin Code'} />
          {watch('city') === 'others' && (
            <CustomTextField
              control={control}
              name={'newCity'}
              label="Add New City/Area"
              isRequired
            />
          )}
          <CustomDropdown
            name={'branch'}
            control={control}
            select
            label="Branch"
            options={Branches}
          />
        </form>
      )}
      {showEmployeeForm && (
        <form className="grid gap-6 lg:grid-cols-2 md:grid-cols-1">
          <CustomDropdown
            name={'userType'}
            control={control2}
            label="User Type"
            options={userOptions}
            isRequired={true}
          />
          <CustomDropdown
            name={'userSubType'}
            control={control2}
            label="User Sub Type"
            options={userSubTypeOptions}
            isRequired={true}
          />
          <CustomTextField
            name={'profileCode'}
            control={control2}
            label={'Profile Code'}
            isRequired={true}
          />
          <CustomTextField name={'name'} control={control2} label={'Name'} />
        </form>
      )}
      <div className="flex justify-center gap-4 my-4">
        <CustomButton
          type="submit"
          variant={'contained'}
          onClick={
            !showEmployeeForm ? handleSubmit(onSubmit) : handleSubmit2(onSubmit)
          }
        >
          Search
        </CustomButton>
        <CustomButton variant={'outlined'}>Back</CustomButton>
      </div>
    </CustomPaper>
  )
}

export default ViewMappedClientsForm
