import { useCreateLeadMutation, useCreateBulkLeadsMutation } from 'api/leadAPI'
import { CustomLoading, CustomModal, Toast } from 'components'
import { useEffect, useState } from 'react'
import { CreateLeadForm } from 'forms'

function CreateLeads() {
  const [modalOpen, setModalOpen] = useState(false)
  const [duplicateLeads, setDuplicateLeads] = useState([])

  const [createLead, { isSuccess, isLoading, isFetching, isError }] =
    useCreateLeadMutation()
  const [
    postCreateBulkLeads,
    {
      isLoading: isBulkLoading,
      isError: isBulkError,
      isSuccess: isBulkSuccess,
      data: bulkLeadsData,
    },
  ] = useCreateBulkLeadsMutation()
  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    const duplicateLeadStatus = 'Lead Not Created'
    if (bulkLeadsData) {
      const isduplicateLeads = bulkLeadsData.filter(
        (lead) => lead.status === duplicateLeadStatus
      )
      if (isduplicateLeads.length) {
        setDuplicateLeads(isduplicateLeads)
      }
    }
    if (isSuccess || isBulkSuccess) {
      handleModalOpen()
    }
  }, [isSuccess, isBulkSuccess])

  const displayDuplicateLeadEmails = duplicateLeads.map((lead) => (
    <li className="text-lg sm:text-2xl" key={Math.random()}>
      {lead.email}
    </li>
  ))

  const onSubmit = (formData) => {
    const lead = {
      ...formData,
      address: {
        permanentAddress: formData.address,
        city: formData.city,
        country: formData.country,
        state: formData.state,
        pincode: formData.pincode,
        district: formData.district,
      },
    }
    createLead(lead)
  }

  const onBulkSubmit = (formData) => {
    postCreateBulkLeads(formData)
  }

  return (
    <>
      <CreateLeadForm
        onSubmit={onSubmit}
        onBulkSubmit={onBulkSubmit}
        buttonLabel={'Submit'}
        formHeader={'Create Lead'}
      />
      {(isError || isBulkError) && (
        <Toast
          message={'Error in Creating Lead Try Again in Sometime'}
          severity={'error'}
        />
      )}
      {(isLoading || isFetching || isBulkLoading) && <CustomLoading />}
      <CustomModal
        message={
          !isSuccess && duplicateLeads.length ? (
            <ul>
              <li className="text-red-500 text-base sm:text-2xl">
                Failed to Create Following Leads with Email Id`s
              </li>
              {displayDuplicateLeadEmails}
            </ul>
          ) : isSuccess ? (
            'Lead Created Successfully!'
          ) : (
            'File Uploaded Successfully!'
          )
        }
        modalOpen={modalOpen}
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
      />
    </>
  )
}

export default CreateLeads
