import * as yup from 'yup'

const employeeCodeSchema = yup.object({
  employeeCode: yup
    .string()
    .matches(/^[a-zA-Z0-9]*$/, {
      message: 'Only alphanumeric characters are allowed for this field',
    })
    .max(50, 'Must be less than 50 characters')
    .test(
      'isNotEmpty',
      'Either Employee Code or Name is Required',
      function (value) {
        const { employeeName } = this.parent
        return !!employeeName || !!value
      }
    ),
  employeeName: yup
    .string()
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .max(50, 'Must be less than 50 characters')
    .test(
      'isNotEmpty',
      'Either Employee Code or Name is Required',
      function (value) {
        const { employeeCode } = this.parent
        return !!employeeCode || !!value
      }
    ),
})

export default employeeCodeSchema
