import { ViewDetails } from 'components'

const UserDetails = ({ user, handleClick }) => {
  const fieldsToDisplay = [
    { value: 'employeeCode', label: 'Employee Code' },
    { value: 'agencyCode', label: 'Agency code' },
    { value: 'posCode', label: 'POS code' },
    { value: 'title', label: 'Title' },
    { value: 'firstName', label: 'First Name' },
    { value: 'middleName', label: 'Middle Name' },
    { value: 'lastName', label: 'Last Name' },
    { value: 'dateOfBirth', label: 'Date Of Birth' },
    { value: 'designation', label: 'Designation' },
    { value: 'email', label: 'Email' },
    { value: 'officeEmail', label: 'Office Email' },
    { value: 'officeMobile', label: 'Office Mobile' },
    { value: 'mobile', label: 'Mobile' },
    { value: 'phoneNo', label: 'Phone No' },
    { value: 'address', label: 'Address' },
    { value: 'country', label: 'Country' },
    { value: 'region', label: 'Region' },
    { value: 'state', label: 'State' },
    { value: 'district', label: 'District' },
    { value: 'city', label: 'City/Area' },
    { value: 'branch', label: 'Branch' },
    { value: 'pincode', label: 'Pin Code' },
    { value: 'nismRegistrationNumber', label: 'NSIM Registration Number' },
    {
      value: 'nismRegistrationNumberGenerationDate',
      label: 'NSIM Registration Number Generation Date',
    },
    { value: 'posRegistrationNumber', label: 'POS Registration Number' },
    {
      value: 'posRegistrationNumberGenerationDate',
      label: 'POS Registration Number Generation Date',
    },
    { value: 'qualification', label: 'Education Qualification' },
    { value: 'educationCertificate', label: 'Education Certificate' },
    { value: 'nismCertificate', label: 'Nism Certificate' },
    { value: 'photograph', label: 'Photograph' },
    { value: 'posCertificate', label: 'POS Certificate' },
  ]

  return (
    <ViewDetails
      user={user}
      handleClick={handleClick}
      fieldsToDisplay={fieldsToDisplay}
    />
  )
}

export default UserDetails
