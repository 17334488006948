import { AppBar, Toolbar, IconButton, Button, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  Menu as MenuIcon,
  HomeOutlined as HomeOutlinedIcon,
} from '@mui/icons-material'
import DrawerMenu from './DrawerMenu'
import { useState } from 'react'
import { getCookie, getEncodedRouteParams } from 'utils/CustomUtils'
import { config } from 'config'
import { useDispatch } from 'react-redux'
import { setLogin } from 'features/auth/authSlice'
import { useLogoutMutation } from 'api/logoutAPI'

function Navbar({ isLoggedIn, privateRoutes }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [logout] = useLogoutMutation()

  const clearCookiesAndLogout = () => {
    document.cookie =
      'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    document.cookie =
      'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    dispatch(setLogin(false))
    navigate('/')
  }

  const handleHomeClick = () => {
    navigate('/')
  }

  const toggleDrawer = () => {
    setOpen((prev) => !prev)
  }

  const handleLoginClick = async () => {
    const { queryString } = getEncodedRouteParams(config.tptLoginParams)
    window.location.href = `${config.tptLoginUrl}&${queryString}`
  }

  const handleLogoutClick = async () => {
    const body = {
      accessToken: getCookie('accessToken'),
      refreshToken: getCookie('refreshToken'),
    }
    try {
      await logout(body)
      clearCookiesAndLogout()
    } catch (err) {
      navigate('/')
    }
  }

  return (
    <>
      <AppBar position="sticky" className="w-full text-sm h-25">
        <Toolbar className="flex justify-between px-2 sm:px-12 md:px-14 lg:px-2">
          <Box display="flex" alignItems="center">
            {isLoggedIn && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
              >
                <MenuIcon className="w-6 h-6" />
              </IconButton>
            )}
          </Box>
          <Box className="flex items-center justify-center gap-3">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="home"
              onClick={handleHomeClick}
            >
              <HomeOutlinedIcon className="w-6 h-6" />
            </IconButton>
            {!isLoggedIn ? (
              <Button color="inherit" onClick={handleLoginClick}>
                Login
              </Button>
            ) : (
              <Button color="inherit" onClick={handleLogoutClick}>
                Logout
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <DrawerMenu
        open={open}
        toggleDrawer={toggleDrawer}
        privateRoutes={privateRoutes}
      />
    </>
  )
}

export default Navbar
