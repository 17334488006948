import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  CustomDropdown,
  CustomTextField,
  CustomButton,
  CustomMobileInput,
  CustomPaper,
  PincodeInput,
  Toast,
} from 'components'
import { userOptions, userSubTypeOptions } from 'constant/formOptions'
import { yupResolver } from '@hookform/resolvers/yup'
import { useGetUsersQuery } from 'api/usersAPI'
import { filterFormData } from 'utils/CustomUtils'
import { userSchema } from './schema'

export default function LeadsAllocation(props) {
  const {
    setShowLeadAllocationTable,
    setStep,
    setTableData,
    setUserTypeValue,
    setIsLoading,
  } = props

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  })

  const [formData, setFormData] = useState([{}])
  const [user, setUser] = useState(true)

  const formDataFiltered = filterFormData(formData)

  const {
    data: agents = [],
    isLoading,
    isError,
    isFetching,
    isSuccess,
  } = useGetUsersQuery(
    {
      ...formDataFiltered,
      userType: 'individual',
    },
    { skip: user }
  )

  useEffect(() => {
    setIsLoading(isLoading || isFetching)
  }, [isLoading, setIsLoading, isFetching])

  useEffect(() => {
    if (isSuccess) {
      setTableData(agents)
      setShowLeadAllocationTable(true)
    }
  }, [agents])

  const onSubmit = (formData) => {
    setFormData({ ...formData })
    setUser(false)
    setUserTypeValue(formData.userSubType)
  }
  const handleBackClick = () => {
    setStep(1)
  }

  return (
    <CustomPaper>
      {isError && (
        <Toast
          message={'Error Fetching users Try Again in Sometime'}
          severity={'error'}
        />
      )}
      <Typography
        component={'h1'}
        sx={{
          fontWeight: 700,
          fontSize: { xs: 15, sm: 15, md: 20, lg: 24 },
        }}
        className="p-4 pl-8 text-center capitalize"
      >
        Lead Allocation
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-6 lg:grid-cols-2 md:grid-cols-1 ">
          <CustomDropdown
            name={'userType'}
            control={control}
            label="User Type"
            defaultValue={'individual'}
            disabled={true}
            options={userOptions}
            isRequired={true}
          />
          <CustomDropdown
            name={'userSubType'}
            control={control}
            label="User Subtype"
            options={userSubTypeOptions}
            isRequired={true}
          />
          <CustomTextField
            name={'profileCode'}
            control={control}
            label={'Profile Code'}
          />
          <CustomTextField
            name={'firstName'}
            control={control}
            label={'First Name'}
          />
          <CustomTextField
            name={'lastName'}
            control={control}
            label={'Last Name'}
          />
          <PincodeInput control={control} name={'pincode'} label={'Pin Code'} />
          <CustomMobileInput
            name={'mobile'}
            label={'Phone Number'}
            control={control}
            variant="outlined"
            errors={errors}
          />
        </div>
        <div className="flex justify-center gap-4 my-4">
          <CustomButton type="submit" variant={'contained'}>
            Search
          </CustomButton>
          <CustomButton variant={'outlined'} onClick={handleBackClick}>
            Back
          </CustomButton>
        </div>
      </form>
    </CustomPaper>
  )
}
