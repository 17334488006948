import styled from '@emotion/styled'
import { TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import CustomLabel from './CustomLabel'

export const StyledTextField = styled(TextField)(({ theme, error }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 2,
    },
    '&:hover fieldset': {
      borderColor: error
        ? theme.palette.error.light
        : theme.palette.primary.light,
    },
    '&.Mui-focused fieldset': {
      borderColor: error
        ? theme.palette.error.main
        : theme.palette.primary.main,
    },
  },
}))

function CustomTextField({
  control,
  placeholder,
  variant,
  label,
  name,
  className,
  type,
  sx,
  defaultValue,
  isRequired,
  ...restProps
}) {
  return (
    <div className="flex flex-col">
      <CustomLabel htmlFor={name} className="mt-2 mb-1 capitalize">
        {label}
        {isRequired && <span className="text-red-700"> *</span>}
      </CustomLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        render={({
          field: { ref, onChange, value },
          fieldState: { error },
        }) => (
          <StyledTextField
            inputRef={ref}
            placeholder={placeholder}
            type={type}
            variant={variant}
            sx={sx}
            value={value}
            onChange={onChange}
            helperText={error ? error.message : null}
            error={!!error}
            className={className}
            inputProps={{
              'data-testid': `${name}-text-field`,
            }}
            {...restProps}
          />
        )}
      />
    </div>
  )
}

export default CustomTextField
