import { useState, useRef } from 'react'
import { useGetUsersQuery } from 'api/usersAPI'
import { UserDetails, UsersTable } from 'features/AllUsers'
import { CustomLoading, CustomButton, Toast } from 'components'
import { filterFormData } from 'utils/CustomUtils'
import { UsersForm } from 'forms'
import { useNavigate } from 'react-router-dom'

function AllUsers() {
  const [formData, setFormData] = useState({})
  const [user, setUser] = useState(true)
  const [showDetails, setShowDetails] = useState(false)
  const [userObj, setUserObj] = useState({})
  const tableRef = useRef(null)
  const navigate = useNavigate()

  const formDataFiltered = filterFormData(formData)

  const {
    data: users = [],
    isLoading,
    isFetching,
    isError,
  } = useGetUsersQuery(formDataFiltered, { skip: user })

  const handleClick = (user) => {
    setUserObj(user)
    setShowDetails(!showDetails)
  }

  const handleBackClick = () => {
    setShowDetails(!showDetails)
    setUser(true)
  }

  const onSubmit = (formData) => {
    setFormData({ ...formData })
    setUser(false)
  }

  return (
    <>
      {!showDetails && (
        <div>
          <UsersForm
            onSubmit={onSubmit}
            formData={formData}
            formHeader={'Search Users List'}
          />
          {isError && (
            <Toast
              message={'Error Fetching Users Try Again in Sometime'}
              severity={'error'}
            />
          )}
          {(isLoading || isFetching) && <CustomLoading />}
          {!!users.length && (
            <>
              <div className="mt-8" ref={tableRef}>
                <UsersTable users={users} handleClick={handleClick} />
              </div>
              <div className="flex justify-center mb-4">
                <CustomButton variant="outlined" onClick={() => navigate('/')}>
                  Back
                </CustomButton>
              </div>
            </>
          )}
        </div>
      )}
      {showDetails && (
        <UserDetails user={userObj} handleClick={handleBackClick} />
      )}
    </>
  )
}

export default AllUsers
