import { ViewDetails } from 'components'

export default function LeadDetails({ user, handleClick, step }) {
  let fieldsToDisplay
  if (step === 2) {
    fieldsToDisplay = [
      { value: 'leadType', label: 'Lead Type' },
      { value: 'leadSubType', label: 'Lead Sub Type' },
      { value: 'policyOption', label: 'Policy Type' },
      { value: 'leadName', label: 'Name' },
      { value: 'gender', label: 'Gender' },
      { value: 'dob', label: 'Date Of Birth' },
      { value: 'officeEmail', label: 'Email ID (Official)' },
      { value: 'email', label: 'Email ID (Personal)' },
      { value: 'mobile', label: 'Mobile Number' },
      { value: 'address', label: 'Address' },
      { value: 'country', label: 'Country' },
      { value: 'region', label: 'Region' },
      { value: 'state', label: 'State' },
      { value: 'district', label: 'District' },
      { value: 'city', label: 'City/Area' },
      { value: 'pincode', label: 'Pin Code' },
      { value: 'createdAt', label: 'Lead created on' },
    ]
  } else if (step === 4) {
    fieldsToDisplay = [
      { value: 'firstName', label: 'First Name' },
      { value: 'lastName', label: 'Last Name' },
      { value: 'email', label: 'Email ID' },
      { value: 'mobile', label: 'Mobile Number' },
      { value: 'country', label: 'Country' },
      { value: 'state', label: 'State' },
      { value: 'district', label: 'District' },
      { value: 'city', label: 'City/Area' },
      { value: 'pincode', label: 'PinCode' },
    ]
  }

  return (
    <ViewDetails
      user={user}
      handleClick={handleClick}
      fieldsToDisplay={fieldsToDisplay}
    />
  )
}
