import { TextField } from '@mui/material'
import { useController } from 'react-hook-form'
import CustomLabel from './CustomLabel'
import styled from '@emotion/styled'
import CustomDropdown from './CustomDropdown'
import { useGetPincodeQuery } from 'api/metadataAPI'
import Toast from './Toast'
import { useEffect, useState } from 'react'

const StyledPincodeInput = styled(TextField)(({ theme, error }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 2,
    },
    '&:hover fieldset': {
      borderColor: error
        ? theme.palette.error.light
        : theme.palette.primary.light,
    },
    '&.Mui-focused fieldset': {
      borderColor: error
        ? theme.palette.error.main
        : theme.palette.primary.main,
    },
  },
}))
const defaultCityOption = [
  {
    value: 'others',
    label: 'Others',
  },
]
const PincodeInput = ({ control, name, label, isRequired }) => {
  const [cityOptions, setCityOptions] = useState(defaultCityOption)
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    defaultValue: '',
  })

  const handleInputChange = async (e) => {
    const inputValue = e.target.value
    const truncatedValue = inputValue.slice(0, 6)
    const numericValue = truncatedValue.replace(/[^0-9]/g, '')
    inputProps.onChange(numericValue)
  }

  const { data, isError, isLoading, isSuccess } = useGetPincodeQuery(
    {
      pincode: inputProps.value,
      insurerId: 'IN-15472',
      insuranceType: 'term',
    },
    { skip: inputProps.value.length !== 6 }
  )

  const handleDataChange = () => {
    if (!isLoading && data) {
      const cities = data.map((item) => ({
        value: item.city,
        label: item.city,
      }))
      setCityOptions([...cities, ...defaultCityOption])
    }
    if (isError || inputProps.value.length < 6) {
      setCityOptions(defaultCityOption)
    }
  }

  useEffect(() => {
    handleDataChange()
  }, [data, isLoading, isError, inputProps.value])

  return (
    <>
      <div className="flex flex-col">
        <CustomLabel htmlFor={name} className="mt-2 mb-1 capitalize">
          {label}
          {isRequired && <span className="text-red-700"> *</span>}
        </CustomLabel>
        <StyledPincodeInput
          {...inputProps}
          inputRef={ref}
          type="text"
          onChange={handleInputChange}
          error={invalid}
          helperText={invalid ? error?.message : ''}
          inputProps={{
            'data-testid': `${name}-field`,
          }}
        />
      </div>
      <CustomDropdown
        name={'city'}
        control={control}
        label="City/Area"
        options={cityOptions}
      />
      {isSuccess && (
        <Toast message={'Cities has been Loaded'} severity={'success'} />
      )}
      {isError && (
        <Toast
          message={'Invalid Pincode Please try Again'}
          severity={'error'}
        />
      )}
    </>
  )
}

export default PincodeInput
