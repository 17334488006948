import { HashRouter, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { routes, mapRoutes, allRoutes } from './router'
import { theme, Navbar, CustomLoading } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { Landing, NotFound } from 'pages'
import TptHandler from 'features/auth/Tpt-Handler'
import { initializeUser, setIamId } from 'features/auth/authSlice'
import { useEffect, useState } from 'react'
import { newToken } from 'utils/CustomUtils'

function App() {
  const [loading, setLoading] = useState(true)
  const [privateRoutes, setPrivateRoutes] = useState([])
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoggedIn) {
      setPrivateRoutes(mapRoutes(allRoutes))
      newToken(isLoggedIn)
      dispatch(setIamId())
    }
  }, [isLoggedIn])

  useEffect(() => {
    const initialize = async () => {
      await dispatch(initializeUser())
      setLoading(false)
    }
    initialize()
  }, [dispatch])

  return (
    <HashRouter>
      <ThemeProvider theme={theme}>
        <Navbar isLoggedIn={isLoggedIn} privateRoutes={privateRoutes} />
        <Routes>
          <Route path="/" element={<Landing />} exact />
          <Route
            path="*"
            element={loading ? <CustomLoading /> : <NotFound />}
          />
          <Route path="/tpt-handler" element={<TptHandler />} />
          {isLoggedIn
            ? privateRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={<route.component />}
                  exact={route.exact}
                />
              ))
            : routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={<route.component />}
                  exact={route.exact}
                />
              ))}
        </Routes>
      </ThemeProvider>
    </HashRouter>
  )
}
export default App
