import * as yup from 'yup'

export const clientSchema = yup.object({
  client: yup.string().required('Please Select Client'),
  name: yup.string().when('client', {
    is: (value) => value && value === 'byName',
    then: () =>
      yup
        .string()
        .required('Please Provide Name')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .min(3, 'Must be minimum 3 characters')
        .max(70, 'Must be less Than 70 characters'),
    otherwise: () => yup.string().notRequired(),
  }),
  pincode: yup
    .string()
    .matches(/^$|^\d{6}$/, 'Pincode must be exactly 6 digits'),
  newCity: yup
    .string()
    .when('city', { is: 'others', then: (schema) => schema.min(3) })
    .matches(/^[aA-zZ\s]+$/, 'Please Provide New City Name'),
})
