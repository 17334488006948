import { FormControlLabel, Switch, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  CustomDatePicker,
  CustomDropdown,
  CustomTextField,
  CustomButton,
  CustomPaper,
  Toast,
  LocationDropdown,
  PincodeInput,
} from 'components'
import {
  userOptions,
  userSubTypeOptions,
  Branches,
  employeeDesignationOptions,
} from 'constant/formOptions'
import { userSchema } from './schema'
import { useGetUsersQuery } from 'api/usersAPI'
import { filterFormData } from 'utils/CustomUtils'
import { employeeCodeSchema } from 'schema'
import { useCustomForm } from 'hooks'

export default function ClientsReallocationForm({
  setShowClientReallocationTable,
  setStep,
  setTableData,
  setUserTypeValue,
  setIsLoading,
}) {
  const [byEmployeeCode, setByEmployeeCode] = useState(false)
  const [formData, setFormData] = useState({})
  const [user, setUser] = useState(true)

  const { control, resetForm, handleSubmit, watch } = useCustomForm(
    formData,
    userSchema
  )

  const {
    control: control2,
    resetForm: resetForm2,
    handleSubmit: handleSubmit2,
  } = useCustomForm(formData, employeeCodeSchema)

  const formDataFiltered = filterFormData(formData)

  const handleSwitchChange = () => {
    setByEmployeeCode(!byEmployeeCode)
    resetForm()
    resetForm2()
  }

  const {
    data: agents = [],
    isLoading,
    isError,
    isFetching,
    isSuccess,
  } = useGetUsersQuery(
    {
      ...formDataFiltered,
      userType: 'individual',
      userSubType: 'employee',
    },
    { skip: user }
  )

  useEffect(() => {
    setIsLoading(isLoading || isFetching)
  }, [isLoading, setIsLoading, isFetching])

  useEffect(() => {
    if (isSuccess) {
      setTableData(agents)
      setShowClientReallocationTable(true)
    }
  }, [agents])

  const onSubmit = (formData) => {
    setFormData({ ...formData })
    setUser(false)
    setUserTypeValue(formData.userSubType)
  }

  const onEmployeeSubmit = (formData) => {
    setFormData({ ...formData })
    setUser(false)
  }

  const handleBackClick = () => {
    setStep(1)
  }

  return (
    <CustomPaper>
      {isError && (
        <Toast
          message={'Error Reassigning Clients Please Try Again'}
          severity={'error'}
        />
      )}
      <Typography
        component={'h1'}
        sx={{
          fontWeight: 700,
          fontSize: { xs: 15, sm: 15, md: 20, lg: 24 },
        }}
        className="p-4 pl-8 text-center capitalize"
      >
        Client ReAllocation
      </Typography>
      <FormControlLabel
        control={
          <Switch checked={byEmployeeCode} onChange={handleSwitchChange} />
        }
        label="Search By Employee Code or Employee Name"
      />
      {!byEmployeeCode && (
        <div className="grid gap-6 lg:grid-cols-2 md:grid-cols-1 ">
          <CustomDatePicker
            label="From Date"
            name={'dateFrom'}
            control={control}
          />
          <CustomDatePicker label="To Date" name={'dateTo'} control={control} />
          <CustomDropdown
            name={'userType'}
            control={control}
            label="User Type"
            defaultValue={'individual'}
            disabled={true}
            options={userOptions}
            isRequired={true}
          />
          <CustomDropdown
            name={'userSubType'}
            control={control}
            label="User Subtype"
            defaultValue={'employee'}
            disabled={true}
            options={userSubTypeOptions}
            isRequired={true}
          />
          <CustomDropdown
            name={'designation'}
            control={control}
            label={'Employee Designation'}
            options={employeeDesignationOptions}
          />
          <LocationDropdown control={control} watch={watch} />
          <PincodeInput control={control} name={'pincode'} label={'Pin Code'} />
          {watch('city') === 'others' && (
            <CustomTextField
              control={control}
              name={'newCity'}
              label="Add New City/Area"
              isRequired
            />
          )}
          <CustomDropdown
            name={'branch'}
            control={control}
            label="Branch"
            options={Branches}
          />
        </div>
      )}
      {byEmployeeCode && (
        <div className="grid gap-6 lg:grid-cols-2 md:grid-cols-1">
          <CustomTextField
            name={'employeeCode'}
            control={control2}
            label={'Employee Code'}
          />
          <CustomTextField
            name={'employeeName'}
            control={control2}
            label={'Employee Name'}
          />
        </div>
      )}
      <div className="flex justify-center gap-4 my-4">
        <CustomButton
          type="submit"
          variant={'contained'}
          onClick={
            !byEmployeeCode
              ? handleSubmit(onSubmit)
              : handleSubmit2(onEmployeeSubmit)
          }
        >
          Search
        </CustomButton>
        <CustomButton variant={'outlined'} onClick={handleBackClick}>
          Back
        </CustomButton>
      </div>
    </CustomPaper>
  )
}
