import { Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CustomDatePicker,
  CustomDropdown,
  CustomButton,
  CustomTextField,
  CustomPaper,
  PincodeInput,
  LocationDropdown,
  Toast,
} from 'components'
import {
  selectUserOptions,
  leadOptions,
  Branches,
  LifeSubTypeOptions,
  HealthSubTypeOptions,
  GeneralSubTypeOption,
} from 'constant/formOptions'
import { useGetLeadsQuery } from 'api/leadAPI'
import { useCustomForm } from 'hooks/useCustomForm'
import { filterFormData } from 'utils/CustomUtils'
import { leadSchema } from './schema'

export default function AssignLeadsAllocationForm(props) {
  const { setShowLeadTable, setTableData, setIsLoading } = props
  const { control, watch, handleSubmit } = useCustomForm({}, leadSchema)
  const navigate = useNavigate()
  const [formData, setFormData] = useState([{}])
  const [user, setUser] = useState(true)

  let leadSubTypeOptions = []
  if (watch('leadType') === 'Life insurance') {
    leadSubTypeOptions = LifeSubTypeOptions
  } else if (watch('leadType') === 'Health insurance') {
    leadSubTypeOptions = HealthSubTypeOptions
  } else if (watch('leadType') === 'General insurance') {
    leadSubTypeOptions = GeneralSubTypeOption
  }

  const formDataFiltered = filterFormData(formData)

  const {
    data: leads = [],
    isLoading,
    isFetching,
    isError,
    isSuccess,
  } = useGetLeadsQuery(
    {
      ...formDataFiltered,
      assigned: false,
    },
    { skip: user }
  )

  useEffect(() => {
    setIsLoading(isLoading || isFetching)
  }, [isLoading, setIsLoading, isFetching])

  useEffect(() => {
    if (isSuccess) {
      setTableData(leads)
      setShowLeadTable(true)
    }
  }, [leads])

  const onSubmit = (data) => {
    setFormData({ ...data })
    setUser(false)
  }

  return (
    <CustomPaper>
      {isError && (
        <Toast
          message={'Error Fetching Leads Try Again in Sometime'}
          severity={'error'}
        />
      )}
      <Typography
        component={'h1'}
        sx={{
          fontWeight: 700,
          fontSize: { xs: 15, sm: 15, md: 20, lg: 24 },
        }}
        className="p-4 pl-8 text-center capitalize"
      >
        Search Lead
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-6 lg:grid-cols-2 md:grid-cols-1 ">
          <CustomDatePicker
            label="From Date"
            name={'dateFrom'}
            control={control}
            isRequired={true}
          />
          <CustomDatePicker
            label="To Date"
            name={'dateTo'}
            control={control}
            isRequired={true}
          />
          <CustomDropdown
            name={'userWiseLead'}
            control={control}
            label="User Wise Lead"
            options={selectUserOptions}
            isRequired={true}
          />
          <CustomDropdown
            name={'leadType'}
            control={control}
            label="Lead Type"
            options={leadOptions}
            isRequired={true}
          />
          {watch('userWiseLead') === 'byName' && (
            <CustomTextField
              name={'name'}
              control={control}
              label={'Name'}
              isRequired={true}
            />
          )}
          <CustomDropdown
            name={'leadSubType'}
            control={control}
            label="Lead Sub Type"
            options={leadSubTypeOptions}
            isRequired={true}
          />
          <LocationDropdown control={control} watch={watch} />
          <PincodeInput control={control} name={'pincode'} label={'Pin Code'} />
          {watch('city') === 'others' && (
            <CustomTextField
              control={control}
              name={'newCity'}
              label="Add New City/Area"
              isRequired
            />
          )}
          <CustomDropdown
            name={'branch'}
            control={control}
            label="Branch"
            options={Branches}
          />
        </div>
        <div className="flex justify-center gap-4 my-4">
          <CustomButton type="submit" variant={'contained'}>
            Search
          </CustomButton>
          <CustomButton variant={'outlined'} onClick={() => navigate('/')}>
            Back
          </CustomButton>
        </div>
      </form>
    </CustomPaper>
  )
}
