import { jwtDecode } from 'jwt-decode'
import {
  AllUsers,
  AssignLeadsAllocation,
  AssignLeadsToSelf,
  EmployeeToEmployeeRemapping,
  AllLeads,
  MyLeads,
  ViewAssignedLeads,
  ViewMappedAgents,
  MappedLeads,
  ViewAllClients,
  CreateLeads,
  Registration,
  ViewMappedEmployee,
  EmployeeAgentMapping,
  MyClients,
  EmployeeEmployeeMapping,
  ViewMappedClients,
  ReassignLeads,
  EmployeeAgentRemapping,
  ReassignClients,
} from 'pages'
import ViewMappedLeads from 'pages/MappedLeads/ViewMappedLeads'
import { getCookie } from 'utils/CustomUtils'

export const routes = []

export const allRoutes = [
  { path: '/create-users', component: Registration },
  { path: '/view-users', component: AllUsers },
  { path: '/create-leads', component: CreateLeads },
  { path: '/all-leads', component: AllLeads },
  { path: '/assign-leads-to-self', component: AssignLeadsToSelf },
  { path: '/assign-leads-allocation', component: AssignLeadsAllocation },
  { path: '/view-assigned-leads', component: ViewAssignedLeads },
  { path: '/view-mapped-agents', component: ViewMappedAgents },
  { path: '/my-leads', component: MyLeads },
  { path: '/mapped-leads', component: MappedLeads },
  {
    path: '/employee-agent-remapping',
    component: EmployeeAgentRemapping,
  },
  {
    path: '/employee-employee-remapping',
    component: EmployeeToEmployeeRemapping,
  },
  { path: '/all-clients', component: ViewAllClients },
  { path: '/view-mapped-employee', component: ViewMappedEmployee },
  { path: '/employee-agent-mapping', component: EmployeeAgentMapping },
  { path: '/my-clients', component: MyClients },
  { path: '/employee-employee-mapping', component: EmployeeEmployeeMapping },
  { path: '/view-mapped-clients', component: ViewMappedClients },
  { path: '/reassign-leads', component: ReassignLeads },
  { path: '/view-mapped-leads', component: ViewMappedLeads },
  { path: '/reassign-clients', component: ReassignClients },
]
export function mapRoutes(allRoutes) {
  const cookie = getCookie('accessToken')
  const authRoutes = jwtDecode(cookie)?.resource_access?.agent?.roles || []

  const filteredRoutes = allRoutes.filter((routObj) =>
    authRoutes.find((route) => routObj.path === `/${route}`)
  )
  return filteredRoutes
}
