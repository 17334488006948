import { Checkbox } from '@mui/material'
import { useMappedUserToUserMutation } from 'api/usersAPI'
import {
  CustomButton,
  CustomLoading,
  CustomModal,
  CustomTable,
  Toast,
} from 'components'
import { useEffect, useState } from 'react'
import { filterFormData } from 'utils/CustomUtils'

function AgentPosRemappingTable({ agentFormData, tableData, empCode }) {
  const [checkbox, setCheckbox] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  const toggleSelectAll = () => {
    if (checkbox.length === tableData.length) {
      setCheckbox([])
    } else {
      setCheckbox(tableData.map((item) => item.agencyCode || item.posCode))
    }
  }

  const tableHeaders = [
    {
      label: <Checkbox onClick={() => toggleSelectAll()} />,
      field: 'select-all',
    },
    { label: 'sr. no.', field: '' },
    { label: 'Name', field: 'name' },
    { label: 'User SubType', field: 'userSubType' },
    { label: 'Agency/Pos Code', field: 'profileCode' },
    { label: 'Region', field: 'region' },
    { label: 'District', field: 'district' },
    { label: 'Pincode', field: 'pincode' },
    { label: 'City', field: 'city' },
  ]

  const handleCheckbox = (event, id) => {
    event.stopPropagation()
    const checkIndex = checkbox.indexOf(id)
    let newCheck = []

    if (checkIndex === -1) {
      newCheck = newCheck.concat(checkbox, id)
    } else if (checkIndex === 0) {
      newCheck = newCheck.concat(checkbox.slice(1))
    } else if (checkIndex === checkbox.length - 1) {
      newCheck = newCheck.concat(checkbox.slice(0, -1))
    } else if (checkIndex > 0) {
      newCheck = newCheck.concat(
        checkbox.slice(0, checkIndex),
        checkbox.slice(checkIndex + 1)
      )
    }
    setCheckbox([...newCheck])
  }

  const isChecked = (id) => checkbox.indexOf(id) !== -1

  const handleModalOpen = () => setModalOpen(true)

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const [mappedUserToUser, { isSuccess, isError, isLoading }] =
    useMappedUserToUserMutation()

  const formDataFiltered = filterFormData(agentFormData)

  const handleReassignClick = () => {
    const mapping = {
      userType: formDataFiltered.userType,
      userSubType: formDataFiltered.userSubType,
      employeeCode: empCode,
      agencyCodes: checkbox,
    }
    const mappingForPos = {
      userType: formDataFiltered.userType,
      userSubType: formDataFiltered.userSubType,
      employeeCode: empCode,
      posCodes: checkbox,
    }
    if (formDataFiltered.userSubType === 'agent') {
      mappedUserToUser(mapping)
    } else if (formDataFiltered.userSubType === 'pos') {
      mappedUserToUser(mappingForPos)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      handleModalOpen()
    }
  }, [isSuccess])

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'select-all':
        return (
          <>
            {row.agencyCode && (
              <Checkbox
                checked={isChecked(row.agencyCode || row.posCode)}
                onClick={(event) =>
                  handleCheckbox(event, row.agencyCode, row.posCode)
                }
              />
            )}
            {row.posCode && (
              <Checkbox
                checked={isChecked(row.posCode)}
                onClick={(event) => handleCheckbox(event, row.posCode)}
              />
            )}
          </>
        )
      case 'name':
        return `${row.firstName} ${row.lastName}`
      case 'userSubType':
        return row.userSubType
      case 'profileCode':
        return row.agencyCode || row.posCode
      case 'region':
        return row.region
      case 'district':
        return row.district
      case 'pincode':
        return row.pincode
      case 'city':
        return row.city
      default:
        return ''
    }
  }
  return (
    <>
      {isLoading && <CustomLoading />}
      {isError && (
        <Toast
          message={'Error in mapping to Agent/Pos Please Try Again'}
          severity={'error'}
        />
      )}
      <div>
        <CustomTable
          tableLabel="Agent/POS Selection"
          headers={tableHeaders}
          rows={tableData}
          render={renderCell}
        />
        <div className="flex justify-center gap-4 my-4 mb-4">
          <CustomButton
            type="submit"
            variant={'contained'}
            disabled={checkbox.length === 0 ? true : false}
            onClick={handleReassignClick}
          >
            Re-assign
          </CustomButton>
        </div>
      </div>
      {isSuccess && (
        <CustomModal
          message={'User Re-assigned Successfully'}
          modalOpen={modalOpen}
          handleModalOpen={handleModalOpen}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  )
}

export default AgentPosRemappingTable
