import CustomButton from './CustomButton'
import { parseISO, format } from 'date-fns'

function ViewDetails({ user, handleClick, fieldsToDisplay }) {
  return (
    <>
      <div className="grid gap-4 m-8 md:grid-cols-2 lg:grid-cols-3">
        {fieldsToDisplay.map(({ value, label }) => {
          let userValue = user[value]
          if (value === 'assignToData') {
            if (label === 'Assign To' || label === 'Assigned To') {
              userValue = userValue?.assignTo
            } else if (label === 'Previously Assigned To') {
              userValue = userValue?.previouslyAssignedTo
            } else if (label === 'Employee Code') {
              userValue = userValue?.employeeCode
            } else if (label === 'Designation') {
              userValue = userValue?.designation
            }
          }

          if (value === 'assignedTo') {
            if (label === 'Assign To' || label === 'Assigned To') {
              userValue = user?.Assignee[0]?.completeName
            }
          }

          if (value === 'branch') {
            if (label === 'Branch') {
              userValue = user?.branch
            }
          }
          if (
            value === 'employeeCode' ||
            value === 'agencyCode' ||
            value === 'posCode'
          ) {
            if (label === 'Employee Code') {
              userValue = user?.employeeCode
            } else if (label === 'Agency Code') {
              userValue = user?.agencyCode
            } else {
              userValue = user?.posCode
            }
          }

          if (value === 'assigneeData') {
            if (label === 'Employee Code') {
              userValue = user?.Assignee[0]?.employeeCode
            } else if (label === 'Agency Code') {
              userValue = user?.Assignee[0]?.agencyCode
            } else {
              userValue = user?.Assignee[0]?.posCode
            }
            if (label === 'Branch') {
              userValue = user?.Assignee[0]?.branch
            }
          }
          if (value === 'currentAssignee') {
            userValue = user?.Assignee[0]?.completeName
          } else if (value === 'previousAssignee') {
            userValue = user?.Assignee[1]?.completeName
          }

          if (
            value === 'gender' &&
            (userValue === null || userValue === undefined)
          ) {
            const title = user.title.toLowerCase()
            if (title === 'mr' || title === 'mr.') {
              userValue = 'Male'
            } else if (
              title === 'ms' ||
              title === 'ms.' ||
              title === 'mrs' ||
              title === 'mrs.'
            ) {
              userValue = 'Female'
            } else {
              userValue = 'NA'
            }
          }

          if (userValue !== undefined) {
            let displayValue
            if (
              value === 'dob' ||
              value === 'dateOfBirth' ||
              value === 'createdAt' ||
              value === 'nismRegistrationNumberGenerationDate' ||
              value === 'posRegistrationNumberGenerationDate'
            ) {
              if (userValue === null) {
                displayValue = 'NA'
              } else {
                displayValue = format(parseISO(userValue), 'dd-MM-yyyy')
              }
            } else if (value === 'address') {
              displayValue = user.address?.permanentAddress || 'NA'
            } else if (userValue === null || userValue === '') {
              displayValue = 'NA'
            } else if (value === 'mobile' || value === 'officeMobile') {
              const parsedValue = JSON.parse(userValue)
              if (parsedValue.code && parsedValue.number) {
                displayValue =
                  parsedValue.code.charAt(0) === '+'
                    ? `${parsedValue.code}${parsedValue.number}`
                    : `+${parsedValue.code}${parsedValue.number}`
              } else if (
                user.countryCode !== null &&
                user.countryCode !== undefined
              ) {
                displayValue =
                  user.countryCode.charAt(0) === '+'
                    ? `${user.countryCode}${user.mobile}`
                    : `+${user.countryCode}${user.mobile}`
              } else {
                displayValue =
                  userValue.length === 10 ? userValue : `+${userValue}`
              }
            } else {
              displayValue = userValue
            }
            return (
              <div
                key={label}
                className="flex gap-5 px-4 py-2 rounded-lg shadow-md"
              >
                <h1 className="flex-1 self-center mb-2 font-semibold capitalize">
                  {label}:
                </h1>
                <div className="flex-1 px-3 py-2 text-sm border border-gray-200 rounded-md">
                  {displayValue}
                </div>
              </div>
            )
          }
        })}
      </div>
      <div className="flex justify-center my-4 mt-8">
        <CustomButton
          className="px-4 py-2 mr-4"
          variant={'outlined'}
          onClick={handleClick}
        >
          Back
        </CustomButton>
      </div>
    </>
  )
}

export default ViewDetails
