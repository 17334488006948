import { Box, Modal, Typography } from '@mui/material'
import CustomButton from './CustomButton'

function CustomModal({
  message,
  modalOpen,
  handleModalClose,
  alertModalClose,
  label,
}) {
  return (
    <Modal
      open={modalOpen}
      onClose={alertModalClose ? alertModalClose : handleModalClose}
    >
      <Box className="absolute p-12 m-auto text-center shadow-xl top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 min-h-fit bg-slate-50">
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {message}
        </Typography>
        <div className="mt-10">
          <CustomButton variant="contained" onClick={handleModalClose}>
            {label ? label : 'OK'}
          </CustomButton>
        </div>
      </Box>
    </Modal>
  )
}

export default CustomModal
