import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const env = process.env.NODE_ENV
const appEnv = process.env.REACT_APP_BUILD_TYPE
const envMode = env === 'development' ? env : appEnv

const configMapper = {
  development: {
    insuranceConfigService: 'insurance/configure',
    smartcovrConfig: {
      apiKey: 'dummy',
      client: 'one_access_admin',
      configType: 'config',
      configService: 'configure',
      env: envMode,
      host: 'uat.smartcovr.io',
      serverUrl: 'http://localhost:3032',
    },
  },
  uat: {
    insuranceConfigService: 'insurance-configure/1.0',
    smartcovrConfig: {
      apiKey: 'a6a53c92-60cd-46dc-956f-ce83252b6ff5',
      configService: 'configure/1.0',
      configType: 'config',
      clientId: 'Finclusion',
      clientSecret: 'a',
      client: 'one_access_admin',
      env: envMode,
      host: window.location.host.includes('localhost')
        ? 'uat.smartcovr.io'
        : window.location.host,
      serverUrl: 'https://apigateway.smartcovr.io/apiman-gateway/smartcovr',
    },
  },
}

const baseConfig =
  envMode === 'development' ? configMapper.development : configMapper.uat

export const fetchConfig = createAsyncThunk('config/fetchConfig', async () => {
  const {
    serverUrl,
    configService,
    configType,
    host,
    apiKey,
    client,
    env,
    clientId,
    clientSecret,
  } = baseConfig.smartcovrConfig
  const url = `${serverUrl}/${configService}/distributor?configType=${configType}&host=${host}&apikey=${apiKey}&client=${client}&env=${env}`
  const headers = {
    Authorization: 'Basic ' + window.btoa(`${clientId}:${clientSecret}`),
  }
  const response = await fetch(url, { headers: headers })
  if (!response.ok) {
    throw new Error('Failed to fetch configuration data')
  }
  const config = await response.json()
  const addedConfig = {
    ...config,
    apiman: {
      grantType: 'client_credentials',
      clientId: config.clientId,
      clientSecret: config.clientSecret,
      apikey: config.apiKeyWithOAuth,
      iamUrl: `https://iam2${appEnv === 'staging' ? 'stage' : ''}${
        appEnv === 'production' ? 'prod' : ''
      }.smartcovr.io/realms/{realm}/protocol/openid-connect/token`,
      realm: 'smartcovr',
    },
  }
  return {
    addedConfig,
  }
})

const configSlice = createSlice({
  name: 'config',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfig.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchConfig.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(fetchConfig.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  },
})

export default configSlice.reducer
