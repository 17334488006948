import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  CustomButton,
  CustomDatePicker,
  CustomDropdown,
  CustomPaper,
  CustomTextField,
  LocationDropdown,
  PincodeInput,
} from 'components'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  leadOptions,
  Branches,
  LifeSubTypeOptions,
  HealthSubTypeOptions,
  GeneralSubTypeOption,
} from 'constant/formOptions'
import { myLeadSchema } from './schema'

function GetMyLeadsForm({ onSubmit, formData }) {
  const navigate = useNavigate()

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      ...formData,
    },
    resolver: yupResolver(myLeadSchema),
  })

  let leadSubTypeOptions = []
  if (watch('leadType') === 'Life insurance') {
    leadSubTypeOptions = LifeSubTypeOptions
  } else if (watch('leadType') === 'Health insurance') {
    leadSubTypeOptions = HealthSubTypeOptions
  } else if (watch('leadType') === 'General insurance') {
    leadSubTypeOptions = GeneralSubTypeOption
  }

  return (
    <>
      <CustomPaper>
        <Typography
          component={'h1'}
          sx={{
            fontWeight: 700,
            fontSize: { xs: 15, sm: 15, md: 20, lg: 24 },
          }}
          className="p-4 pl-8 text-center capitalize"
        >
          My Leads
        </Typography>
        <div className="grid gap-6 lg:grid-cols-2 md:grid-cols-1 ">
          <CustomDatePicker
            label="From Date"
            name={'dateFrom'}
            control={control}
          />
          <CustomDatePicker label="To Date" name={'dateTo'} control={control} />
          <CustomDropdown
            control={control}
            select
            name={'leadType'}
            label="Lead Type"
            options={leadOptions}
            isRequired={true}
          />
          <CustomDropdown
            control={control}
            select
            name={'leadSubType'}
            label="Lead Sub Type"
            options={leadSubTypeOptions}
            isRequired={true}
          />
          <LocationDropdown control={control} watch={watch} />
          <PincodeInput control={control} name={'pincode'} label={'Pin Code'} />
          {watch('city') === 'others' && (
            <CustomTextField
              control={control}
              name={'newCity'}
              label="Add New City/Area"
              isRequired
            />
          )}
          <CustomDropdown
            name={'branch'}
            control={control}
            select
            label="Branch"
            options={Branches}
          />
        </div>
        <div className="flex justify-center gap-4 my-4">
          <CustomButton
            type="submit"
            variant={'contained'}
            onClick={handleSubmit(onSubmit)}
          >
            Search
          </CustomButton>
          <CustomButton variant={'outlined'} onClick={() => navigate('/')}>
            Back
          </CustomButton>
        </div>
      </CustomPaper>
    </>
  )
}

export default GetMyLeadsForm
