import { ViewDetails } from 'components'

export const fieldsToDisplay = [
  { value: 'leadType', label: 'Insurance Type' },
  { value: 'leadSubType', label: 'Insurance Sub Type' },
  { value: 'policyOption', label: 'Policy Type' },
  { value: 'productName', label: 'Product Name' },
  { value: 'policyNumber', label: 'Policy Number' },
  { value: 'leadName', label: 'Full Name' },
  // { value: 'firstName', label: 'First Name' },
  // { value: 'middleName', label: 'Middle Name' },
  // { value: 'lastName', label: 'Last Name' },
  { value: 'gender', label: 'Gender' },
  { value: 'dob', label: 'Date Of Birth' },
  { value: 'email', label: 'Email ID' },
  { value: 'mobile', label: 'Mobile Number' },
  { value: 'address', label: 'Address' },
  { value: 'country', label: 'Country' },
  { value: 'region', label: 'Region' },
  { value: 'state', label: 'State' },
  { value: 'district', label: 'District' },
  { value: 'pincode', label: 'Pincode' },
  { value: 'city', label: 'City' },
]

const ClientDetails = ({ user, handleClick }) => {
  return (
    <ViewDetails
      fieldsToDisplay={fieldsToDisplay}
      user={user}
      handleClick={handleClick}
    />
  )
}

export default ClientDetails
