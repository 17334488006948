import { ViewDetails } from 'components'

export const fieldsToDisplay = [
  { value: 'leadType', label: 'Lead Type' },
  { value: 'leadSubType', label: 'Lead Sub Type' },
  { value: 'policyOption', label: 'Policy Type' },
  { value: 'leadName', label: 'Lead Name' },
  { value: 'dob', label: 'Date Of Birth' },
  { value: 'email', label: 'Email ID(Personal)' },
  { value: 'officeEmail', label: 'Email ID(Official)' },
  { value: 'mobile', label: 'Mobile Number' },
  { value: 'address', label: 'Address' },
  { value: 'country', label: 'Country' },
  { value: 'region', label: 'Region' },
  { value: 'state', label: 'State' },
  { value: 'district', label: 'District' },
  { value: 'city', label: 'City/Area' },
  { value: 'pincode', label: 'Pin Code' },
  { value: 'createdAt', label: 'Lead Created On' },
  { value: 'leadEntryDate', label: 'Lead Entry Date' },
  { value: 'leadEndDate', label: 'Lead End Date' },
  { value: 'leadContactDate', label: 'Lead Contact Date' },
  { value: 'leadContactMode', label: 'Lead Contact Mode' },
]

export default function LeadDetails({ user, handleClick }) {
  return (
    <ViewDetails
      user={user}
      handleClick={handleClick}
      fieldsToDisplay={fieldsToDisplay}
    />
  )
}
