import { useEffect, useState } from 'react'
import {
  useAssignSelfLeadMutation,
  useAssignSelfBulkLeadsMutation,
} from 'api/leadAPI'
import { CustomLoading, CustomModal, Toast } from 'components'
import { CreateLeadForm } from 'forms'

export default function AssignLeadsToSelf() {
  const [assignSelfLead, { isSuccess, isLoading, isFetching, isError }] =
    useAssignSelfLeadMutation()
  const [
    postAssignBulkLeads,
    {
      isLoading: isBulkLoading,
      isError: isBulkError,
      isSuccess: isBulkSuccess,
    },
  ] = useAssignSelfBulkLeadsMutation()
  const [modal1Open, setModal1Open] = useState(false)
  const [modal2Open, setModal2Open] = useState(false)
  const [bulkLeadsData, setBulkLeadsData] = useState({})

  useEffect(() => {
    if (isSuccess || isBulkSuccess) {
      setModal2Open(true)
    }
  }, [isSuccess, isBulkSuccess])

  const handleModal1Close = () => {
    setModal1Open(false)
    postAssignBulkLeads(bulkLeadsData)
    if (!isLoading && !isBulkLoading && !isError && !isBulkError) {
      setModal2Open(true)
    }
  }

  const alertModalClose = () => {
    setModal1Open(false)
  }

  const handleModal2Close = () => {
    setModal2Open(false)
  }

  const onSubmit = (formdata, iamId) => {
    assignSelfLead({ lead: formdata, iamId })
  }

  const onBulkSubmit = (formdata) => {
    setModal1Open(true)
    setBulkLeadsData(formdata)
  }

  return (
    <>
      <CreateLeadForm
        onSubmit={onSubmit}
        onBulkSubmit={onBulkSubmit}
        buttonLabel={'Assign'}
        formHeader={'Assign Lead to Self'}
      />
      {(isError || isBulkError) && (
        <Toast
          message={'Error in Assigning Leads Try Again in Sometime'}
          severity={'error'}
        />
      )}
      {(isLoading || isFetching || isBulkLoading) && <CustomLoading />}
      <CustomModal
        message="File Uploaded Successfully!"
        label="Assign"
        modalOpen={modal1Open}
        handleModalClose={handleModal1Close}
        alertModalClose={alertModalClose}
      />
      {!isLoading && !isBulkLoading && !isBulkError && !isError && (
        <CustomModal
          message="Lead Assigned Successfully!"
          modalOpen={modal2Open}
          handleModalClose={handleModal2Close}
        />
      )}
    </>
  )
}
