import { useState } from 'react'
import { CustomButton, CustomTable } from 'components'
import { Checkbox } from '@mui/material'

const tableHeaders = [
  { label: '', field: 'check' },
  { label: 'sr. no.', field: '' },
  { label: 'Name', field: 'name' },
  { label: 'Lead Type', field: 'leadType' },
  { label: 'Lead Subtype', field: 'leadSubType' },
  { label: 'Looking For', field: 'lookingFor' },
  { label: 'Country', field: 'country' },
  { label: 'View', field: 'view' },
]
export default function LeadTable(props) {
  const { tableData, setLeadNumbers, setStep, setUserObj } = props
  const [selected, setSelected] = useState([])

  const isSelected = (leadNumber) => selected.indexOf(leadNumber) !== -1
  const handleCheckboxClick = (event, rowData) => {
    const selectedIndex = selected.findIndex(
      (selectedRow) => selectedRow === rowData
    )
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowData)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    } else {
      // add this else block to remove the row data if the checkbox is unchecked
      newSelected = newSelected.concat(
        selected.filter((selectedRow) => selectedRow !== rowData)
      )
    }
    setSelected(newSelected)
    setLeadNumbers(newSelected)
  }

  const handleClick = (user) => {
    setUserObj(user)
    setStep(2)
  }

  const onClickAssign = () => {
    setStep(3)
  }

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'name':
        return `${row.leadName}`
      case 'leadType':
        return row.leadType
      case 'leadSubType':
        return row.leadSubType
      case 'lookingFor':
        return row.policyOption
      case 'country':
        return row.country
      case 'view':
        return (
          <CustomButton variant={'outlined'} onClick={() => handleClick(row)}>
            View
          </CustomButton>
        )
      case 'check':
        return (
          <Checkbox
            checked={isSelected(row.leadNumber)}
            onClick={(event) => handleCheckboxClick(event, row.leadNumber)}
          />
        )
      default:
        return ''
    }
  }
  const disabledButton = selected.length === 0 ? true : false

  return (
    <>
      <CustomTable
        tableLabel="Lead Search Result"
        headers={tableHeaders}
        rows={tableData}
        render={renderCell}
      />
      <div className="flex justify-center gap-4 my-4">
        <CustomButton
          type="submit"
          variant={'contained'}
          disabled={disabledButton}
          onClick={onClickAssign}
        >
          Re-Assign
        </CustomButton>
        <CustomButton variant={'outlined'} onClick={() => setStep(1)}>
          Back
        </CustomButton>
      </div>
    </>
  )
}
