import { capitalizeCamelCase } from 'utils/CustomUtils'

export const populateLocationDropdown = (
  name,
  countriesArray,
  countryVal,
  regionVal,
  stateVal
) => {
  const optionsArray = []

  if (name === 'country') {
    const countryArr = Object.keys(countriesArray || {})
    countryArr.forEach((country) => {
      const countryObj = {
        label: capitalizeCamelCase(country),
        value: country,
      }
      optionsArray.push(countryObj)
    })
  } else if (name === 'region') {
    const regionsArr = Object.keys(countriesArray?.[countryVal] || {})
    regionsArr.forEach((region) => {
      const regionObj = {
        label: capitalizeCamelCase(region),
        value: region,
      }
      optionsArray.push(regionObj)
    })
  } else if (name === 'state') {
    const statesArr = countriesArray?.[countryVal]?.[regionVal] || []
    statesArr.forEach((state) => {
      const stateObj = {
        label: capitalizeCamelCase(state.state),
        value: state.state,
      }
      optionsArray.push(stateObj)
    })
  } else if (name === 'district') {
    const districtArr = countriesArray?.[countryVal]?.[regionVal] || []
    districtArr.forEach((state) => {
      if (state.state === stateVal) {
        state.districts.forEach((district) => {
          const districtObj = {
            label: capitalizeCamelCase(district),
            value: district,
          }
          optionsArray.push(districtObj)
        })
      }
    })
  }

  return optionsArray
}
