import * as yup from 'yup'
const today = new Date()

const leadSchema = yup.object({
  leadType: yup
    .string()
    .required('Please select lead Type from the list above'),
  leadSubType: yup
    .string()
    .required('Please select lead sub Type from the list above'),
  title: yup.string().required('Please select title from the list above'),
  leadName: yup
    .string()
    .required('Please Enter the Name')
    .min(3)
    .max(70, 'Must be less than 70 characters'),
  dob: yup
    .date()
    .required()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .required('Please Provide Date of Birth')
    .min(
      new Date(today.getFullYear() - 80, today.getMonth(), today.getDate()),
      'Enter Valid Date'
    )
    .max(
      new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()),
      'Should be above 18 years'
    ),
  mobile: yup
    .string()
    .test('phone-number', 'Please provide a valid mobile number', (value) => {
      try {
        const phoneNumber = JSON.parse(value)
        const numberLength = phoneNumber.number.length || 0
        return numberLength >= 10 && numberLength <= 15
      } catch (error) {
        return false
      }
    })
    .required('Please provide mobile number'),
  email: yup
    .string()
    .required('Please Provide Email Id')
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      'Please provide valid email id'
    )
    .max(40, 'Must be less than 40 characters'),
  officeEmail: yup
    .string()
    .required('Please Provide Email Id')
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      'Please provide valid email id'
    )
    .max(40, 'Must be less than 40 characters'),
  address: yup
    .string()
    .required('Please Provide Address')
    .max(50, 'Must be less Than 50 characters')
    .matches(
      /^[0-9a-zA-Z \\/,-:;<>.&@(){}#~]*$/,
      'Only alphanumeric with special characters are allowed for this field '
    ),
  country: yup.string().required('Please select country from the list above'),
  region: yup.string().required('Please select region from the list above'),
  state: yup.string().required('Please select state from the list above'),
  district: yup.string().required('Please select district from the list above'),
  city: yup.string().required('Please select city from the list above'),
  newCity: yup
    .string()
    .when('city', { is: 'others', then: (schema) => schema.min(3) })
    .matches(/^[aA-zZ\s]+$/, 'Please Provide New City Name'),
  pincode: yup
    .string()
    .matches(/^$|^\d{6}$/, 'Pincode must be exactly 6 digits')
    .required(),
  leadSource: yup
    .string()
    .required('Please select Lead source from the list above'),
  occupation: yup.string().max(30, 'Must be less than 30 characters'),
  organization: yup
    .string()
    .matches(
      /^[a-zA-Z]{0,30}$/,
      'Please only enter alphabets and less than 30 charectars'
    ),
  policyOption: yup
    .string()
    .required('Please select what you are Looking for from the list above'),
})

export default leadSchema
