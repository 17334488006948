import {
  CustomButton,
  CustomDataRangeDownloader,
  CustomTable,
} from 'components'

const tableHeaders = [
  { label: 'sr. no.', field: '' },
  { label: 'Employee Name', field: 'name' },
  { label: 'Assigned To', field: 'assignTo' },
  { label: 'Designation', field: 'designation' },
  { label: 'Employee Code', field: 'employeeCode' },
  { label: 'Country', field: 'country' },
  { label: 'View', field: 'view' },
]
const fieldsToExport = [
  { value: 'completeName', label: 'Employee Name' },
  { value: 'assignTo', label: 'Assign To' },
  { value: 'employeeCode', label: 'Employee Code' },
  { value: 'designation', label: 'Designation' },
  { value: 'dateOfBirth', label: 'Date Of Birth' },
  { value: 'email', label: 'Email ID' },
  { value: 'phoneNo', label: 'Mobile Number' },
  { value: 'country', label: 'Country' },
  { value: 'region', label: 'Region' },
  { value: 'state', label: 'State' },
  { value: 'district', label: 'District' },
  { value: 'city', label: 'City/Area' },
  { value: 'pincode', label: 'Pin Code' },
]

export default function MappedEmployeeTable(props) {
  const { tableData, setStep, setUserObj } = props

  const handleClick = (user) => {
    setUserObj(user)
    setStep((prev) => prev + 1)
  }

  const renderCell = (row, header) => {
    switch (header.field) {
      case 'name':
        return `${row.firstName} ${row.lastName}`
      case 'employeeCode':
        return row.employeeCode
      case 'assignTo':
        return row.assignToData.assignTo
      case 'country':
        return row.country
      case 'designation':
        return row.designation
      case 'view':
        return (
          <CustomButton variant={'outlined'} onClick={() => handleClick(row)}>
            View
          </CustomButton>
        )
      default:
        return ''
    }
  }
  return (
    <>
      <div className="flex justify-center my-12 text-3xl font-semibold capitalize">
        View Mapped Employee
      </div>
      <CustomDataRangeDownloader
        className="w-56 ml-44"
        tableHeaders={fieldsToExport}
        tableData={tableData.map((row) => {
          return {
            ...row,
            assignTo: row.assignToData.assignTo,
          }
        })}
        fileName={'MAPPED_EMPLOYEES'}
      />
      <CustomTable
        tableLabel="Search result"
        headers={tableHeaders}
        rows={tableData}
        render={renderCell}
      />
      <div className="flex justify-center gap-4 my-4">
        <CustomButton
          type="submit"
          variant={'outlined'}
          onClick={() => setStep(1)}
        >
          Back
        </CustomButton>
      </div>
    </>
  )
}
