import { ViewDetails } from 'components'

export default function LeadDetails({ user, handleClick }) {
  const fieldsToDisplay = [
    { value: 'leadType', label: 'Lead Type' },
    { value: 'leadSubType', label: 'Lead Sub Type' },
    { value: 'policyOption', label: 'Policy Type' },
    { value: 'firstName', label: 'First Name' },
    { value: 'middle Name', label: 'Middle Name' },
    { value: 'lastName', label: 'Last Name' },
    { value: 'leadName', label: 'Name' },
    { value: 'assignedTo', label: 'Assigned To' },
    { value: 'assigneeData', label: 'Employee Code' },
    { value: 'assigneeData', label: 'Agency Code' },
    { value: 'assigneeData', label: 'Pos Code' },
    { value: 'dob', label: 'Date Of Birth' },
    { value: 'createdAt', label: 'Lead Created On' },
    { value: 'email', label: 'Email ID(Personal)' },
    { value: 'officeEmail', label: 'Email ID(official)' },
    { value: 'mobile', label: 'Mobile Number' },
    { value: 'address', label: 'Address' },
    { value: 'country', label: 'Country' },
    { value: 'region', label: 'Region' },
    { value: 'state', label: 'State' },
    { value: 'district', label: 'District' },
    { value: 'city', label: 'City/Area' },
    { value: 'assigneeData', label: 'Branch' },
    { value: 'pincode', label: 'Pin Code' },
  ]
  return (
    <ViewDetails
      user={user}
      handleClick={handleClick}
      fieldsToDisplay={fieldsToDisplay}
    />
  )
}
