import { CustomLoading } from 'components'
import { useState } from 'react'
import { UserTypeSelectionForm, RegistrationForm } from 'features/registration'

function Registration() {
  const [formStep, setFormStep] = useState(true)
  const [subtype, setSubType] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleBackClick = () => {
    setFormStep((prev) => !prev)
  }

  const handleNextClick = (data) => {
    setSubType(data.registerUserSubType)
    setFormStep((prev) => !prev)
  }

  return (
    <>
      {isLoading && <CustomLoading />}
      {formStep ? (
        <UserTypeSelectionForm onSubmit={handleNextClick} />
      ) : (
        <RegistrationForm
          handleBackClick={handleBackClick}
          userSubType={subtype}
          formStep={formStep}
          setFormStep={setFormStep}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  )
}

export default Registration
