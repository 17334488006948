import styled from '@emotion/styled'
import { InputLabel } from '@mui/material'

const CustomLabel = styled(InputLabel)(({ theme }) => ({
  '&.MuiInputLabel-root': {
    color: theme.palette.primary.dark,
  },
}))

export default CustomLabel
