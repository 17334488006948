import React, { useState } from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  MenuItem,
  FormControl,
  Select,
  FormLabel,
} from '@mui/material'
import { CustomPaper, theme } from 'components'
import { useScrollToTable } from 'hooks'

function CustomTable({ rows, headers, render, tableLabel }) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortOrder, setSortOrder] = useState('asc')
  const tableRef = useScrollToTable([rows])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value)
  }

  const sortedRows = [...rows].sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.id - b.id
    } else {
      return b.id - a.id
    }
  })

  return (
    <CustomPaper className="rounded-xl lg:mx-20 sm:w-11/12 lg:w-9/12">
      <div className="flex items-center justify-between mx-8 mb-6">
        <div className="text-lg font-semibold md:text-xl lg:text-2xl">
          {tableLabel}
        </div>
        <FormControl>
          <FormLabel>Sort:</FormLabel>
          <Select
            className="pl-2 pr-8 w-44"
            value={sortOrder}
            onChange={handleSortOrderChange}
          >
            <MenuItem value={'asc'}>Ascending</MenuItem>
            <MenuItem value={'desc'}>Descending</MenuItem>
          </Select>
        </FormControl>
      </div>
      <TableContainer ref={tableRef} className="rounded-lg shadow-md max-h-96">
        <Table
          stickyHeader
          aria-label="sticky table"
          className="border table-auto"
        >
          <TableHead className="uppercase">
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  align={'center'}
                  style={{
                    backgroundColor: theme.palette.secondary.main,
                    color: 'white',
                  }}
                  className="font-bold min-w-fit"
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow hover tabIndex={-1} key={rowIndex}>
                  {headers.map((header, cellIndex) => (
                    <React.Fragment key={cellIndex}>
                      {header.label === 'sr. no.' ? (
                        <TableCell
                          align="center"
                          className="font-bold capitalize min-w-fit"
                        >
                          {page * rowsPerPage + rowIndex + 1}
                        </TableCell>
                      ) : (
                        <TableCell
                          align="center"
                          className="font-bold capitalize min-w-fit"
                          key={cellIndex}
                        >
                          {render(row, header)}
                        </TableCell>
                      )}
                    </React.Fragment>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="px-8 py-4"
      />
    </CustomPaper>
  )
}

export default CustomTable
