import axios from 'axios'
import { format } from 'date-fns'
import { jwtDecode } from 'jwt-decode'
import { config } from 'config'

/**
 * Filters the form data by removing empty values and formatting specific date fields.
 * @param {object} formData - The form data object to be filtered.
 * @returns {object} The filtered form data object.
 */
export const filterFormData = (formData) => {
  return Object.fromEntries(
    Object.entries(formData)
      .filter(([, value]) => value !== '')
      .map(([key, value]) => {
        if (['dateFrom', 'dateTo', 'dob'].includes(key)) {
          return [key, format(new Date(value), 'yyyy-MM-dd')]
        }
        return [key, value]
      })
  )
}

/**
 * Replaces capital letters with space followed by the same letter
 * Capitalizes the first letter of each word
 * @param {string} str - The string to be capitalized
 * @returns {string} The capitalized string
 */
export const capitalizeCamelCase = (str) => {
  const spacedStr = str.replace(/([A-Z])/g, ' $1')
  const capitalizedStr = spacedStr.replace(/\b\w/g, (match) =>
    match.toUpperCase()
  )
  return capitalizedStr
}

/**
 * Generate the URL for redirecting to Smartcovr.
 * The URL includes the smartcovrLocation, enquiryId, and insuranceType parameters.
 * Open the URL in a new tab/window.
 * @param {object} row - Details of lead to be redirect
 */

export const RedirectToSmartcovr = (row) => {
  const smartcovrLocation = row.dropOff
  const enquiryId = row.enquiryId
  const insuranceType = row.leadSubType
  const url = `https://uat.smartcovr.io/#/${insuranceType}/${smartcovrLocation}?enquiryId=${enquiryId}`
  window.open(url, '_blank')
}

/**
 * Converts the query string parameters of the current URL to a JSON object.
 * @returns {object} The JSON object containing the query string parameters.
 */

export const queryStringToJSON = () => {
  const searchString = window.location.search
  const result = {}
  if (searchString) {
    const pairs = searchString.substring(1).split('&')
    pairs.forEach(function (pair) {
      const [key, value] = pair.split('=')
      result[decodeURIComponent(key)] = decodeURIComponent(value || '')
    })
  }
  return result
}

/**
 * Gets the encoded route parameters for constructing a URL with query parameters and state.
 * @param {object} params - The parameters for constructing the URL.
 * @returns {object} The encoded route parameters.
 */

export const getEncodedRouteParams = (params) => {
  const { redirectToPath, configType, ...rest } = params

  const code = Math.random().toString(36).substring(2, 15)
  const hash = window.location.hash || ''
  const redirectTo =
    redirectToPath ||
    hash.split('#/')[1]?.split('?')[0] ||
    window.location.pathname
  const stateJson = queryStringToJSON()
  stateJson.redirectTo = redirectTo
  stateJson.code = code
  window.localStorage.setItem('state', JSON.stringify(stateJson))

  const queryParams = Object.entries(rest)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&')

  const queryString = `state=${code}&${queryParams}&configType=${configType}`

  return {
    queryString,
    state: code,
  }
}

/**
 * Retrieves the query parameters from the current URL and returns them as an object.
 * @returns {object} The query parameters as key-value pairs.
 */

export const getQueryParams = () => {
  const search = new URLSearchParams(window.location.search)
  const searchQuery = {}

  for (const [key, value] of search.entries()) {
    searchQuery[decodeURIComponent(key)] = decodeURIComponent(value || '')
  }

  return searchQuery
}

/**
 * Sets new accessToken and refreshToken in cookies
 * @returns {void}
 */

export const newToken = async (isLoggedIn) => {
  const currentRefreshToken = getCookie('refreshToken')

  const refresh = async () => {
    try {
      const response = await axios({
        url: config.refreshTokenUrl,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: { refresh_token: currentRefreshToken },
      })
      const { accessToken, refreshToken } = response.data
      document.cookie = `accessToken=${accessToken}; path=/`
      document.cookie = `refreshToken=${refreshToken}; path=/`
      return true
    } catch (err) {
      return false
    }
  }
  const tokenSuccess = await refresh()

  const currentAccessToken = getCookie('accessToken')
  const expirationTimestamp = jwtDecode(currentAccessToken).exp
  const currentTimestamp = Math.floor(Date.now() / 1000)
  const timeDifferenceInMilliseconds =
    (expirationTimestamp - currentTimestamp - 60) * 1000

  if (isLoggedIn && tokenSuccess) {
    setTimeout(() => newToken(true), timeDifferenceInMilliseconds)
  }
}

/**
 * Gets the cookie matching the regex pattern
 *
 * @param {*} name
 * @returns object
 */

export const getCookie = (name) => {
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

/**
 * Changes the mobile number format to SmartCovr format for mobile property
 *
 * @param {*} result
 * @returns array
 */

export const mobileFormatChange = (result) => {
  return result.data.map((row) => {
    return {
      ...row,
      mobile: JSON.stringify({ code: '+91', number: row?.mobile }),
    }
  })
}

/**
 * Checks and formats a JSON string representing a mobile number.
 * If the JSON structure is valid and contains 'code' and 'number' properties,
 * returns the formatted mobile number. Otherwise, returns the original value.
 *
 * @param {string} value - The input value, possibly a JSON string.
 * @returns {string} - Formatted mobile number or the original value.
 */

export const checkSmartcovrMobileFormat = (value) => {
  try {
    const parsedValue = JSON.parse(value)
    if (
      typeof parsedValue === 'object' &&
      parsedValue.code &&
      parsedValue.number
    ) {
      return parsedValue.number
    }
  } catch (error) {}
  return value
}
