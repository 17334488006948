import { Typography } from '@mui/material'
import {
  CustomButton,
  CustomDatePicker,
  CustomDropdown,
  CustomMobileInput,
  CustomPaper,
  CustomTextField,
  PincodeInput,
  LocationDropdown,
  FileUploadWidget,
  // CsvUploadForm,
} from 'components'
import {
  Branches,
  TitleOption,
  employeeDesignationOptions,
  qualificationOption,
} from 'constant/formOptions'
import { useCustomForm } from 'hooks/useCustomForm'
import { employeeSchema } from './schema'

function RegistrationFormEmployee({ handleBackClick, onSubmit }) {
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useCustomForm({}, employeeSchema)
  return (
    <CustomPaper>
      <div className="flex flex-row justify-between">
        <Typography
          component={'h1'}
          sx={{
            fontWeight: 700,
            fontSize: { xs: 15, sm: 15, md: 20, lg: 24 },
          }}
          className="p-4 pl-8 text-center capitalize"
        >
          Employee Registration
        </Typography>
        {/* <div className="text-center">
          <CsvUploadForm
            postData={onBulkSubmit}
            label={'Users'}
            smartCovrFormat
          />
        </div> */}
      </div>
      <div className="grid gap-6 lg:grid-cols-2 md:grid-cols-1">
        <CustomTextField
          name={'employeeCode'}
          control={control}
          label={'Employee Code'}
          isRequired
        />
        <CustomDropdown
          name={'title'}
          control={control}
          select
          label="Title"
          options={TitleOption}
          isRequired
        />
        <CustomTextField
          name={'firstName'}
          control={control}
          label={'First Name'}
          isRequired
        />
        <CustomTextField
          name={'middleName'}
          control={control}
          label={'Middle Name'}
          isRequired
        />
        <CustomTextField
          name={'lastName'}
          control={control}
          label={'Last Name'}
          isRequired
        />
        <CustomDatePicker
          name={'dateOfBirth'}
          control={control}
          label="Date of Birth"
          isRequired
        />
        <CustomDropdown
          name={'designation'}
          control={control}
          label={'Designation'}
          options={employeeDesignationOptions}
          isRequired
        />
        <CustomTextField
          name={'officeEmail'}
          control={control}
          label={'Email Id (Office)'}
          type={'email'}
          isRequired
        />
        <CustomTextField
          name={'email'}
          control={control}
          label={'Email Id (Personal)'}
          type={'email'}
          isRequired
        />
        <CustomMobileInput
          name={'officeMobile'}
          control={control}
          label={'Mobile No. (Office)'}
          variant="outlined"
          errors={errors}
          isRequired
        />
        <CustomMobileInput
          name={'phoneNo'}
          control={control}
          label={'Mobile No. (Residence)'}
          variant="outlined"
          errors={errors}
        />
        <CustomTextField
          name={'address'}
          control={control}
          label={'Address'}
          isRequired
        />
        <LocationDropdown control={control} watch={watch} isRequired />
        <PincodeInput
          control={control}
          name={'pincode'}
          label={'Pin Code'}
          isRequired
        />
        {watch('city') === 'others' && (
          <CustomTextField
            name={'newCity'}
            control={control}
            label={'Add New City/Area'}
            isRequired
          />
        )}
        <CustomDropdown
          name={'branchCode'}
          control={control}
          label={'Branch'}
          options={Branches}
          isRequired
        />
        <CustomTextField
          name={'nismRegistrationNumber'}
          control={control}
          label={'NISM Certificate Number'}
        />
        <CustomDatePicker
          name={'nismRegistrationNumberGenerationDate'}
          control={control}
          label={'NISM Registration Number Generation Date'}
        />
        <CustomDropdown
          name={'qualification'}
          control={control}
          select
          label="Education Qualification"
          options={qualificationOption}
          isRequired
        />
      </div>
      <FileUploadWidget
        name={'photograph'}
        label="Photograph"
        setValue={setValue}
      />
      <FileUploadWidget
        name={'nismCertificate'}
        label="NISM Certificate Copy"
        setValue={setValue}
      />
      <FileUploadWidget
        name={'educationCertificate'}
        label="Highest Education Certificate"
        setValue={setValue}
      />
      <div className="flex justify-center gap-4 my-6">
        <CustomButton
          type="submit"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </CustomButton>
        <CustomButton
          type="submit"
          variant="outlined"
          onClick={() => handleBackClick()}
        >
          Back
        </CustomButton>
      </div>
    </CustomPaper>
  )
}

export default RegistrationFormEmployee
