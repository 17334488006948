import { createApi } from '@reduxjs/toolkit/query/react'
import { wrappedFetchBaseQuery } from './baseQuery'

export const usersAPI = createApi({
  reducerPath: 'userAPI',
  baseQuery: wrappedFetchBaseQuery,
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (filters) => ({
        url: '/user/filter',
        params: filters,
      }),
    }),
    registerUsers: builder.mutation({
      query: (user) => ({
        url: '/user',
        method: 'POST',
        body: user,
      }),
    }),
    mappedUser: builder.query({
      query: (filters) => ({
        url: '/user/mapped',
        params: filters,
      }),
    }),
    mappedAgent: builder.query({
      query: (params) => ({
        url: '/user/assignedAgent',
        params: params,
      }),
    }),
    mappedUserToUser: builder.mutation({
      query: (data) => ({
        url: '/mapping',
        method: 'POST',
        body: data,
      }),
    }),
    getEmployee: builder.query({
      query: (filters) => ({
        url: '/user/assignedEmployee',
        params: filters,
      }),
    }),
  }),
})

export const {
  useGetUsersQuery,
  useRegisterUsersMutation,
  useMappedUserQuery,
  useMappedAgentQuery,
  useMappedUserToUserMutation,
  useGetEmployeeQuery,
} = usersAPI
