import { createApi } from '@reduxjs/toolkit/query/react'
import { wrappedFetchBaseQuery } from './baseQuery'

export const clientAPI = createApi({
  reducerPath: 'clientAPI',
  baseQuery: wrappedFetchBaseQuery,
  endpoints: (builder) => ({
    getClient: builder.query({
      query: (filters) => ({
        url: '/client/admin',
        params: filters,
      }),
    }),
    myClients: builder.query({
      query: (filters) => ({
        url: '/client/user',
        params: filters,
      }),
    }),
    assignClients: builder.mutation({
      query: (client) => ({
        url: '/assignClients',
        method: 'POST',
        body: client,
      }),
    }),
  }),
})

export const {
  useGetClientQuery,
  useMyClientsQuery,
  useAssignClientsMutation,
} = clientAPI
