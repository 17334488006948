import { IconButton } from '@mui/material'
import { useEffect } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import CustomButton from './CustomButton'
import CustomDropdown from './CustomDropdown'
import CustomLabel from './CustomLabel'
import CustomTextField from './CustomTextField'
import { Delete } from '@mui/icons-material'

function CustomAddInsurance({
  name,
  control,
  label,
  dropdownOptions,
  isRequired,
}) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: name,
  })

  useEffect(() => {
    if (fields.length === 0) {
      append({})
    }
  }, [append, fields.length])

  return (
    <div data-testid={`${name}-testId`}>
      <CustomLabel htmlFor={name} className="pt-6 mt-2 mb-1 capitalize">
        {label}
        {isRequired && <span className="text-red-700"> *</span>}
      </CustomLabel>
      {fields.map((item, index) => {
        return (
          <div
            key={item.id}
            className="grid grid-flow-row-dense gap-4 lg:grid-cols-6 md:grid-cols-1"
          >
            <Controller
              name={`${name}[${index}].insurancePolicy`}
              control={control}
              render={({
                field: {
                  onChange: insurancePolicyOnChange,
                  value: insurancePolicyValue,
                },
              }) => (
                <>
                  <div className="col-span-2">
                    <CustomDropdown
                      control={control}
                      label={'Insurance Policy'}
                      options={dropdownOptions}
                      name={`${name}[${index}].insurancePolicy`}
                      onChange={insurancePolicyOnChange}
                      value={insurancePolicyValue}
                    />
                  </div>
                  <div className="col-span-2">
                    <Controller
                      name={`${name}[${index}].companyCode`}
                      control={control}
                      render={({
                        field: {
                          onChange: companyCodeOnChange,
                          value: companyCodeValue,
                        },
                      }) => (
                        <CustomTextField
                          control={control}
                          label={'Insurance Company Code'}
                          onChange={companyCodeOnChange}
                          value={companyCodeValue || ''}
                          name={`${name}[${index}].companyCode`}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-1 sm:grid sm:place-items-center">
                    {index !== 0 && (
                      <IconButton
                        sx={{
                          color: 'red',
                          marginTop: '2.2rem',
                        }}
                        variant="contained"
                        type="button"
                        onClick={() => remove(index)}
                      >
                        <Delete />
                      </IconButton>
                    )}
                    {index === 0 && (
                      <CustomButton
                        sx={{
                          marginTop: '2.2rem',
                          maxWidth: '15px',
                          maxHeight: '30px',
                          minWidth: '15px',
                          minHeight: '30px',
                        }}
                        type="button"
                        variant="contained"
                        onClick={() => append({})}
                      >
                        +
                      </CustomButton>
                    )}
                  </div>
                </>
              )}
            />
          </div>
        )
      })}
    </div>
  )
}

export default CustomAddInsurance
