import * as yup from 'yup'

export const myLeadSchema = yup.object({
  leadType: yup
    .string()
    .required('Please select Lead Type from the list above'),
  leadSubType: yup
    .string()
    .required('Please select Lead Sub Type from the list above'),
  pincode: yup
    .string()
    .matches(/^$|^\d{6}$/, 'Pincode must be exactly 6 digits'),
  newCity: yup
    .string()
    .when('city', { is: 'others', then: (schema) => schema.min(3) })
    .matches(/^[aA-zZ\s]+$/, 'Please Provide New City Name'),
})
