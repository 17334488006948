import { ViewDetails } from 'components'

export default function LeadDetails({ user, handleClick, step }) {
  let fieldsToDisplay
  if (step === 2) {
    fieldsToDisplay = [
      { value: 'leadType', label: 'Lead Type' },
      { value: 'leadSubType', label: 'Lead Sub Type' },
      { value: 'policyOption', label: 'Policy Type' },
      { value: 'leadName', label: 'Name' },
      { value: 'firstName', label: 'First Name' },
      { value: 'middle Name', label: 'Middle Name' },
      { value: 'lastName', label: 'Last Name' },
      { value: 'gender', label: 'Gender' },
      { value: 'dateOfBirth', label: 'Date Of Birth' },
      { value: 'createdAt', label: 'Leads Created On' },
      { value: 'email', label: 'Email ID(Personal)' },
      { value: 'mobile', label: 'Mobile Number' },
      { value: 'address', label: 'Address' },
      { value: 'country', label: 'Country' },
      { value: 'region', label: 'Region' },
      { value: 'state', label: 'State' },
      { value: 'district', label: 'District' },
      { value: 'city', label: 'City/Area' },
      { value: 'branch', label: 'Branch' },
      { value: 'pincode', label: 'Pin Code' },
    ]
  } else if (step === 4) {
    fieldsToDisplay = [
      { value: 'firstName', label: 'First Name' },
      { value: 'middleName', label: 'Middle Name' },
      { value: 'lastName', label: 'Last Name' },
      { value: 'gender', label: 'Gender' },
      { value: 'dateOfBirth', label: 'Date Of Birth' },
      { value: 'designation', label: 'Designation' },
      { value: 'email', label: 'Email ID(Personal)' },
      { value: 'officeEmail', label: 'Email ID(Official)' },
      { value: 'phoneNo', label: 'Mobile Number(Personal)' },
      { value: 'officeMobile', label: 'Mobile Number(Official)' },
      { value: 'address', label: 'Address' },
      { value: 'employeeCode', label: 'Employee Code' },
      { value: 'agencyCode', label: 'Agency Code' },
      { value: 'posCode', label: 'POs Code' },
      { value: 'country', label: 'Country' },
      { value: 'state', label: 'State' },
      { value: 'district', label: 'District' },
      { value: 'city', label: 'City/Area' },
      { value: 'pincode', label: 'PinCode' },
      { value: 'branch', label: 'Branch' },
    ]
  }
  return (
    <ViewDetails
      user={user}
      handleClick={handleClick}
      fieldsToDisplay={fieldsToDisplay}
    />
  )
}
